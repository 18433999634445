import React from 'react'

const TextLoader = (props) => {
    const { text } = props
    return (
        <>
            <div className='loading-text d-flex align-items-center justify-content-center pt-3 pb-3' >
                <h6 className='m-0'>{text}</h6>
                <div className="snippet ms-4 d-flex align-items-center" data-title=".dot-falling">
                    <div className="stage">
                        <div className="dot-falling"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextLoader