import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CounterContext, PostManagementData } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext, UserContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import { AllPost } from "../services/posts";
import { error } from "../constants/msg";
import Loader from '../components/loader/Loader';

const FilterPostManagementPage = () => {
    const [loading, setLoading] = useState(false);
    const [postManagement, setPostManagement] = useContext(PostManagementData);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    const [userData, setUserData] = useContext(UserContext);
    const [counter, setCounter] = useContext(CounterContext);
    const [filterPosts, setFilterPosts] = useState([])
    const location = useLocation()
    const navigate = useNavigate()

    console.log("location data 1", location.state.datas)

    // useEffect(() => {
    //     if (!location.state.data) {
    //         navigate("/post-management")
    //     }
    //     else {
    //         setLoading(false)
    //         setPostManagement(location.state.data)
    //     }
    // }, [counter, location])
    useEffect(() => {
        setFilterPosts(location.state.datas)
    }, [])

    return (
        <>
            {handleFilterPopup && <FilterSearchPopup />}
            <div className="post-management-page">
                <Row className="mt-4">
                    <Col xs={12}>
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"} to='/post-management'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)} className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4 mb-5 mb-sm-0">
                    {console.log("filterPosts", filterPosts)}
                    {filterPosts.length == 0 || undefined ? <h4 className='text-center'>Their are no posts to show</h4> :
                        filterPosts.map((data, index) => {
                            return (
                                <Col key={index} xs={12} sm={12} md={6} lg={4} className="mb-4">
                                    <PostManagementCard data={data} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </>
    )
}

export default FilterPostManagementPage