import axios from 'axios';
import { constant, apiEndPoints, s3Credential } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

// My Profile
export const GetMyProfile = async () => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.me}`, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Update User

export const UpdateUser = async (loginId, data) => {
    if(data.user_image_url){
        data.user_image_url = s3Credential.s3EndPoint + data.user_image_url;
    }
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.users}/${loginId}`, data, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

export const UpdateRole = async (user_id, role_id) =>{
    let data = {
        user_id: user_id,
        role_id: role_id
    }
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.update_role}`, data,{});
    return resp
}
