import {getFacebookPage} from "../services/socialMediaIntegration";

export const platforms = {
    'FACEBOOK': 1,
    'TWITTER': 2,
    'INSTAGRAM': 3,
    'LINKEDIN': 4,
    'YOUTUBE': 5,
}

export const DAYS = {
    'Sunday': 0,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
}

export const DAYSKEY = {
    [DAYS['Sunday']]: 'Sunday',
    [DAYS['Monday']]: 'Monday',
    [DAYS['Tuesday']]: 'Tuesday',
    [DAYS['Wednesday']]: 'Wednesday',
    [DAYS['Thursday']]: 'Thursday',
    [DAYS['Friday']]: 'Friday',
    [DAYS['Saturday']]: 'Saturday',
}

export const constant = {
    BASE_URL: "https://rodeo.tekstaging.com/api/v1/",
    DEVICE_TYPE: "web",
    DEVICE_TOKEN: "hadouken",
    LOCAL_STORAGE_TOKEN: "rodeoToken",
    LOCAL_STORAGE_USER: "rodeoUser",
    userDirName: "users",
    postDirName: "posts",
    owner_role_id: 5,
    MANAGER_ROLE_ID: 3,
    COLLABORATOR_ROLE_ID: 4,
    PLATFORM: platforms,
    PAGINATE: 21
}

export const apiEndPoints = {
    register: "register",
    login: "login",
    logout: "logout",
    forgot_password: "forget-password",
    verify_reset_code: "verify-reset-code",
    reset_password: "reset-password",
    change_password: "change-password",
    dashboard_post: "posts",
    notifications: "notifications",
    pages: "pages",
    team_user: "team-users",
    me: "me",
    users: "users",
    update_role: "updateRole",
    statistics: "statistics",
    team_user_badges: "team-user-badges",
    teams: "teams",
    post_platform: "post-platform",
    joining_team_request: "joining-team-request",
    badges_list: "badges-list",
    team_notifications: "team-notifications",
    teams_invite_collaborator: "teams-invite-collaborator",
    teams_invite_manager: "teams-invite-manager",
    facebookPages: "facebookPages",
    team_platforms: "team-platforms",
    approved_post: "approved-post",
    social_login: "social_login",
    auto_post: "autoPost",
    all_packages: "all-packages",
    templates: "templates",
    template_days: "template-days",
    template_day_slots: "template-day-slots",
    user_subscription_create: "user-subscription-create",
    add_stripe_card : "add-stripe-card",
    currentTeam: "currentTeam",
    team_template: "team-template",
    team_day_slot: "team_day_slot",
    book_slots: "book-slots",
    post_slots_status: "post-slots-status",
    post_media: "post-media",
    tutorial_viewed: 'tutorial_viewed'
}

export const s3Credential = {
    bucketName: "rodeo-app",
    region: "us-east-1",
    dirName: "",
    accessKeyId: "AKIATMFKXRG7GUZUNEFM",
    secretAccessKey: "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",
    s3EndPoint: "https://rodeo-app.s3.us-east-1.amazonaws.com/",
    fileSize: "5000000"
}

export const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
}
