import React, { useState, useEffect, useContext } from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import Assets from '../constants/images'
import { DoneIcon, FireIcon, StarIcon } from '../constants/svgs'
import LeaderboardCard from '../components/dashboard/LeaderboardCard';
import { GetAllTeamUsers, getTemUserWeekly } from "../services/teamsUser";
import { getSingleTeam } from "../services/singleTeam";
import { success, error } from "../constants/msg";
import Loader from '../components/loader/Loader';
import BlurLoader from '../components/blurLoader/BlurLoader';
import { ProfileContext } from '../App';

const LeaderboardPage = () => {
    const [loading, setLoading] = useState(true);
    const [singleTeam, setSingleTeam] = useState();
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);

    var currenTeamId = profileDetail?.current_team?.id; //Team Id need to be change with the current team  after the API will completed from backend

    const [userList, SetUserList] = useState({
        allUser: true,
        monthlyUsers: false,
        weeklyUsers: false
    });

    const [userListData, SetUserListData] = useState();
    let { allUser, monthlyUsers, weeklyUsers } = userList;

    useEffect(() => {

        //Get All User Function
        const getTeamUser = async () => {
            try {
                let response = await GetAllTeamUsers(currenTeamId);
                SetUserListData(response.data.data)
                setLoading(false)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        // Get All User Weekly
        const TemUserWeekly = async () => {
            try {
                let response = await getTemUserWeekly(currenTeamId);
                SetUserListData(response.data.data)
                setLoading(false)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        // Get All User Monthly
        const getTemUserMonthly = async () => {
            try {
                let response = await getTemUserMonthly(currenTeamId);
                SetUserListData(response.data.data)
                setLoading(false)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        if (allUser) {
            getTeamUser()
        }
        if (weeklyUsers) {
            TemUserWeekly()
        }
        if (monthlyUsers) {
            getTemUserMonthly()
        }



    }, [allUser, monthlyUsers, weeklyUsers, currenTeamId, profileDetail])

    useEffect(() => {
        const fetchSingleTeam = async () => {
            try {
                let response = await getSingleTeam(currenTeamId);
                setSingleTeam(response.data.data)
                setLoading(false)
            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        fetchSingleTeam();
    }, [currenTeamId])



    return (
        <div className='leaderboard-page'>
            <Row className="mt-4">
                <Col xs={12} className="mb-4">
                    <h5 className='text-yellow d-block d-md-none'>LEADERBOARD</h5>
                    <h6>Add and Remove Your Social Media Accounts</h6>
                </Col>

                <Col xl={4} lg={4} className="mb-4">

                    <div className="left-container p-4 position-relative">
                        {loading && <BlurLoader />}
                        <div className="brand-team-box">
                            <div className="brand-img-wrapper">
                                <img className='brand-img' onError={(e) => e.target.src = Assets.ProfilePlaceholderImages} src={singleTeam?.profile_picture} />
                            </div>
                            <div className='mt-4'>
                                <p>{singleTeam?.name}</p>
                                <div className='mb-2'>
                                    <DoneIcon />
                                    <h6 className='d-inline-block ms-2'>{singleTeam?.successful_posts} Success Posts </h6>
                                </div>

                                <div>
                                    <FireIcon />
                                    <h6 className='d-inline-block ms-2'>{singleTeam?.streak_score}</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </Col>

                <Col xl={6} lg={6} className="mb-4 mb-lg-0">
                    <div className="right-container">
                        <div className="">
                            <div className='d-block leaderboard-tabs'>
                                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="py-2 pe-4">
                                    <Tab eventKey="all" title="All" onClick={() => {
                                        SetUserList({
                                            allUser: true,
                                            monthlyUsers: false,
                                            weeklyUsers: false
                                        })
                                    }}>

                                        <div className="leaderboard-card-container">
                                            {userListData?.length > 0 ? userListData?.map((data, key) => {
                                                return <LeaderboardCard cardData={data} key={key} index={key} />
                                            }) : <h4 className='w-100 h-100 d-flex align-items-center justify-content-center'>No Data To Show</h4>}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="weekly" title="Weekly" onClick={() => {
                                        SetUserList({
                                            allUser: false,
                                            weeklyUsers: true,
                                            monthlyUsers: false,
                                        })
                                    }}>
                                        <div className="leaderboard-card-container">

                                            {userListData?.length > 0 ? userListData?.map((data, key) => {
                                                return <LeaderboardCard cardData={data} key={key} index={key} />
                                            }) : <h4 className='w-100 h-100 d-flex align-items-center justify-content-center'>No Data To Show</h4>}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="monthly" title="Monthly" onClick={() => {
                                        SetUserList({
                                            allUser: false,
                                            weeklyUsers: false,
                                            monthlyUsers: true,
                                        })
                                    }}>
                                        <div className="leaderboard-card-container">
                                            {userListData?.length > 0 ? userListData?.map((data, key) => {
                                                return <LeaderboardCard cardData={data} key={key} index={key} />
                                            }) : <h4 className='w-100 h-100 d-flex align-items-center justify-content-center'>No Data To Show</h4>}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default LeaderboardPage
