import React, { useContext } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import { Controller, useForm } from "react-hook-form";
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { FilterPopupContext, FilterDataContext, CounterContext, UserContext, ProfileContext } from '../../App';
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { FilterPost } from '../../services/posts';
import BlurLoader from '../blurLoader/BlurLoader'
import { useState } from 'react'
import {GetAuthUserLocalStorage, UpdateUserOnLocalStorage} from "../../services/auth";

const FilterSearchPopup = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    const [filterData, setFilterData] = useContext(FilterDataContext)
    const [counter, setCounter] = useContext(CounterContext)

    const { register, handleSubmit, reset, watch, control, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const returnToday = () => {
        let date = new Date()
        date = moment(date).format('YYYY-MM-DD');
        return {
            to: date,
            from: date
        }
    }

    const returnAll = () =>{
        console.log(profileDetail)
        let date = new Date()
        date = moment(date).format('YYYY-MM-DD');
        return {
            to: date,
            from: profileDetail['created_at'].split("T")[0]
        }
    }

    const returnThisWeek = () => {
        let startOfWeek = moment().startOf('week').toDate();
        let endOfWeek = moment().endOf('week').toDate();

        startOfWeek = moment(startOfWeek).format('YYYY-MM-DD');
        endOfWeek = moment(endOfWeek).format('YYYY-MM-DD');

        return {
            to: startOfWeek,
            from: endOfWeek
        }
    }

    const returnLastWeek = () => {
        let startOfWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
        let endOfWeek = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')

        return {
            to: startOfWeek,
            from: endOfWeek
        }
    }

    const returnThisMonth = () => {
        let date = new Date()
        date = moment(date).format('YYYY-MM-DD');
        return {
            to: date.split("-")[0] + "-" + date.split("-")[1] + "-" + "01",
            from: date.split("-")[0] + "-" + date.split("-")[1] + "-" + "31"
        }
    }

    const returnTimeDuration = (timeDuration) => {
        if (timeDuration == 1) {
            return returnAll()
        }
        else if (timeDuration == 2) {
            return returnToday()
        }
        else if (timeDuration == 3) {
            return returnThisWeek()
        }
        else if (timeDuration == 4) {
            return returnLastWeek()
        }
        else if (timeDuration == 5) {
            return returnThisMonth()
        }
        else {
            return null
        }
    }


    const onSubmit = async (data) => {
        let user = GetAuthUserLocalStorage()
        console.log(data, "HERE I AM")
        setIsLoading(true)
        let { status, media_type, user_id, published_post, platform_ids, timeDuration } = data;



        if (status == null && media_type == null && user_id == false && published_post == null && platform_ids == false && timeDuration == null) {
            setHandleFilterPopup(false)

            navigate("/post-management")
        }

        else {
            try {
                let params = { status, media_type, user_id, published_post, platform_ids, timeDuration }
                console.log(params, 'This is params')
                params.user_id = user.id

                if (!params.status) {
                    delete params.status
                }
                if (!params.media_type) {
                    delete params.media_type
                }
                if (!params.status) {
                    delete params.status
                }
                if (!params.user_id) {
                    delete params.user_id
                }
                if (!params.published_post) {
                    delete params.published_post
                }
                if (!params.platform_ids) {
                    delete params.platform_ids
                }
                if (!params.timeDuration) {
                    // delete params.timeDuration
                    params.timeDuration.to = returnTimeDuration(1)
                }
                if (params.timeDuration) {
                    params.timeDuration = returnTimeDuration(timeDuration)
                }
                // if(params.timeDuration == null){
                //     params.timeDuration['to'] = undefined
                //     params.timeDuration['from'] = undefined
                // }

                console.log("filter Data", params)

                FilterPost(params, user.current_team.id).then(async (resp) => {

                    console.log('resp.data.data', resp.data.data)
                    setCounter(counter + 1)
                    setHandleFilterPopup(false);
                    setIsLoading(true)
                    await UpdateUserOnLocalStorage()
                    navigate("/filter-post-management", { state: { datas: resp.data.data } })
                })



                // FilterPost(params, profileDetail.id)
                // console.log("Filter respo", res.data.data)

            }
            catch (e) {
                error(e.response.data.message)
            }
        }
    }


    return (
        <div>
            <ToastContainer />
            <Modal
                className='filter-search-modal'
                show={handleFilterPopup}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className='position-relative'>
                    {isLoading ? <BlurLoader /> : null}
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Filter Your Search</h6>
                        <div onClick={() => setHandleFilterPopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>


                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='position-relative'>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>Platform</h6>
                                <div className='socialCheckbox d-flex flex-wrap'>
                                    <div className="mt-2">
                                        <input type="checkbox" id='facebook' value='1' {...register("platform_ids")} className='' />
                                        <label htmlFor="facebook" className='d-flex align-items-center justify-content-center text-center label-facebook'><FacebookIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='twitter' value='3' {...register("platform_ids")} className='' />
                                        <label htmlFor="twitter" className='d-flex align-items-center justify-content-center text-center label-twitter'><TwitterIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='instagram' value='2' {...register("platform_ids")} className='' />
                                        <label htmlFor="instagram" className='d-flex align-items-center justify-content-center text-center label-instagram'><InstagramIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='linkedin' value='4' {...register("platform_ids")} className='' />
                                        <label htmlFor="linkedin" className='d-flex align-items-center justify-content-center text-center label-linkedin'><LinkedinIcon /></label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="checkbox" id='youtube' value='5' {...register("platform_ids")} className='' />
                                        <label htmlFor="youtube" className='d-flex align-items-center justify-content-center text-center label-youtube'><YoutubeIconWithoutBg /></label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>Time Duration</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className="mt-2">
                                        <input type="radio" id='all' value="1" {...register("timeDuration")} className='' />
                                        <label htmlFor="all" className='other-label'>All</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="radio" id='today' value="2" {...register("timeDuration")} className='' />
                                        <label htmlFor="today" className='other-label'>Today</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="radio" id='this week' value="3" {...register("timeDuration")} className='' />
                                        <label htmlFor="this week" className='other-label'>This Week</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="radio" id='last week' value="4" {...register("timeDuration")} className='' />
                                        <label htmlFor="last week" className='other-label'>Last Week</label>
                                    </div>

                                    <div className="mt-2">
                                        <input type="radio" id='this month' value="5" {...register("timeDuration")} className='' />
                                        <label htmlFor="this month" className='other-label'>This Month</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>Content Type</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className='mt-2'>
                                        <input type="radio" id='photo' value="photo" {...register("media_type")} className='' />
                                        <label htmlFor="photo" className='other-label'>Photo</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="radio" id='video' value="video" {...register("media_type")} className='' />
                                        <label htmlFor="video" className='other-label'>Video</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="radio" id='text' value="text" {...register("media_type")} className='' />
                                        <label htmlFor="text" className='other-label'>Text</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>By Approval</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className='mt-2'>
                                        <input type="radio" id='approved post' value="approved" {...register("status")} className='' />
                                        <label htmlFor="approved post" className='other-label'>Approved Post</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="radio" id='pending post' value="pending" {...register("status")} className='' />
                                        <label htmlFor="pending post" className='other-label'>Pending Post</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="radio" id='rejected post' value="rejected" {...register("status")} className='' />
                                        <label htmlFor="rejected post" className='other-label'>Rejected Post</label>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <h6 className='p-0 m-0'>By Member</h6>
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    {
                                        profileDetail?.team?.team_users.length > 0 &&
                                        profileDetail?.team?.team_users.map((v, i) => (
                                            <div className="mt-2">
                                                <input type="checkbox" name={v.id} id={v.id} value={v.id} {...register("user_id")} className='' />
                                                <label htmlFor={v.id} className='other-label'>{v.name}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="pb-4 border-bottom mb-3">
                                <div className='otherCheckbox d-flex flex-wrap'>
                                    <div className='mt-2'>
                                        <input type="radio" id='most recently scheduled' value="2" {...register("published_post")} className='' />
                                        <label htmlFor="most recently scheduled" className='other-label'>Most Recently Scheduled</label>
                                    </div>

                                    <div className='mt-2'>
                                        <input type="radio" id='published posts' value="1" {...register("published_post")} className='' />
                                        <label htmlFor="published posts" className='other-label'>Published Posts</label>
                                    </div>
                                </div>
                            </div>

                            <Button type="submit" className="w-100 mt-5 btn-create-post">FILTER NOW</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FilterSearchPopup
