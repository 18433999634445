import React from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Assets from "../../constants/images";
import { StripeAccount } from "../../constants/svgs"

const FullNavigationLayout = ({ Icon, children, heading, subHeading, link, islogoShow }) => {
    return (
        <section className="full-navigation-layout d-flex">
            <div className='left d-none d-md-block'>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className='py-5 px-4'>
                                <div className="rodeo-logo mb-5">
                                    <img className='logo-img' src={Assets.Logo} alt="Logo" />
                                </div>
                                <div className="rodeo-big-img mt-5">
                                    <img className='w-100 mt-5' src={Assets.Cartoon} alt="Cartoon" />os
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='right'>
                <div className='py-4 p-md-4'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col xs={12}>
                                <div className='header p-3'>
                                    {
                                        islogoShow === true ?
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <div className=''>
                                                    <img className='show-logo' src={Assets.LogoBlack} alt="logo" />
                                                </div>

                                                <div className='ps-2'>
                                                    <h5 className='m-0 p-0 me-3'>{heading}</h5>
                                                </div>
                                            </div> :
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <div className='d-flex align-items-center'>
                                                    <h5 className='m-0 p-0 me-3'>{heading}</h5>
                                                    <OverlayTrigger
                                                        key="right"
                                                        placement="right"
                                                        overlay={
                                                            <Tooltip className='text-start information'>
                                                                <span style={{ fontSize: "14px" }} className='text-dark'>
                                                                    Creating a Post Calendar is critical to maintaining
                                                                    a uniform strategy, and it keeps you consistent.
                                                                    Every time you create or submit a post, you have the
                                                                    option to assign it to a future Post slot.  So, design
                                                                    your ideal Post Calendar and make sure your slots are
                                                                    filled ahead of time! We suggest starting light, and
                                                                    adding slots as desired - that way you're not overwhelmed
                                                                    from the start.
                                                                </span>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="d-none d-lg-inline" type="button">{<Icon />}</span>
                                                    </OverlayTrigger>
                                                </div>

                                                <div className='ps-2'>
                                                    <NavLink className="skip-navlink" to={`/${link}`}>{subHeading}</NavLink>
                                                </div>
                                            </div>


                                    }
                                </div>
                            </Col>
                        </Row>

                        {children}
                    </Container>
                </div>
            </div>
        </section>
    )
}

export default FullNavigationLayout
