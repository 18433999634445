import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Button, Col, Row } from 'react-bootstrap';
import { FileDownloadIcon} from '../../constants/svgs'

const GenericPdfDownloader = ({rootElementId , downloadFileName}) => {

    const downloadPdfDocument = () => {
        const input = document.getElementById(rootElementId);
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "pt" , "a2");
                pdf.addImage(imgData, 'JPEG', 10 , 50);
                pdf.save(`${downloadFileName}.pdf`);
            })
    }

    return <Row>
                <Col xs={12} className="mt-4">
                    <Button className="d-flex align-items-center btn-download px-3" onClick={downloadPdfDocument}>
                        <span className='d-inline-block me-4'>Download Report</span>
                        <FileDownloadIcon />
                    </Button>
                </Col>
            </Row>

}

export default GenericPdfDownloader;