import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const TutorialSteps = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleButtonClick = () => {
        setShowTooltip(!showTooltip);
    }

    const tooltip = (

        <Tooltip id="tooltip" show className='right tutorialToolTip'>
            <h4>{props?.heading}</h4>
            <p>{props?.para}</p>
        </Tooltip>

    );


    return (
        <div>

            <OverlayTrigger
                placement={props?.placement}
                defaultOverlayShown={true}
                overlay={tooltip}
            >
                <Button onClick={handleButtonClick} className={`${"tutorialStep steps"} ${props?.stepName}`}>
                    <span className='stepCount'> {props?.stepNum} </span>
                    <span className='stepIcon'>{props?.icon}</span>
                </Button>
            </OverlayTrigger>
        </div>
    );
}
export default TutorialSteps