import React, { useState, useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import YourPlanCard from '../components/plan/YourPlanCard'
import {GetAuthUserLocalStorage} from "../services/auth";

const SubscriptionManagementPage = () => {
    const profileDetail = GetAuthUserLocalStorage()

    return (
        <div className='subscription-management-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} md={8} lg={6} xl={5} xxl={4} className="mt-4">
                    {/*for all subscription*/}
                    {/*{profileDetail['all_subscriptions'].map((dt,index)=> {*/}
                    {/*    console.log(index, dt, 'This is all data')*/}
                    {/*    return (<YourPlanCard profileDetail={dt}/>)*/}
                    {/*})}*/}

                    {/*for current Subscription*/}
                    <YourPlanCard profileDetail={profileDetail['current_subscription']}/>
                    <NavLink className="d-block mt-5" to="/settings/change-choose-plan">Change Plan</NavLink>
                </Col>
            </Row>
        </div>
    )
}

export default SubscriptionManagementPage
