import React, { useState, useEffect, useContext } from 'react'
import ChoosePlanCard from '../components/plan/ChoosePlanCard'
import { Row, Col } from 'react-bootstrap';
import { getPakagesList, createSubscription } from '../services/packages.js';
import AddCardPopup from '../components/popups/AddCardPopup';
import CardConfirmationPopup from '../components/popups/CardConfirmationPopup';
import { AddCardPopupContext, CardConfirmationPopupContext, UserContext } from '../App';
import { ToastContainer } from "react-toastify";
import { success, error } from "../constants/msg";

const ChangeChoosePlanPage = () => {
  const [addCardPopup, setAddCardPopup] = useContext(AddCardPopupContext);
  const [cardConfirmationPopup, setCardConfirmationPopup] = useContext(CardConfirmationPopupContext);
  const [userData, setUserData] = useContext(UserContext);
  const [packagesData, setPackagesData] = useState();
  const [defaultPackage, setDefaultPackage] = useState('');

  //Gettting Login UserID from User Data
  let login_user_id = userData?.id;
  console.log(login_user_id, "login_user_id")

  //Fetching All the packages
  const fetchPackage = async () => {
    try {
      const resp = await getPakagesList();
      setPackagesData(resp?.data?.data)
    } catch (e) {
      console.log("Error Message On choose-plan page:", e.resp.messageq)
    }
  }
  useEffect(() => {
    fetchPackage();
  }, [])


  //Set all the choosen plan
  const setPlan = async () => {
    //todo: user-subscription-create api [login-user-id, defaultpackage]
    console.log(login_user_id, "login_user_id")
    try {
      const response = await createSubscription(login_user_id, defaultPackage)
      success(response.data.message)
    } catch (e) {
      console.log(e.response.data.message)
    }
    // alert(`${defaultPackage} purchased`)
    setCardConfirmationPopup(false)
  }

  return (
    <>
      {addCardPopup == true && <AddCardPopup defaultPackage={defaultPackage} setDefaultPackage={setDefaultPackage} />}
      {cardConfirmationPopup == true && <CardConfirmationPopup setPlan={setPlan} />}
      <div className='choose-plan-page mt-4'>
        <ToastContainer />
        <Row>
          {
            packagesData ? packagesData?.map((v, i) => {
              return (
                <ChoosePlanCard key={i} data={v} setDefaultPackage={setDefaultPackage} />
              )
            }) : <span className="text-center">Loading PLans</span>
          }
        </Row>

        <Row>
          <Col xs={12} className="mb-3 mb-sm-0">
            <p className="mt-3 text-center">Need more manager or collaborator accounts?  Contact us and we’ll find a rate that fits your needs.</p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ChangeChoosePlanPage
