import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom';
import { PostManagementData } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { AddIcon, CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
// import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
// import listPlugin from '@fullcalendar/list';
// import timeGridPlugin from '@fullcalendar/timegrid';
import { FiStar } from "react-icons/fi"
import { BsStarFill } from "react-icons/bs"
import { PendingSubmissionPopupContext } from '../App';
import PendingSubmissionPopup from "../components/dashboard/PendingSubmissionPopup"
import { GetMyProfile } from "../services/profile";
import { error, success } from "../constants/msg";
import { BookSlots, BookSlotsSingle, TeamDaysSlots } from "../services/templates";
import { isDisabled, wait } from "@testing-library/user-event/dist/utils";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import './../assets/css/styles.css'


const CalendarPage = () => {
    const navigate = useNavigate()
    const [postManagement, setPostManagement] = useContext(PostManagementData);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    //let time = ["12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"]
    const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useContext(PendingSubmissionPopupContext)

    /*const [time, setTime] = useState([
        '09 AM', '10 AM', '11 AM', '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM',
        '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM', '12 AM',
        '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM'
    ]);*/

    const [currTeam, setCurrTeam] = useState("")
    const [profile, setProfile] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState("")
    const [tempDays, setTempDays] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [postSlots, showPostSlots] = useState(false);
    const [time, setTime] = useState([]);
    const [to, setTo] = useState([]);
    const [from, setFrom] = useState([]);
    const [events, setEvents] = useState([]);
    const [slots, setSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [reset, setReset] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [bookSlotData, setBookSlotData] = useState([]);

    useEffect(() => {
        myProfile()
    }, [])

    useEffect(() => {
        getTeamDaysSlots()
    }, [currTeam, selectedTemplate])

    useEffect(() => {
        //console.log(tempDays, timeSlots)
    }, [tempDays, timeSlots])

    useEffect(() => {
        bookSlots()
    }, [currTeam, selectedTemplate, from, to])

    useEffect(() => {
        if (currTeam && selectedDate) {
            bookSingle()
        }
    }, [time, reset])

    const bookSlots = async () => {
        try {
            const response = await BookSlots(currTeam, selectedTemplate, from, to);
            if (response.data.data) {
                let sdata = [];
                response.data.data.map((dt) => {
                    sdata.push({
                        title: '',
                        date: dt.date,
                        display: 'background'
                    })
                })
                setBookSlotData(sdata);
            }
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const myProfile = async () => {
        try {
            const response = await GetMyProfile();
            let resp = response.data.data
            // console.log(resp)
            setProfile(resp);
            setSelectedTemplate(resp?.current_team?.template_id)
            setCurrTeam(resp?.current_team?.id);
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const getTeamDaysSlots = async () => {
        let response
        if (selectedTemplate == 3) {
            response = await TeamDaysSlots(currTeam, selectedTemplate);
        } else {
            response = await TeamDaysSlots('', selectedTemplate);
        }
        if (response?.data?.data) {
            let loop = [];
            response?.data?.data.map((dt) => {
                loop = [...loop, dt.day]
            })
            setTempDays(loop)
            setTimeSlots(response?.data?.data)
        }
    }

    const bookSingle = async () => {
        let response = await BookSlotsSingle(currTeam, selectedDate)
        setSlots(response.data.data)
    }

    const handleDateClick = (arg) => { // bind with an arrow function
        setIsLoading(true);
        showPostSlots(false)
        const day = arg.date.getDay();
        const date = arg.dateStr
        setSelectedDate(date);
        if (moment(arg.dateStr).format('YYYY-MM-DD') > moment(moment.now()).format('YYYY-MM-DD') ||
            moment(arg.dateStr).format('YYYY-MM-DD') == moment(moment.now()).format('YYYY-MM-DD')) {
            if (tempDays.includes(day)) {
                timeSlots && timeSlots.map(async (ts) => {
                    if (ts.day == day) {
                        setTime(ts.time);
                        showPostSlots(true)
                        setIsLoading(false);
                    }
                })

            } else {
                showPostSlots(false)
                setIsLoading(false)
            }
        } else {
            showPostSlots(false)
            setIsLoading(false)
            error('Previous Date Not Allowed')
        }
    }

    const disableDay = (arg) => {
        const day = arg.dow;
        if (!tempDays.includes(day)) {
            return 'disable-day'
        } else {
            return 'template-day'
        }
    }

    const dataBookSlot = async (arg) => {
        if (moment(arg.start).format('YYYY-MM') == moment(moment.now()).format('YYYY-MM') ||
            moment(arg.start).format('YYYY-MM-DD') >= moment(moment.now()).format('YYYY-MM-DD')) {
            setFrom(moment(arg.start).format('YYYY-MM-DD'));
            setTo(moment(arg.end).format('YYYY-MM-DD'));
        } else {
            return false;
        }
    }

    return (
        <>
            {handlePendingSubmissionPopup &&
            <PendingSubmissionPopup reset={reset} setReset={setReset} team_id={currTeam} selectedDate={selectedDate}
                                    selectedTime={selectedTime} />}
            {handleFilterPopup && <FilterSearchPopup />}

            <ToastContainer />
            <div className="calendar-page mb-4">
                <Row className="mt-4">
                    <Col xs={12} className="d-block d-md-none mb-3">
                        <h5 className='text-yellow'>POST MANAGEMENT</h5>
                    </Col>

                    <Col xs={12} xl={8} className="mb-4">
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"}
                                     to='/post-management'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)}
                                    className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>

                        <div className='calendar-container p-4 pt-3 pb-4 mt-4'>
                            <FullCalendar
                                className="w-100"
                                selectable={true}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                weekends={true}
                                clickable={false}
                                showNonCurrentDates={false}
                                headerToolbar={{
                                    center: 'today',
                                    right: "prev, next",
                                    left: "title",
                                }}
                                datesSet={dataBookSlot}
                                events={bookSlotData}
                                dateClick={handleDateClick}
                                dayCellClassNames={disableDay}
                            />
                        </div>
                    </Col>

                    <Col xs={12} xl={4} className="mb-4">
                        {
                            isLoading && <div>Loading...</div>
                        }
                        <div className={`time-slot ${postSlots ? 'd-block' : 'd-none'}`}>
                            <h6>Post Slots</h6>
                            <div className={`time-slot-container p-4 pt-3 cutom-margin`}>
                                <ul>
                                    {
                                        time && time.map((time, i) => {
                                            return (
                                                <li key={i}>
                                                    <div
                                                        className='add-event-box border-bottom  d-flex flex-wrap flex-flow-column justify-content-between align-items-center'>
                                                        <small className='mt-0'>{time}</small>
                                                        <div className='slots-box'>
                                                            {
                                                                slots && slots.length > 0 && slots.map((value) => {
                                                                    if (value?.time == time) {
                                                                        let data = []
                                                                        value?.slots?.map((dt) => {
                                                                            data.push(<div
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    navigate(`/post-management/${dt?.id}`)
                                                                                }}
                                                                                className={`w-100  p-3  add-event-container bg-green d-flex align-items-center justify-content-between`}>
                                                                                <div>
                                                                                    <BsStarFill
                                                                                        className='text-white' />
                                                                                    <small
                                                                                        className='text-white ms-2'>{dt?.text_limit}</small>
                                                                                </div>
                                                                            </div>)
                                                                        })
                                                                        return <>{data}
                                                                            {/*<div style={{cursor: "pointer"}}
                                                                                 onClick={() => setHandlePendingSubmissionPopup(true)}
                                                                                 className={`w-100 p-3 add-event-container bg-grey d-flex align-items-center justify-content-between`}>
                                                                                <div>
                                                                                    <FiStar className='text-grey'/>
                                                                                    <small className='ms-1'>Empty
                                                                                        Slot</small>
                                                                                </div>
                                                                                <AddIcon/>
                                                                            </div>*/}
                                                                        </>
                                                                    }
                                                                })
                                                            }
                                                            <div style={{ cursor: "pointer" }}
                                                                 onClick={() => {
                                                                     setSelectedTime(time)
                                                                     setHandlePendingSubmissionPopup(true)
                                                                 }}
                                                                 className={`w-100 p-3 add-event-container bg-grey d-flex align-items-center justify-content-between`}>
                                                                <div>
                                                                    <FiStar className='text-grey' />
                                                                    <small className='ms-1'>Empty Slot</small>
                                                                </div>
                                                                <AddIcon />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CalendarPage
