import React from 'react'
import { Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { AutoPostIcon, ChangePasswordIcon, PrivacyPolicyIcon, PushNotificationIcon, RewardManagementIcon, SocialAccountManagementIcon, SubscriptionManagementIcon, TeamPostCalendarIcon, TeamProfileIcon, TermAndConditionIcon } from '../../constants/svgs'

const SettingNavigation = () => {
    return (
        <Col xs={12} lg={12} xl={3} className="mt-3">
            <div className='setting-navigation'>
                <NavLink to="/settings/rewards-management" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <RewardManagementIcon />
                    <span className='d-inline-block ms-3'>Rewards Management</span>
                </NavLink>
                <NavLink to="/settings/social-accounts-management" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <SocialAccountManagementIcon />
                    <span className='d-inline-block ms-3'>Social Accounts Management</span>
                </NavLink>
                <NavLink to="/settings/subscription-management" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <SubscriptionManagementIcon />
                    <span className='d-inline-block ms-3'>Subscription Management</span>
                </NavLink>
                <NavLink to="/settings/push-notification-management" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <PushNotificationIcon />
                    <span className='d-inline-block ms-3'>Push Notifications Management</span>
                </NavLink>
                {/*<NavLink to="/settings/auto-post" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>*/}
                {/*    <AutoPostIcon />*/}
                {/*    <span className='d-inline-block ms-3'>Auto Post</span>*/}
                {/*</NavLink>*/}
                <NavLink to="/settings/create-team-post-calendar" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <TeamPostCalendarIcon />
                    <span className='d-inline-block ms-3'>Team Post Calendar</span>
                </NavLink>
                <NavLink to="/settings/change-password" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <ChangePasswordIcon />
                    <span className='d-inline-block ms-3'>Change Password</span>
                </NavLink>
                <NavLink to="/settings/team-profile" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <TeamProfileIcon />
                    <span className='d-inline-block ms-3'>Team Profile</span>
                </NavLink>
                <NavLink to="/settings/terms-and-conditions" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <TermAndConditionIcon />
                    <span className='d-inline-block ms-3'>Terms and Conditions</span>
                </NavLink>
                <NavLink to="/settings/privacy-policy" className={({ isActive })=> isActive ? "d-flex align-items-center p-3 active" : "d-flex align-items-center p-3"}>
                    <PrivacyPolicyIcon />
                    <span className='d-inline-block ms-3'>Privacy Policy</span>
                </NavLink>
            </div>
        </Col>
    )
}

export default SettingNavigation
