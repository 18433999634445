import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

//Team Platform
export const GetTeamPlatform = async (team_id, platform_id, access_id, access_token, url) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.team_platforms}`, { team_id, platform_id, access_id, access_token, url }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return resp
}

export const GetTeamPlatformByTeamId = async (userTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_platforms}?team_id=${userTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return resp
}