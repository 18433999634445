import React, { useState, useEffect, useContext } from 'react'
import ChoosePlanCard from '../components/plan/ChoosePlanCard'
import { Row, Col } from 'react-bootstrap';
import { getPakagesList, createSubscription } from '../services/packages.js';
import AddCardPopup from '../components/popups/AddCardPopup';
import CardConfirmationPopup from '../components/popups/CardConfirmationPopup';
import { GetMyProfile } from '../services/profile';
import { AddCardPopupContext, CardConfirmationPopupContext, UserContext } from '../App';
import { ToastContainer } from "react-toastify";
import { success, error } from "../constants/msg";
import {UpdateUserOnLocalStorage} from "../services/auth";

const ChoosePlanPage = () => {
  const [addCardPopup, setAddCardPopup] = useContext(AddCardPopupContext);
  const [cardConfirmationPopup, setCardConfirmationPopup] = useContext(CardConfirmationPopupContext);
  const [userData, setUserData] = useContext(UserContext);
  const [packagesData, setPackagesData] = useState();
  const [defaultPackage, setDefaultPackage] = useState('');
  const [currentCard, setCurrentCard] = useState('');

  //Gettting Login UserID from User Data
  let login_user_id = userData?.id;

  //Fetching All the packages
  const fetchPackage = async () => {
    try {
      const resp = await getPakagesList();
      setPackagesData(resp?.data?.data)
    } catch (e) {
      console.log("Error Message On choose-plan page:", e.resp.messageq)
    }
  }
  //Fetching Profile
  const fetchProfile = async () => {
    try {
      const resp = await GetMyProfile();
      //console.log("Profile data:", resp.data.data.current_card)
      if(resp?.data?.data?.current_card){
        setCurrentCard(resp.data.data.current_card);
      }
    } catch (e) {
      console.log(e.resp.data.data.message)
    }
  }
  useEffect(() => {
    fetchPackage();

  }, [])

  useEffect(() => {
    fetchProfile();
  }, [currentCard])

  //Set all the choosen plan
  const setPlan = async () => {
    //todo: user-subscription-create api [login-user-id, defaultpackage]
    try {
      const response = await createSubscription(login_user_id, defaultPackage)
      await UpdateUserOnLocalStorage()
      success(response.data.data.message)
    } catch (e) {
      error("error")
    }

    setCardConfirmationPopup(false)

    //navigate to dashboard
  }

  return (
    <>
      {addCardPopup == true && <AddCardPopup defaultPackage={defaultPackage} setDefaultPackage={setDefaultPackage} setCurrentCard={setCurrentCard} />}
      {cardConfirmationPopup == true && <CardConfirmationPopup setPlan={setPlan} />}
      <div className='choose-plan-page mt-4'>
        <ToastContainer />
        <Row>
          {
            packagesData ? packagesData?.map((v, i) => {
              return (
                <ChoosePlanCard key={i} data={v} setDefaultPackage={setDefaultPackage} currentCard={currentCard} />
              )
            }) : <span className="text-center">Loading PLans</span>
          }
        </Row>

        <Row>
          <Col xs={12} className="mb-3 mb-sm-0">
            <p className="mt-3 text-center">Need more manager or collaborator accounts?  Contact us and we’ll find a rate that fits your needs.</p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ChoosePlanPage
