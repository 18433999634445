import React, { useContext, useEffect, useRef, useState, Component } from 'react'
import { Button, Carousel, Form, InputGroup, Modal, Row, Col } from 'react-bootstrap'
import { FaTimes, FaRegPlayCircle } from "react-icons/fa"
import Assets from '../../constants/images'
import {
    AddUserIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    LocationIcon,
    TwitterIcon,
    YoutubeIcon,
    YoutubeIconWithoutBg
} from '../../constants/svgs'
import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import { Controller, useForm } from "react-hook-form";
import {
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    VALIDATIONS,
    VALIDATIONS_TEXT
} from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { CreatePostPopupContext, CurrentTeamContext, ProfileContext, UserContext } from "../../App";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-datepicker';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Select from 'react-select'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Geocode from "react-geocode";
import { UploadFileToS3 } from '../../utlis/helper'
import { constant, s3Credential } from '../../utlis/constants'
import moment from "moment"
import { CreatePost } from '../../services/posts'
import { GetMyProfile } from "../../services/profile";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { TeamDaysSlots } from "../../services/templates";

const CreatePostPopup = (props) => {
    Geocode.setApiKey("AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw");
    Geocode.setLanguage("en");

    const [options, setOptions] = useState([]);
    const [timeOptions, setTimeOptions] = useState()
    const selectTime = useRef()
    const selectDate = useRef()

    let markersList = [
        { lat: null, lng: null },
    ]

    const [customError, setCustomError] = useState(false);
    const [userData, setUserData] = useContext(UserContext)
    const [value, onChange] = useState(new Date());
    const [uploadedImage, setUploadedImage] = useState([]);
    const [showLocationPickerModal, setShowLocationPickerModal] = useState(false);
    const [handleCreatePostPopup, setHandleCreatePostPopup] = useContext(CreatePostPopupContext);
    let [markers, setMarkers] = useState(markersList);
    const [location, setLocation] = useState("")
    const { register, handleSubmit, reset, watch, control, setValue, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });
    const [profile, setProfile] = useState([]);
    const [teamPlatform, setTeamPlatform] = useState([]);
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [locationValue, setLocationValue] = useState(null);
    const [newDate, setNewDate] = useState(new Date());
    const [selectedTemplate, setSelectedTemplate] = useState("")
    const [currTeam, setCurrTeam] = useState("")
    const [disableDate, setDisableDate] = useState(true);
    const [timeSlots, setTimeSlots] = useState(true);
    const [tempDays, setTempDays] = useState([]);
    const [selectedTimeDisable, setSelectedTimeDisable] = useState(true);
    const [defaultTimeSlot, setDefaultTimeSlot] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");

    const mapStyles = {
        position: "relative",
        width: "100%",
        height: "100%"
    };

    useEffect(() => {
        myProfile()
    }, [])

    useEffect(() => {
        getTeamDaysSlots()
        setDisableDate(false);
    }, [currTeam, selectedTemplate])

    const myProfile = async () => {
        let response = await GetMyProfile()
        let resp = response?.data?.data
        setTeamPlatform(resp?.current_team?.platforms);
        setSelectedTemplate(resp?.current_team?.template?.id)
        setCurrTeam(resp?.current_team?.id);
        let teams = resp?.team;
        let option = []
        if (teams && teams.team_users.length > 0) {
            teams.team_users.map((v, i) => {
                option.push({
                    value: v.id,
                    label: v?.name
                })
            })
            setOptions(option)
        }
        setProfile(resp.data)
    }

    const getTeamDaysSlots = async () => {
        let response
        if (selectedTemplate == 3) {
            response = await TeamDaysSlots(currTeam, selectedTemplate);
        } else {
            response = await TeamDaysSlots('', selectedTemplate);
        }
        if (response?.data?.data) {
            let loop = [];
            response?.data?.data.map((dt) => {
                loop = [...loop, dt.day]
            })
            setTempDays(loop)
            setTimeSlots(response?.data?.data)
        }
    }

    const isWeekday = (date) => {
        const day = date.getDay();
        return tempDays.includes(day);
    };

    let onMarkerDragEnd = (coord, index, markers) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        markers[index] = { lat, lng };
        setMarkers(markers);
        saveLocationName(lat, lng)
    }

    let myMarkers = markers && Object.entries(markers).map(([key, val]) => (
        <Marker key={key} id={key} position={{
            lat: val.lat,
            lng: val.lng
        }}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord, key, markers)}
        />
    ))

    const handleImageUpload = (e) => {
        setUploadedImage([...uploadedImage, ...e.target.files])
    }

    const deleteImage = (i) => {
        let temp = [...uploadedImage]
        temp.splice(i, 1)
        setUploadedImage(temp)
    }

    const returnFileType = (file) => {
        let fileType = file.name.split(".")
        fileType = fileType[fileType.length - 1]

        if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "jfif") {
            fileType = "image"
        } else if (fileType === "pdf" || fileType === "docx" || fileType === "doc" || fileType === "txt" || fileType === "xlsx" || fileType === "tiff") {
            fileType = "document"
        } else if (fileType === "mp3") {
            fileType = "mp3"
        } else if (fileType === "mp4" || fileType === "mov") {
            fileType = "video"
        } else {
            fileType = "invalid"
        }
        return fileType
    }

    const onSubmit = async (data) => {
        let time = moment(startTime, "hh:mm A").format("HH:mm:ss")
        let schedule_time = moment(startDate).format('YYYY-MM-DD') + " " + time;
        //let schedule_time = moment(data.recuringTime).format('YYYY-MM-DD HH:mm:ss');
        let coordinate = [];
        if (locationValue) {
            await geocodeByAddress(locationValue.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    coordinate = { lat: lat, lng: lng }
                });
        }

        let medias = []

        if (uploadedImage.length > 0) {
            for (let i = 0; i < uploadedImage.length; i++) {
                try {
                    await UploadFileToS3(uploadedImage[i], constant.userDirName).then((document) => {
                        medias.push({
                            type: returnFileType(uploadedImage[i]),
                            url: s3Credential.s3EndPoint + document.url
                        })
                    })
                } catch (e) {
                    error(e.response.data.message)
                }
            }
        }

        let addPeople = []
        data.addPeople.map(d => addPeople.push(d.value))

        let params = {
            template_id: selectedTemplate,
            user_id: userData.id,
            team_id: userData.current_team.id,
            text: data.caption,
            status: 'pending',
            location: locationValue?.label || "",
            latitude: coordinate?.lat || "",
            longitude: coordinate?.lng || "",
            schedule_time: schedule_time ? schedule_time : '',
            user_ids: addPeople,
            platform_ids: data.socialPlatform.length >= 1 ? data.socialPlatform : [data.socialPlatform],
            medias: medias
        }

        try {
            const resp = await CreatePost(params)
            success(resp.data.message)
            reset()
            setTimeout(() => {
                setHandleCreatePostPopup(false)
                setShowLocationPickerModal(false)
            }, 1500)
        } catch (e) {
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        setValue("recuringTime", value)
        // setValue("addPeople", [])
        setValue("image", uploadedImage)
    }, [value, uploadedImage])

    //Adding team members

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setMarkers([
                    { lat: position.coords.latitude, lng: position.coords.longitude }
                ])
            });
        }
    }, [userData])

    const saveLocationName = async (lat, lng) => {
        try {
            const res = await Geocode.fromLatLng(lat, lng);
            setLocation(res.results[0].formatted_address)
        } catch (e) {
            error(e)
        }

    }

    const datePickerOnChange = (date) => {
        setStartDate(date)
        let ts = timeSlots.filter((v) => v.day == date.getDay())
        //setDefaultTimeSlot(ts);
        let data = []
        ts.map((v, i) => {
            if (v.time && v.time.length > 0) {
                v.time.map((time) => {
                    data.push({
                        value: time,
                        label: time
                    })
                });
            }
        })
        setTimeOptions(data)
        setNewDate(moment(date).format('ddd MMM DD, yyyy'));
        setSelectedTimeDisable(false);
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                className='location-picker-modal'
                show={showLocationPickerModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between p-3  border-bottom">
                        <h6 className='p-0 m-0'>Select Your Location</h6>
                        <div onClick={() => setShowLocationPickerModal(false)}
                            className="d-flex align-items-center justify-content-center text-center fa-times">
                            <FaTimes /></div>
                    </div>

                    <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                        <Map
                            google={props.google}
                            zoom={14}
                            style={mapStyles}
                            initialCenter={
                                {
                                    lat: markers[0].lat,
                                    lng: markers[0].lng
                                }
                            }
                        >
                            {myMarkers}
                        </Map>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                className='create-post-modal'
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div
                        className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>
                            Create a New Post</h6>
                        <div onClick={() => setHandleCreatePostPopup(false)}
                            className="d-flex align-items-center justify-content-center text-center fa-times">
                            <FaTimes /></div>
                    </div>

                    <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                        <div className='avatar-container d-flex align-items-center mb-3'>
                            {profileDetail?.current_team?.profile_picture === null ?
                                <img src={Assets.ProfilePlaceholderImage}
                                    onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} className="border-radius-100" />
                                : <img src={profileDetail?.current_team?.profile_picture}
                                    onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} className="border-radius-100" />}

                            <h6 className='d-inline-block p-0 m-0 ms-2'>{profileDetail?.current_team?.name || 'N/A'}</h6>
                        </div>

                        <div>
                            <InputGroup className='mt-2'>
                                <Form.Control
                                    name="caption"
                                    placeholder="Add a caption"
                                    className="pt-3 form-control"
                                    as="textarea"
                                    autoFocus
                                    rows={3}
                                    {...register("caption",
                                        {
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.CAPTION_REQUIRED
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.CAPTION_MAX,
                                                message: VALIDATIONS_TEXT.CAPTION_MAX
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.caption && <small className='text-red'>{errors.caption.message}</small>}

                            <InputGroup className='mt-2'>
                                <Select
                                    className='w-100 custom-select-input'
                                    onChange={(e) => {
                                        setValue("addPeople", e)
                                    }}
                                    isMulti
                                    options={options}
                                />
                            </InputGroup>


                            <div className='select-location mt-2'
                                style={{ width: '100%', border: 'none', background: '#f8f9fd' }}>
                                <InputGroup>
                                    <GooglePlacesAutocomplete
                                        className={"pt-3 form-control input-icon input-icon-location  w-100 "}
                                        onLoadFailed={(error) => (
                                            console.error("Could not connect Google script", error)
                                        )}
                                        selectProps={{
                                            locationValue,
                                            onChange: setLocationValue,
                                            className: 'input-icon input-icon-location',
                                            placeholder: "Write Location...",
                                        }}
                                        minLengthAutocomplete={2}
                                        apiKey="AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
                                        {...register("location")}
                                    />
                                </InputGroup>
                            </div>

                            {errors.location && <small className='text-red'>{errors.location.message}</small>}
                            <Row>
                                <Col xs={6} xl={6} lg={6} md={12}>
                                    <div className="mt-2 d-flex flex-row ">
                                        <DatePicker
                                            ref={selectDate}
                                            disabled={disableDate}
                                            placeholderText={'Please select a date'}
                                            className={"pt-3 form-control input-icon input-icon-clockIcon"}
                                            onChange={(date) => datePickerOnChange(date)}
                                            filterDate={isWeekday}
                                            value={newDate}
                                            selected={startDate}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} xl={6} lg={6} md={12}
                                    className={`${selectedTimeDisable ? 'd-none' : 'd-block'}`}>
                                    {/* Time Dropdown Select */}
                                    <div
                                        className={`mt-2 d-flex flex-row`}>
                                        <Form.Select aria-label="Default select example"
                                            onChange={e => setStartTime(e.target.value)}
                                            className='w-100 custom-select-input timedropdown form-control select-dropdown'
                                        >
                                            <option>Selected Time</option>
                                            {timeOptions?.map((v, i) => (
                                                <option value={v?.value}>{v?.label}</option>
                                            ))}


                                        </Form.Select>

                                        {/* <Select
                                            onChange={(selectedOptions) => {
                                                setStartTime(selectedOptions.value)
                                            }}
                                            className='w-100 custom-select-input timedropdown'
                                            //isMulti
                                            options={timeOptions}
                                            placeholder={"Selected Time"}
                                        /> */}
                                    </div>
                                </Col>
                            </Row>


                            <InputGroup className='mt-2'>
                                <Form.Control
                                    readOnly
                                    placeholder="Add Image/Video"
                                    className="bg-none input-icon input-icon-addImage"
                                />
                            </InputGroup>

                            <div className="mb-3 d-flex flex-row ">
                                <input
                                    // {...register("image", {
                                    //     required: {
                                    //         value: true,
                                    //         message: VALIDATIONS_TEXT.IMAGE_REQUIRED
                                    //     }
                                    // })
                                    // }
                                    className={`form-control custom-file-input ${uploadedImage.length > 0 && "mt-15"} me-3`}
                                    name="image"
                                    id="img"
                                    type="file"
                                    multiple
                                    accept=".png, .jpeg, .jpg, .mp4, .mov"
                                    onChange={(e) => handleImageUpload(e)}

                                />


                                <Swiper
                                    // navigation
                                    modules={[Navigation]}
                                    spaceBetween={10}
                                    className="m-0"
                                    breakpoints={{
                                        200: {
                                            slidesPerView: 2,
                                        },
                                        300: {
                                            slidesPerView: 3,
                                        },
                                        640: {
                                            slidesPerView: 5,
                                        },
                                        768: {
                                            slidesPerView: 6,
                                        },
                                        992: {
                                            slidesPerView: 8,
                                        },
                                        1200: {
                                            slidesPerView: 10,
                                        },
                                        1300: {
                                            slidesPerView: 10,
                                        },
                                    }}
                                >
                                    {
                                        uploadedImage.length > 0 &&
                                        uploadedImage.map((v, i) => {
                                            let url = URL.createObjectURL(v);
                                            let fileType = v?.type

                                            return (
                                                <>
                                                    {fileType === "video/mp4" ? <SwiperSlide>
                                                        < div className="box video-box d-flex justify-content-center align-items-center">
                                                            <div className='icon'>
                                                                <FaTimes onClick={() => deleteImage(i)} />
                                                            </div>
                                                            <FaRegPlayCircle />
                                                        </div>
                                                    </SwiperSlide> :
                                                        <SwiperSlide>
                                                            <div className="box">
                                                                <div className='icon'>
                                                                    <FaTimes onClick={() => deleteImage(i)} />
                                                                </div>
                                                                <img src={url} alt="PostImage1" />
                                                            </div>
                                                        </SwiperSlide>}
                                                </>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            {errors.image && <small className='text-red'>{errors.image.message}</small>}

                            <InputGroup className='mt-2'>
                                <Form.Control readOnly placeholder="Select Platform(s)"
                                    className="bg-none input-icon input-icon-shareIcon" />
                            </InputGroup>

                            <div className='socialCheckbox d-flex w-100'>
                                {(teamPlatform && teamPlatform.length > 0) ?
                                    teamPlatform.map((data, index) => {
                                        return (
                                            <div key={index} className='platform-box'>
                                                < input
                                                    value={data?.platform_id}
                                                    type="checkbox"
                                                    id={data?.platform?.name || "No Name"}
                                                    //onClick={(e)=>setValue("socialPlatform", e.target.value)}
                                                    className=''
                                                    {
                                                    ...register("socialPlatform", {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.SOCIAL_PLATFORM_REQUIRED
                                                        },
                                                    })
                                                    }
                                                />
                                                <label htmlFor={data?.platform?.name || "No name"}
                                                    className={`d-flex align-items-center justify-content-center text-center label-${data?.platform.name.toLowerCase()}`}>

                                                    {data?.platform_id == 1 && <FacebookIcon />}
                                                    {data?.platform_id == 2 && <TwitterIcon />}
                                                    {data?.platform_id == 3 && <InstagramIcon />}
                                                    {data?.platform_id == 4 && <LinkedinIcon />}
                                                    {data?.platform_id == 5 && <YoutubeIconWithoutBg />}

                                                </label>
                                            </div>
                                        )
                                    })


                                    : (<p className='mt-4'>No Platform Has Been Login Yet</p>)}
                            </div>
                            {errors.socialPlatform &&
                                <small className='text-red'>{errors.socialPlatform.message}</small>}

                            <Button disabled={value === null} type="submit" className="w-100 mt-5 btn-create-post">CREATE
                                POST</Button>
                        </div>
                    </Form >
                </Modal.Body >
            </Modal >
        </div >
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
})(CreatePostPopup);
