import React from 'react'
import { NavLink } from 'react-router-dom';
import InviteTeamCard from '../account-setup/InviteTeamCard';
import { Row, Col } from 'react-bootstrap'
import { constant } from '../../utlis/constants';
import InviteCollaboratorCard from '../dashboard/InviteCollaboratorCard';
import InviteManagerCard from '../dashboard/InviteManagerCard';
import { useLocation } from 'react-router-dom';



const InviteTeamForm = () => {
    const location = useLocation();
    const { myState } = location.pathname
    console.log("location:", location)
    return (
        <div className='invite-team-form mt-5 position-relative'>

            <Row>
                <Col xs={12} className="d-none custom-d-block mb-3">
                    <div className="text-end">
                        <NavLink className="text-dark me-3" to="/create-team-post-calendar">Skip To Team Post Calendar</NavLink>
                    </div>
                </Col>

                <Col xs={12} sm={6} md={12} lg={6} xl={5} className="mb-4">
                    <InviteCollaboratorCard heading="Invite Collaborators" buttonText="INVITE COLLABORATOR" content="Invite team members best suited to submit content -
                collaborators will be able to use the mobile app (only)
                to snap and submit content at lightning speed!" />
                    {/* <InviteTeamCard roleId={constant.COLLABORATOR_ROLE_ID} heading="Invite Collaborators" buttonText="INVITE COLLABORATOR" /> */}
                </Col>

                <Col xs={12} sm={6} md={12} lg={6} xl={5}>
                    <InviteManagerCard heading="Invite Manager" buttonText="INVITE Manager" content="Invite team members best suited to submit content -
                collaborators will be able to use the mobile app (only)
                to snap and submit content at lightning speed!" />
                    {/* <InviteTeamCard roleId={constant.MANAGER_ROLE_ID} heading="Invite Managers" buttonText="INVITE MANAGER" /> */}
                </Col>

                <NavLink
                    key={"myRoute"}
                    to="/create-team-post-calendar"
                    state={{ myState: "inviteTeam" }}
                    className="mt-5"
                >
                    Continue</NavLink>
            </Row>
        </div>
    )
}

export default InviteTeamForm