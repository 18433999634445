import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

// get all notifications

export const getAllNotification = async (curr_team) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.notifications}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}



//pull Notification

export const putNotification = async (notification_ID, read_at) => {
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.notifications}/${notification_ID}`, { read_at }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Push Notification and Team Notification

export const pushNotification = async (formData) => {
    for (var key of formData.entries()) {
        console.log(key[0] + ', ' + key[1]);
    }

    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.team_notifications}`, formData, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage(),
            'content-type': 'multipart/form-data'
        }
    }

    );
    return resp
}