import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { InstagramLogo, FacebookLogo, TwitterLogo, LinkedinLogo, YoutubeLogo } from "../../constants/svgs"
import { AddCardPopupContext, CardConfirmationPopupContext } from '../../App';
import parse from 'html-react-parser';
import {GetAuthUserLocalStorage} from "../../services/auth";

const ChoosePlanCard = ({ data, setDefaultPackage, currentCard }) => {
  const [user, setUser] = useState(GetAuthUserLocalStorage())
  const [addCardPopup, setAddCardPopup] = useContext(AddCardPopupContext);
  const [CardConfirmationPopup, setCardConfirmationPopup] = useContext(CardConfirmationPopupContext);
  console.log(data, "This is user from plans")
  const SubscriptionSelection = (id) => {
    if(user?.current_subscription===null){
      return false
    } else{
      if(user?.current_subscription?.package?.id == id){
        return true
      }else{
        return false
      }
    }
  }
  return (
    <>
      <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4">
        <div className='choose-plan-card p-4'>
          <div className='text-center'>
            <h3>{data?.name}: ${data?.price}/MO </h3>
            <div className="description-container">
              <p>{data.short_desc == null ? "Description not added" : data.short_desc}</p>
            </div>
            <h4>{data.text}</h4>
          </div>

          <div className="icon-div text-center mt-4">
            <ul>
              <li className='me-2 me-sm-3'><NavLink to="#"><InstagramLogo /></NavLink></li>
              <li className='me-2 me-sm-3'><NavLink to="#"><FacebookLogo /></NavLink></li>
              <li className='me-2 me-sm-3'><NavLink to="#"><TwitterLogo /></NavLink></li>
              <li className='me-2 me-sm-3'><NavLink to="#"><LinkedinLogo /></NavLink></li>
              <li className='me-2 me-sm-3'><NavLink to="#"><YoutubeLogo /></NavLink></li>
            </ul>
          </div>

          <div className='mt-4 list-container'>
            <p>{parse(data.descriptions)}</p>
            {/* <ul>
              {
                data.list.map((list_item, i) => {
                  return (
                    <li className="mb-3" key={i}>
                      {list_item}
                    </li>
                  )
                })
              }
            </ul> */}
          </div>

          <div className='text-center my-4'>
            <Row className="justify-content-center">
              <Col xs={10}>
                <Button className="btn-select w-100"
                        // disabled={(user?.current_subscription === null) ? (user?.current_subscription?.package?.id == data.id ? true : false): ''}
                        disabled={!SubscriptionSelection()}
                        onClick={() => {
                  setDefaultPackage(data.id)
                  currentCard ? setCardConfirmationPopup(true) : setAddCardPopup(true)

                // }}>{user.all_subscriptions[0].package.id == data.id ? "CURRENT PLAN" : "SELECT"}</Button>
                }}>{SubscriptionSelection(data.id) === true ? "CURRENT PLAN" : "SELECT"}</Button>
              </Col>
            </Row>
          </div>
        </div >
      </Col >
    </>
  )
}

export default ChoosePlanCard
