import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

//Get Single Team

export const getSingleTeam = async (userTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.teams}/${userTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


