import {errorMessages, s3Credential} from "./constants";
import S3 from "react-aws-s3";

window.Buffer = window.Buffer || require("buffer").Buffer;

export const UploadFileToS3 = async (file, dirName) => {
    let newFileName = file.name;
    if (file.name.split("")[0] == ".") {
        newFileName = newFileName.slice(1, newFileName.length)
    }
    newFileName = newFileName.split(" ").join("-")

    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(s3Credential.fileSize, 10)) {
        return {
            success: false,
            message: errorMessages.fileSize
        }
    }

    const config = {
        bucketName: s3Credential.bucketName,
        dirName: dirName,
        region: s3Credential.region,
        accessKeyId: s3Credential.accessKeyId,
        secretAccessKey: s3Credential.secretAccessKey,
        s3Url: s3Credential.s3EndPoint,
    };

    const ReactS3Client = new S3(config);
    let responseData = {};
    await ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data && data.status === 204) {
            responseData = {
                success: true,
                message: errorMessages.fileSuccess,
                url: dirName + "/" + newFileName,
                mime_type: file.type
            }
        } else {
            responseData = {
                success: false,
                message: errorMessages.fileError
            }
        }
    })
    return responseData;
}

