import React, { useContext, useEffect, useState } from 'react';
import { Router, useNavigate } from 'react-router-dom';
import { Modal, FormCheck, Form } from 'react-bootstrap'
import { StarIcon } from '../../constants/svgs'
import { FaTimes } from "react-icons/fa";
import Assets from '../../constants/images';
import { ProfileContext, UserContext, CurrentTeamContext, AddTeamContext, SwitchTeamContext } from "../../App";
import { GetMyProfile } from "../../services/profile";
import { success, error } from "../../constants/msg";
import BlurLoader from '../blurLoader/BlurLoader';
import { RoundedPlus } from '../../constants/svgs';
import { CurrentTeam } from '../../services/teams';
import { UpdateUserOnLocalStorage} from "../../services/auth";

const SwitchTeamPopup = () => {

    const [switchUserPopup, setSwitchUserPopup] = useContext(SwitchTeamContext);
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [currentTeam, setCurrentTeam] = useContext(CurrentTeamContext);
    const [addTeamPopup, setAddTeamPopup] = useContext(AddTeamContext);

    // Use States
    const [selectedTeam, setSelectedTeam] = useState()
    const [profileData, setProfileData] = useState();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        FetchCurrentTeam();
    }, [currentTeam]);

    const FetchCurrentTeam = async () => {
        console.log("Current Team API:", currentTeam, "User id API", profileDetail?.id,)
        try {
            const response = await CurrentTeam(currentTeam*1, profileDetail.id);
            setProfileDetail(response.data.data)
            await UpdateUserOnLocalStorage()
            setLoading(false)
        } catch (e) {
            console.log(e.response.data.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <Modal
                className='switch-team-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                        <div className="modal-header w-100">
                            <h6 className='p-0 m-0'>My Teams</h6>
                            <div onClick={() => setSwitchUserPopup(false)} className="cursor-pointer cross-icon d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                        </div>
                        <div className="modal-body w-100 pt-0 pb-0">
                            <div className="team-container">
                                {loading ?
                                    <div className="loader mt-4 mb-4">
                                        <BlurLoader />
                                    </div>

                                    : (<div className='modal-box'>
                                        <Form>
                                            {profileDetail?.teams?.map((v, i) => {
                                                return (
                                                    <div className="team-card  pb-3 pt-3 border-bottom">
                                                        <div className="d-flex justify-content-between w-100">
                                                            <Form.Check
                                                                name="currentTeam"
                                                                type={"radio"}
                                                                defaultChecked={currentTeam == v?.team_id ? true : false}
                                                                checked={currentTeam == v?.team_id ? true : false}
                                                                value={v?.team_id}
                                                                onChange={(e) => setCurrentTeam(e.target.value)}
                                                                label={
                                                                    <div className="team-content d-flex align-items-start">
                                                                        <div className="team-img">
                                                                            {v.profile_picture == null ? <img src={v?.team.profile_picture || Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} />
                                                                                : <img src={v?.team.profile_picture} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} />}

                                                                        </div>
                                                                        <div className="team-details ms-3">
                                                                            <h6 className='m-0'>{v?.team.name}</h6>
                                                                            <div className="team-points d-flex align-items-center">
                                                                                <div className="pt-icon">
                                                                                    <StarIcon />
                                                                                </div>
                                                                                <small className="ms-2">{v?.team.streaks_points} pt</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>{/*
                                                        {['radio'].map((type) => (

                                                        ))}*/}
                                                    </div>
                                                )
                                            })}
                                        </Form>
                                    </div>)}
                            </div>
                            <div className="add-team-btn d-flex align-items-center text-yellow mt-4 mb-3" onClick={() => (setAddTeamPopup(true), setSwitchUserPopup(false))} >
                                <div className="add-icon">
                                    <RoundedPlus />
                                </div>
                                <h6 className='ms-2 mb-0 text-capitalize'>Add more team</h6>
                            </div>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >
        </div >
    )
}

export default SwitchTeamPopup
