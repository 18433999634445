import React, { useEffect, useState, useContext } from 'react'
import { Form, Button, InputGroup, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import Assets from "../../constants/images";
import { CameraIcon } from '../../constants/svgs';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { FaTimes } from "react-icons/fa";
import { UploadFileToS3 } from "../../utlis/helper";
import { UpdateTeamById } from '../../services/teams';
import { constant } from "../../utlis/constants";

//Context Import
import { CurrentTeamContext, ProfileContext } from '../../App';

const TeamProfileForm = (props) => {

    const [showLocationPickerModal, setShowLocationPickerModal] = useState(false);
    const [preview, setPreview] = useState("");
    const [currTeamData, setCurrTeamData] = useState();
    const [currTeamId, setCurrTeamId] = useContext(CurrentTeamContext);
    const [btnActive, setBtnActive] = useState("false");
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [image, setImage] = useState()

    console.log(profileDetail)

    var curr_team = profileDetail?.current_team?.id;
    console.log(curr_team, "current team")

    const navigate = useNavigate();
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        //Todo: Update Team APi Is missing
        console.log("Profile Data", data)


        if (image) {
            try {
                await UploadFileToS3(image, constant.userDirName).then((document) => {
                    data.profile_picture = document.url
                })
            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        const response = await UpdateTeamById(curr_team, data);
        console.log(response, "team profile")
        success(response.data.message)
    }

    const handleChange = (e) => {
        let img = e.target.files[0]
        setPreview(URL.createObjectURL(img));
        setImage(img)
    }



    useEffect(() => {

        reset({
            name: profileDetail?.current_team?.name,
            phone: parseInt(profileDetail?.current_team?.phone)
        });

        setPreview(profileDetail?.current_team?.profile_picture)
    }, [curr_team])

    return (
        <>
            <ToastContainer />
            <div className='team-profile-form p-4'>
                <h6 className="border-bottom pb-3 mb-4">Manage Team</h6>
                <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="img-upload">
                        <div className="overlay"></div>
                        <img src={currTeamData?.profile_picture ? currTeamData?.profile_picture : preview} onError={(e) => e.target.src = Assets.GeneralPlaceholderImage} />
                        <input
                            name="image"
                            id="img-upload"
                            type="file"
                            className=""
                            onChange={(e) => handleChange(e)}
                        />
                        <CameraIcon />
                    </label>

                    <InputGroup className='mt-3'>
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            {...register("name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.name && <small className='text-red'>{errors.name.message}</small>}

                    <InputGroup className='mt-3'>
                        <Form.Control
                            onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                            name="phone"
                            type="number"
                            placeholder="Add Contact Number"
                            {...register("phone",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PHONE_MIN,
                                        message: VALIDATIONS_TEXT.PHONE_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PHONE_MAX,
                                        message: VALIDATIONS_TEXT.PHONE_MAX
                                    },
                                    pattern: {
                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                    {/* <InputGroup className='mt-3'>
                        <Form.Control
                            onClick={() => setShowLocationPickerModal(true)}
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                            name="location"
                            type="text"
                            placeholder="Your Location"
                            {...register("location",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.LOCATION_MAX,
                                        message: VALIDATIONS_TEXT.LOCATION_MAX
                                    },
                                })
                            }
                        />
                    </InputGroup>
                    {errors.location && <small className='text-red'>{errors.location.message}</small>}

                    <InputGroup className='mt-3'>
                        <Form.Control
                            name="email"
                            type="email"
                            placeholder="Add Email Address"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                    </InputGroup>
                    {errors.email && <small className='text-red'>{errors.email.message}</small>} */}

                    <div className='text-center'>
                        <Button type="submit" className="w-100 mt-5 btn-change-password">UPDATE CHANGES</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
})(TeamProfileForm);
