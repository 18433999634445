import React, { useContext } from 'react'
import { Col, Button, InputGroup, Form } from "react-bootstrap"
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CurrentTeamContext, ProfileContext } from '../../App';
import { InviteCollaborator } from '../../services/teamsUser';
import BlurLoader from '../blurLoader/BlurLoader';
import { useState } from 'react';
import {UpdateUserOnLocalStorage} from "../../services/auth";

const InviteCollaboratorCard = ({ heading, buttonText, content }) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    var currTeam = profileDetail?.current_team?.id;

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const { email } = data
        try {
            const response = await InviteCollaborator(email, currTeam);
            await UpdateUserOnLocalStorage()
            setLoading(true)
            console.log(response)
            success(response.data.message);
            reset()
            setTimeout(() => {
                setLoading(false)
            }, 2000);

        } catch (e) {
            console.log(e.response.data.message)
        }
        console.log("Collaborator Invite Email", data)
    }

    return (
        <div className="invite-team-card p-4 position-relative">
            {loading == true ? <BlurLoader /> : " "}
            <h6 className='border-bottom pb-4 mb-3'>{heading}</h6>
            <p>{content}</p>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-4'>
                    <Form.Control
                        name="email"
                        type="email"
                        placeholder="Email *"
                        {...register("email",
                            {
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            })
                        }
                    />
                </InputGroup>


                {errors.phone && <small className='text-red'>{errors.phone.message}</small>}
                <Button type='submit' className="w-100 mt-3 btn-invite">{buttonText}</Button>
            </Form>
        </div>
    )
}

export default InviteCollaboratorCard
