import React from 'react';
import { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTimes } from "react-icons/fa";
import { DeleteSchedulePostPopup } from '../../App';
import { NavLink, useNavigate } from 'react-router-dom';
import {DeleteTemplateDays} from "../../services/templates";
import {error, success} from "../../constants/msg";


function DeleteSchedulePopup({getallTemplatesById, schedulePostCurrentId}) {
    const [delSchedulePostPopup, setDelSchedulePostPopup] = useContext(DeleteSchedulePostPopup);
    const navigate = useNavigate();

    const deleteSchedulePost = async () => {
        try {
            const response = await DeleteTemplateDays(schedulePostCurrentId);
            success(response.data.message)
            setDelSchedulePostPopup(false);
            getallTemplatesById(3)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    return (
        <Modal
            style={{zIndex:'1060'}}
            className='confirmation-modal'
            show={true}
            size="xs"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content position-relative border-0 d-flex justify-content-center align-items-center p-4">
                    <div onClick={() => { setDelSchedulePostPopup(false) }} className="cross-icon d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    <div className="confirmation-box d-flex justify-content-center align-items-center flex-flow-column w-80">
                        <h4 className={"text-center"}>Do you want to delete the schedule post?</h4>
                        <div className="confirmation-button d-flex align-items-center justify-content-center w-100">
                            <Button type="submit" className="w-100 mt-2 confirmation-btn" onClick={() => { deleteSchedulePost() }}>Yes</Button>
                            <Button type="submit" className="w-100 mt-2 ms-2 reject-btn" onClick={() => { setDelSchedulePostPopup(false) }}>No</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteSchedulePopup