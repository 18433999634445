import React, { useState, useContext, useEffect } from 'react'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import { InfoIcon } from '../constants/svgs'
import { useForm } from "react-hook-form";
import { AutoPost } from '../services/teams';
import { error, success } from '../constants/msg';
import { ProfileContext } from '../App';
import { ToastContainer } from "react-toastify";
import { GetMyProfile } from '../services/profile';
import { UpdateUserOnLocalStorage } from "../services/auth";

const AutoPostPage = (props) => {


    //------------------------------ Fetch the profile me API Starts ------------------//
    const [profileDetail, setProfileDetail] = useState([]);
    const [user_ID, setUser_ID] = useState('');
    const [currTeamId, setCurrTeamId] = useState('')
    const [switchState, setSwitchState] = useState();


    // console.log(switchState, "switch state")


    const myProfile = async () => {
        try {
            const response = await GetMyProfile();
            let profileResp = response?.data?.data
            setProfileDetail(profileResp)
            setUser_ID(profileResp?.id)
            setCurrTeamId(profileResp?.current_team?.id)
            setSwitchState(profileResp?.current_team_autopost)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    useEffect(() => {
        myProfile();
    }, [])
    //------------------------------ Fetch the profile me API End ------------------//


    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
    });





    const handleChange = async (e) => {

        var autopost_status = switchState == true ? 1 : 0;


        console.log(autopost_status, " autopost_status")



        try {
            const response = await AutoPost(currTeamId, autopost_status);
            console.log("++__++")
            UpdateUserOnLocalStorage().then(r => console.log(r))
            console.log("__++__")
            success("Auto post set");
            console.log(response, response.data.data.message)
        }
        catch (e) {
            console.log(e.response.data.data.message);
            error("auto post error")
        }

        console.log(e.target.checked, "check value")
        setSwitchState(!switchState)
    }

    const onSubmit = (data) => {
        console.log(data, "autopost")
    }


    return (
        <div className={`auto-post-page ${props.padding && 'pb-3'}`}>
            {/*<ToastContainer />*/}
            {/*<Row>*/}
            {/*    <Col xs={12} className="d-block d-md-none mt-4">*/}
            {/*        <h5 className='text-yellow'>SETTINGS</h5>*/}
            {/*    </Col>*/}

            {/*    <SettingNavigation />*/}

            {/*    <Col xs={12} lg={6} xl={6} className="mt-4">*/}
            {/*        <div>*/}
            <Form onSubmit={handleSubmit(onSubmit)}>
                {/*<div className='switch-container d-flex align-items-center justify-content-between p-4'>*/}
                {/*    <div className='d-flex align-items-center'>*/}
                {/*        <h6 className='m-0 p-0 me-4'>Auto Post</h6>*/}
                {/*        <OverlayTrigger*/}
                {/*            key="right"*/}
                {/*            placement="right"*/}
                {/*            overlay={*/}
                {/*                <Tooltip style={{ marginTop: "5rem" }} className='text-start'>*/}
                {/*                    <span style={{ fontSize: "14px" }} className='text-dark'>*/}
                {/*                        Creating a Post Calendar is critical to maintaining*/}
                {/*                        a uniform strategy, and it keeps you consistent.*/}
                {/*                        Every time you create or submit a post, you have the*/}
                {/*                        option to assign it to a future Post slot.  So, design*/}
                {/*                        your ideal Post Calendar and make sure your slots are*/}
                {/*                        filled ahead of time! We suggest starting light, and*/}
                {/*                        adding slots as desired - that way you're not overwhelmed*/}
                {/*                        from the start.*/}
                {/*                    </span>*/}
                {/*                </Tooltip>*/}
                {/*            }*/}
                {/*        >*/}
                {/*            <span className="d-none d-lg-inline" type="button">{<InfoIcon />}</span>*/}
                {/*        </OverlayTrigger>*/}
                {/*    </div>*/}

                <div className="switch_box box_1">
                    <Form.Check
                        type="switch"
                        className="switch_1"
                        defaultChecked={switchState}
                        onChange={(e) => { handleChange(e) }}
                    />
                    {/*<input type="checkbox" {...register("autoPost")} className="switch_1" />*/}
                </div>
                {/*</div>*/}
            </Form>
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    )
}

export default AutoPostPage
