import React, { useContext, useEffect, useState } from 'react'
import { Modal, FormCheck, Form, Button, InputGroup } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";
import { AddTeamContext, SwitchTeamContext } from "../../App";
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { ProfilePicture } from "../../constants/svgs";
import Assets from "../../constants/images";
import { constant } from '../../utlis/constants';
import { UploadFileToS3 } from "../../utlis/helper"
import { UserContext } from '../../App';
import { GetMyProfile } from '../../services/profile';
import { CreateTeam } from '../../services/teamsUser';
import { SetAuthUserLocalStorage } from '../../services/auth';

const AddTeamPopup = () => {
    //Add teams Use Context
    const [addTeamPopup, setAddTeamPopup] = useContext(AddTeamContext);
    const [switchUserPopup, setSwitchUserPopup] = useContext(SwitchTeamContext);
    const [userProfile, setUserProfile] = useState({
        file: null,
        file_url: null,
        aws_url: null
    })

    const [teamProfile, setTeamProfile] = useState({
        file: null,
        file_url: null,
        aws_url: null
    })

    const [userData, setUserData] = useContext(UserContext);

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        try {
            await UploadFileToS3(teamProfile.file, constant.userDirName).then(async (document) => {
                try {
                    data.user_image_url = userData.details.image_url
                    data.profile_picture = document.url
                    data.owner_id = userData.id;
                    await CreateTeam(data).then(async () => {
                        const resp = await GetMyProfile();
                        console.log(resp, "my profile popup response")
                        setUserData(resp.data.data)
                        SetAuthUserLocalStorage(resp.data.data)
                        success("Profile created successfully")
                        setAddTeamPopup(false);
                        setSwitchUserPopup(true)

                        setTimeout(() => {
                            setTeamProfile({
                                file: null,
                                file_url: null,
                                aws_url: null
                            })
                        }, 1500)
                    })
                } catch (error) {

                }
            })
        }
        catch (e) {
            error("error")
        }
    }

    return (
        <>
            <Modal
                className='add-team-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                right
            >
                <Modal.Body>
                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                        <div className="modal-header w-100">
                            <h6 className='p-0 m-0'>Add New Team</h6>
                            <div onClick={() => (setAddTeamPopup(false), setSwitchUserPopup(true))} className="cross-icon d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='setup-profile-form p-4 mt-5'>
                                    <div className='text-center'>
                                        <label htmlFor="team-profile">
                                            <input
                                                accept=".png, jpe, jpg, jfif" className='d-none' type="file" name="team-profile" id="team-profile"
                                                onChange={(e) => {
                                                    setTeamProfile({
                                                        file: e.target.files[0],
                                                        file_url: URL.createObjectURL(e.target.files[0])
                                                    })
                                                }}
                                            />
                                            {
                                                teamProfile.file_url ?
                                                    <div className="profile-img">
                                                        <img src={teamProfile.file_url} alt="" />
                                                    </div>
                                                    :
                                                    <ProfilePicture />
                                            }
                                        </label>
                                    </div>

                                    <InputGroup className='mt-3'>
                                        <Form.Control
                                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                            name="teamTitle"
                                            type="text"
                                            placeholder="Add Team Title"
                                            {...register("teamTitle",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.TEAM_TITLE_MAX,
                                                        message: VALIDATIONS_TEXT.TEAM_TITLE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.TEAM_TITLE_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.teamTitle && <small className='text-red'>{errors.teamTitle.message}</small>}


                                    <InputGroup className='mt-3'>
                                        <Form.Control
                                            onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                            name="teamphone"
                                            type="number"
                                            placeholder="Add Team Contact Number"
                                            {...register("teamphone",
                                                {
                                                    minLength: {
                                                        value: VALIDATIONS.PHONE_MIN,
                                                        message: VALIDATIONS_TEXT.PHONE_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.PHONE_MAX,
                                                        message: VALIDATIONS_TEXT.PHONE_MAX
                                                    },
                                                    pattern: {
                                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.teamphone && <small className='text-red'>{errors.teamphone.message}</small>}

                                    <Button type="submit" className='mt-4 w-100 btn-continue' >SAVE AND CONTINUE</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddTeamPopup
