import React, { useContext, useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import EditPostPopup from '../components/dashboard/EditPostPopup';
import SinglePostManagementCard from '../components/dashboard/SinglePostManagementCard';
import { success, error } from "../constants/msg";
import { GetSinglePost } from "../services/dashboard"

const SinglePostManagementPage = () => {
    const { id } = useParams();
    const [counter, setCounter] = useState(0)
    const [handleEditPostPopup, setHandleEditPostPopup] = useState(false);
    const [editPostPopUp, setEditPostPopUp] = useState();
    const handleClickEditPostPopup = () => {
        setHandleEditPostPopup(!handleEditPostPopup)
    }

    useEffect(() => {
        const getSinglePostById = async () => {
            try {
                const response = await GetSinglePost(id);
                setEditPostPopUp(response.data.data);

            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        getSinglePostById();
    }, [counter]);


    return (
        <>
            {/* This is the edit popup which will popup on dot click */}

            {handleEditPostPopup === true && <EditPostPopup handleClickEditPostPopup={handleClickEditPostPopup} postId={id} editPostPopUp={editPostPopUp} counter={counter} setCounter={setCounter} />}
            <div className="post-management-page">
                <Row className="mt-4">
                    <SinglePostManagementCard handleClickEditPostPopup={handleClickEditPostPopup} counter={counter} setCounter={setCounter} />
                </Row>
            </div>
        </>
    )
}

export default SinglePostManagementPage
