import React, { useEffect, useState } from 'react'
import { StarIcon } from '../../constants/svgs'
import Assets from "../../constants/images";

const LeaderboardCard = ({ cardData, index }) => {
    const [image, setImage] = useState('')

    console.log(cardData)

    return (
        <div className='leaderboard-card'>
            <div className='px-3'>
                <div className='d-flex justify-content-between align-items-center border-bottom py-4 '>
                    <h6 className='p-0 m-0'>{index + 1}</h6>
                    <div className='d-flex align-items-center'>
                        <div className='img-wrapper'>
                            <img onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} src={cardData?.user_image_url || Assets.ProfilePlaceholderImage} />
                        </div>
                        <h6 className='p-0 m-0 ms-2 name'>{cardData.name}</h6>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='star-icon-container'>
                            <StarIcon />
                        </div>
                        <h6 className='p-0 m-0 ms-2'>{cardData?.points} Points</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderboardCard
