import axios from 'axios';
import {constant, apiEndPoints} from '../utlis/constants';
import {GetMyProfile} from "./profile";

export const SetTokenLocalStorage = (token) => {
    localStorage.setItem(constant.LOCAL_STORAGE_TOKEN, token)
}

export const SetAuthUserLocalStorage = (user) => {
    localStorage.setItem(constant.LOCAL_STORAGE_USER, JSON.stringify(user))
}

export const GetTokenLocalStorage = () => {
    if (localStorage.getItem(constant.LOCAL_STORAGE_TOKEN)) {
        return localStorage.getItem(constant.LOCAL_STORAGE_TOKEN)
    } else {
        return null;
    }
}

export const UpdateUserOnLocalStorage = async () => {
    let resp = await GetMyProfile()
    SetAuthUserLocalStorage(resp.data.data)
}

export const GetAuthUserLocalStorage = () => {
    if (localStorage.getItem(constant.LOCAL_STORAGE_USER)) {
        return JSON.parse(localStorage.getItem(constant.LOCAL_STORAGE_USER))
    } else {
        return null;
    }
}

export const EmptyLocalStorage = () => {
    localStorage.removeItem(constant.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(constant.LOCAL_STORAGE_USER);
}

export const Login = async (email, password, device_type, device_token) => {
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.login, {email, password, device_type, device_token})
    return resp;
}

export const SocialLogin = async (username, email, image, platform, client_id, token, device_type, device_token) => {
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.social_login, {
        username,
        email,
        image,
        platform,
        client_id,
        token,
        device_type,
        device_token
    })
    return resp;
}

export const Logout = async () => {
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.logout, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp
}

export const ForgotPassword = async (email) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.forgot_password}?email=${email}`);
    return resp
}

export const VerficationCode = async (otp_code) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.verify_reset_code}?verification_code=${otp_code}`);
    return resp;
}

export const ResetPassword = async (email, otp, password, newPassword) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.reset_password}?email=${email}&verification_code=${otp}&password=${password}&password_confirmation=${newPassword}`);
    return resp
}

export const UpdatePassword = async (currentPassword, newPassword, confirmPassword) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.change_password}?current_password=${currentPassword}&password=${newPassword}&password_confirmation=${confirmPassword}`, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp
}

export const Signup = async (email, password) => {
    const name = "dummy"
    const password_confirmation = password
    const device_type = constant.DEVICE_TYPE
    const device_token = constant.DEVICE_TOKEN
    const role_id = constant.owner_role_id

    const resp = await axios.post(constant.BASE_URL + apiEndPoints.register, {
        name,
        email,
        password,
        password_confirmation,
        role_id,
        device_type,
        device_token
    })
    return resp;
}
