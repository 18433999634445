import React, { useEffect, useState, useContext } from 'react'
import { useForm } from "react-hook-form";
import { Row, Col, Form, Button, Table, Modal } from "react-bootstrap"
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { SlotClock } from '../../constants/svgs';
import { FaTimes } from "react-icons/fa"
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GetAllTemplates, GetAllTemplatesById, TeamDaysSlots, UpdateTeamTemplate } from "../../services/templates";
import { DAYS, DAYSKEY } from "../../utlis/constants";
import { StoreTemplateDays, UpdateTemplateDaySlots } from '../../services/templates';
import { DeleteSchedulePostPopup, ProfileContext } from '../../App';
import DeleteSchedulePopup from '../popups/DeleteSchedulePopup';
import { GetAuthUserLocalStorage } from "../../services/auth";
import { GetMyProfile } from "../../services/profile";


const CreateTeamPostCalendarForm = ({ subHeading, link }) => {
    const locationParams = useLocation()
    console.log(locationParams, "locationParams")
    const navigate = useNavigate();
    const [time, setTime] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [meridiem, setMeridiem] = useState('');
    const [calendarTemplate, setCalendarTemplate] = useState("0");
    const [showSelectedSlot, setShowSelectedSlot] = useState(false)
    const [handleSchedulePostPopup, setHandleSchedulePostPopup] = useState(false);
    const [Disable, setDisabled] = useState(false);
    const [delSchedulePostPopup, setDelSchedulePostPopup] = useContext(DeleteSchedulePostPopup);
    //const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    //const getProfile = GetAuthUserLocalStorage()
    //var currTeam = getProfile?.current_team?.id;

    /*const [packages, setPackages] = useState([
        "Light - 3 Posts / week - M, W, F @ 12PM",
        "Custom - Manage days and time by your choice",
        "Medium - 7 Posts / week - M, T, W, Th, F, Sat, Sun @ 12PM",
        "Heavy - 14 Posts / week - M, T, W, Th, F, Sat, Sun @ 9AM & 12PM",
        "Crazy - 5 Posts / week - M, W, F @ 9AM",
    ])*/

    const [packages, setPackages] = useState([])
    const [selectedPackage, setSelectedPackage] = useState([])

    const [schedulePostCurrentId, setSchedulePostCurrentId] = useState('');

    const [days, setDays] = useState([
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ]);

    const [timeSlot, setTimeSlots] = useState([
        '09 AM', '10 AM', '11 AM', '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM',
        '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM', '12 AM',
        '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM'
    ]);

    const [editSlots, showEditSlots] = useState(false);
    const [defaultDay, setDefaultDay] = useState('');
    //const selectedTemplate = getProfile?.current_team?.template?.id || '';

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [currTeam, setCurrTeam] = useState("")
    const [profile, setProfile] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState("")

    useEffect(() => {
        getallTemplates()
        //myProfile()
    }, [])

    useEffect(() => {
        //getallTemplates()
        myProfile()
    }, [packages])

    useEffect(() => {
        getallTemplatesById(selectedTemplate)
        /*if (packages && packages.length > 0) {
            getallTemplatesById(packages[0].id)
        }*/
    }, [selectedTemplate])

    const myProfile = async () => {
        try {
            const response = await GetMyProfile();
            let resp = response.data.data
            setProfile(resp);
            setSelectedTemplate(resp?.current_team?.template?.id || packages[0].id)
            setCurrTeam(resp?.current_team?.id);
            /*if(resp?.current_team?.template?.id){
                getallTemplatesById(resp?.current_team?.template?.id)
            }*/
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    //Get Templates
    const getallTemplates = async () => {
        try {
            let response = await GetAllTemplates();
            setPackages(response?.data?.data);
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const getallTemplatesById = async (id) => {
        try {
            let team_id = id == 3 && currTeam ? currTeam : ''
            let response = await GetAllTemplatesById(id, team_id);
            setSelectedPackage(response?.data?.data);
            id == 3 ? showEditSlots(true) : showEditSlots(false);
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }


    var inviteTeamPage = locationParams?.state?.myState
    console.log("inviteTeamPage", inviteTeamPage)

    const onSubmit = async (data) => {
        if (inviteTeamPage === "inviteTeam") {
            console.log("From Invite Page", inviteTeamPage)
            let response = await UpdateTeamTemplate(currTeam, parseInt(calendarTemplate))
            if(response){
                navigate("/social-accounts")
            }
        }
        else if (calendarTemplate == 0) {
            navigate("/settings/rewards-management")
        } else {
            //Todo: confirmation changes
            let response = await UpdateTeamTemplate(currTeam, parseInt(calendarTemplate))
            if (response) {
                navigate("/settings/rewards-management")
            }
        }
    }

    const handleTime = async () => {
        setDisabled(true)
        let time = hours + ":" + minutes + " " + meridiem;
        //todo: template-days api
        try {
            const resp = await StoreTemplateDays(defaultDay, selectedPackage.id, DAYS[defaultDay], currTeam)
            console.log(resp.data.data.id)
            let day_id = resp.data.data.id
            const response = await UpdateTemplateDaySlots(time, day_id);
            setHandleSchedulePostPopup(false)
            setDisabled(false)
            getallTemplatesById(selectedPackage.id)
        } catch (error) {
            setDisabled(false)
        }

        //todo: template-day-slots run this on first api response with day_id

        // getallTemplatesById(selectedPackage.id)
        // setHandleSchedulePostPopup(false)
        /*let t = hours + ":" + minutes + ":" + meridiem;
        setTime(t)
        setValue("scheduleTime", time)
        setShowSelectedSlot(true)
        setHandleSchedulePostPopup(false)*/
    }

    return (
        <>
            {delSchedulePostPopup == true && <DeleteSchedulePopup getallTemplatesById={getallTemplatesById}
                schedulePostCurrentId={schedulePostCurrentId} />}
            <Form onSubmit={handleSubmit(onSubmit)}>
                {
                    handleSchedulePostPopup &&
                    <Modal
                        className='schedule-post-modal'
                        show={true}
                        size="xs"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <div
                                className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                                <h6 className='p-0 m-0'>Schedule Post Time </h6>
                                <div onClick={() => setHandleSchedulePostPopup(false)}
                                    className="d-flex align-items-center justify-content-center text-center fa-times">
                                    <FaTimes /></div>
                            </div>

                            {/* <TimePicker
                                inputReadOnly={true}
                                open={true}
                                // placeholder="Scheduled Post Time"
                                use12Hours
                                showSecond={false}
                                focusOnOpen={true}
                                format="hh:mm A"
                                onChange={e => setTime(e.format('LT'))}
                            /> */}

                            <div className="custom-clock">
                                <div className='clock-holder d-flex justify-content-between'>
                                    <div className="hours">
                                        <ul>
                                            <li onClick={() => setHours("12")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='12'
                                                    className='d-none'
                                                />
                                                <label htmlFor="12"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>12</label>
                                            </li>
                                            <li onClick={() => setHours("01")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='01'
                                                    className='d-none'
                                                />
                                                <label htmlFor="01"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>01</label>
                                            </li>
                                            <li onClick={() => setHours("02")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='02'
                                                    className='d-none'
                                                />
                                                <label htmlFor="02"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>02</label>
                                            </li>
                                            <li onClick={() => setHours("03")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='03'
                                                    className='d-none'
                                                />
                                                <label htmlFor="03"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>03</label>
                                            </li>
                                            <li onClick={() => setHours("04")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='04'
                                                    className='d-none'
                                                />
                                                <label htmlFor="04"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>04</label>
                                            </li>
                                            <li onClick={() => setHours("05")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='05'
                                                    className='d-none'
                                                />
                                                <label htmlFor="05"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>05</label>
                                            </li>
                                            <li onClick={() => setHours("06")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='06'
                                                    className='d-none'
                                                />
                                                <label htmlFor="06"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>06</label>
                                            </li>
                                            <li onClick={() => setHours("07")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='07'
                                                    className='d-none'
                                                />
                                                <label htmlFor="07"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>07</label>
                                            </li>
                                            <li onClick={() => setHours("08")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='08'
                                                    className='d-none'
                                                />
                                                <label htmlFor="08"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>08</label>
                                            </li>
                                            <li onClick={() => setHours("09")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='09'
                                                    className='d-none'
                                                />
                                                <label htmlFor="09"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>09</label>
                                            </li>
                                            <li onClick={() => setHours("10")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='10'
                                                    className='d-none'
                                                />
                                                <label htmlFor="10"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>10</label>
                                            </li>
                                            <li onClick={() => setHours("11")}>
                                                <input
                                                    name="hours"
                                                    type="radio"
                                                    id='11'
                                                    className='d-none'
                                                />
                                                <label htmlFor="11"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>11</label>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="minutes">
                                        <ul>
                                            {
                                                Array.from({ length: 61 }, (_, i) => {
                                                    return (
                                                        <li key={i} onClick={() => setMinutes(i < 10 ? `0${i}` : i)}>
                                                            <input
                                                                name="minutes"
                                                                type="radio"
                                                                id={i < 10 ? `m-0${i}` : `m-${i}`}
                                                                className='d-none'
                                                            />
                                                            <label htmlFor={i < 10 ? `m-0${i}` : `m-${i}`}
                                                                className='d-flex align-items-center justify-content-center text-center label-youtube'>{i < 10 ? `0${i}` : i}</label>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>

                                    <div className="am-pm">
                                        <ul>
                                            <li onClick={() => setMeridiem("AM")}>
                                                <input
                                                    name="meridiem"
                                                    type="radio"
                                                    id='AM'
                                                    className='d-none'
                                                />
                                                <label htmlFor="AM"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>AM</label>
                                            </li>
                                            <li onClick={() => setMeridiem("PM")}>
                                                <input
                                                    name="meridiem"
                                                    type="radio"
                                                    id='PM'
                                                    className='d-none'
                                                />
                                                <label htmlFor="PM"
                                                    className='d-flex align-items-center justify-content-center text-center label-youtube'>PM</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Button className="btn-done" disabled={Disable} onClick={() => {
                                handleTime();
                            }}>Done</Button>
                        </Modal.Body>
                    </Modal>
                }

                <div className='create-team-post-form my-4'>
                    <Row>
                        <Col xs={12} className="d-none custom-d-block mb-3">
                            <div className="text-end">
                                <NavLink className="text-dark me-3" to={`/${link}`}>{subHeading}</NavLink>
                            </div>
                        </Col>

                        <Col xs={12} xl={5}>
                            <div>
                                <p>
                                    Need help picking a strategy? Try one of our recommended templates.
                                    Start light, and add more as you see fit.
                                    Click drop down to learn more about optimal posting times.
                                </p>
                            </div>
                        </Col>

                        <Col xs={12} xl={{ span: 5, offset: 2 }}>
                            <div>
                                <Form.Label>Select from Calendar Templates setting</Form.Label>
                                <Form.Select className="py-2" aria-label="Default select example"
                                    onChange={(e) => {
                                        getallTemplatesById(e.target.value)
                                        setCalendarTemplate(e.target.value)
                                    }}>
                                    {
                                        packages && packages.map((dt, index) => {
                                            return <option selected={selectedTemplate == dt.id}
                                                value={dt.id}>{dt.text}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-5 mb-sm-0">
                        <Col xs={12} className="mt-2 border-top">
                            <div className='mt-3'>
                                {
                                    selectedPackage?.name &&
                                    <h4 className="text-capitalize">{selectedPackage?.name}
                                        <small> ({`${selectedPackage?.per_week_post} Posts`})</small></h4>
                                }
                            </div>
                        </Col>

                        <Col xs={12} className="mt-2">
                            <div className='table-0'>
                                <Table responsive="xl" size={"sm"}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {
                                                days && days.map((day) => {
                                                    return <th>{day}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            timeSlot && timeSlot.map((time, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='text-grey'>{time}</td>
                                                        {
                                                            days && days.map((day) => {
                                                                return <td>
                                                                    {
                                                                        selectedPackage.templatedays && selectedPackage.templatedays.length > 0 ?
                                                                            selectedPackage.templatedays.map((dt) => {
                                                                                if (day == dt.name) {
                                                                                    if (dt.templatedaysslots && dt.templatedaysslots.length > 0) {
                                                                                        let data = dt.templatedaysslots.filter((tds) => {
                                                                                            return tds.hasOwnProperty('time_slot') && time == tds?.time_slot
                                                                                        })

                                                                                        return data && data.length > 0 ?
                                                                                            <h6 className='d-flex justify-content-center align-items-center'>Scheduled
                                                                                                Post
                                                                                                {/* <FaRegEdit className='me-2 icon' /> */}
                                                                                                {
                                                                                                    editSlots &&
                                                                                                    <RiDeleteBin6Line
                                                                                                        className='icon ms-1 cursor-pointer'
                                                                                                        onClick={() => {
                                                                                                            setSchedulePostCurrentId(dt.id)
                                                                                                            setDelSchedulePostPopup(true)
                                                                                                        }} />
                                                                                                }
                                                                                            </h6> : ''
                                                                                    } else {
                                                                                        return ''
                                                                                    }
                                                                                }
                                                                            }) : ''
                                                                    }
                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            editSlots &&

                                            <tr>
                                                <td className='text-grey'></td>
                                                {
                                                    days && days.map((day, index) => {
                                                        return (
                                                            <td>
                                                                <Button className="btn-add-slot"
                                                                    onClick={() => {
                                                                        setDefaultDay(DAYSKEY[index]);
                                                                        setHandleSchedulePostPopup(true);
                                                                        setDisabled(false)
                                                                    }}>
                                                                    <SlotClock />
                                                                    <small>Add Post Slot</small>
                                                                </Button>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <Button type="submit" className="mt-4 btn-continue">CONTINUE</Button>
                            {/*<NavLink to="/settings/rewards-management">
                            </NavLink>*/}
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default CreateTeamPostCalendarForm
