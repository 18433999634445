import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import Assets from "../../constants/images";
import { ArrowRight, DashboardIcon, LeaderboardIcon, LogoutIcon, PostManagementIcon, ReportIcon, SettingIcon, UserManagementIcon } from '../../constants/svgs';
import { BsPlus, BsThreeDotsVertical, BsFillPlusCircleFill, BsArrow90DegRight } from "react-icons/bs"
import { MdOutlineLibraryBooks } from "react-icons/md"
import { FaBars, FaTimes } from "react-icons/fa"
import { IoMdNotificationsOutline } from "react-icons/io"
import { BiCalendar } from "react-icons/bi"
import { getAllNotification } from '../../services/notification';
// -------------- Imports Of Popups Starts --------- //
import CreatePostPopup from "../../components/dashboard/CreatePostPopup"
import NotificationPopup from '../../components/dashboard/NotificationPopup';
import ProfilePopup from '../../components/dashboard/ProfilePopup';
import SwitchTeamPopup from '../../components/dashboard/SwitchTeamPopup';
import AddTeamPopup from '../../components/dashboard/AddTeamPopup';
import ConfirmationPopup from '../../components/dashboard/ConfirmationPopup';
import VerificationPopup from '../../components/popups/VerificationPopup';
// -------------- Imports Of Popups Ends --------- //
import { FaAngleLeft } from "react-icons/fa";
import { TutorialContext, VerificationPopupContext, ProfileContext, NotificationLenghtContext, ConfirmationPopupContext, CreatePostPopupContext, NotificationPopupContext, ProfilePopupContext, SwitchTeamContext, AddTeamContext } from "../../App";
import { Logout, EmptyLocalStorage, GetAuthUserLocalStorage } from '../../services/auth';
import { GetMyProfile } from '../../services/profile';
import { constant } from '../../utlis/constants';
import { success, error } from '../../constants/msg';
import TutorialSteps from '../../components/TutorialSteps/TutorialSteps';
import {TutorialViewed} from "../../services/tutorialViewed";

//Notification API


const DashboardLayout = ({ Icon, children, heading, secondHeading, subHeading }) => {
    const routeParams = useLocation();
    const [userData, setUserData] = useState(GetAuthUserLocalStorage());
    const [handleCreatePostPopup, setHandleCreatePostPopup] = useContext(CreatePostPopupContext);
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [handleProfilePopup, setHandleProfilePopup] = useContext(ProfilePopupContext);
    const [addTeamPopup, setAddTeamPopup] = useContext(AddTeamContext);
    const [switchUserPopup, setSwitchUserPopup] = useContext(SwitchTeamContext);
    const [confirmationPopup, setConfirmationPopup] = useContext(ConfirmationPopupContext);
    const [notificationLength, setNotificationLength] = useContext(NotificationLenghtContext);
    const [verificationPopup, setVerificationPopup] = useContext(VerificationPopupContext);
    //Profile Detail
    const [profileDetail, setProfileDetail] = useContext(ProfileContext)
    const [handleSidebar, setHandleSidebar] = useState(true);
    const [handleFullSidebar, setHandleFullSidebar] = useState(false);
    const navigate = useNavigate();

    //Tutorial Context
    const [tutorialScreen, setTutorialScreen] = useContext(TutorialContext);

    useEffect(() => {
        if (handleFullSidebar === true) {
            document.body.classList.add("body-overflow-hidden")
            document.body.classList.remove("body-overflow-hidden-remove")
        }
        else {
            document.body.classList.remove("body-overflow-hidden")
            document.body.classList.add("body-overflow-hidden-remove")
        }

        const myProfile = async () => {
            try {
                const response = await GetMyProfile();
                setProfileDetail(response?.data?.data)
            } catch (e) {
                console.log(e, 'On Logout')
                console.log(e.response.data.message)
            }
        }
        const getNotifications = async () => {
            try {
                let response = await getAllNotification();
                setNotificationLength(response.data.data.length)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        myProfile();
        getNotifications();

    }, [handleFullSidebar]);

    const logout = async () => {
        try {
            const response = await Logout();
            EmptyLocalStorage();
            setVerificationPopup(false)
            navigate("/")

        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        const handleResize = () => {

            if (viewportWidth < 768) {
                setTutorialScreen(0);
                console.log("true")
            } else {
                setTutorialScreen(1);
                console.log("false")
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeTutorial = async () =>{
        try{
            const tutorial_viewed = await TutorialViewed()
        } catch(e){
            error(e)
            console.log(e)
        }
    }

    return (
        <>
            {handleCreatePostPopup === true && <CreatePostPopup />}
            {handleNotificationPopup === true && <NotificationPopup />}
            {handleProfilePopup === true && <ProfilePopup />}
            {switchUserPopup === true && <SwitchTeamPopup />}
            {addTeamPopup === true && <AddTeamPopup />}
            {confirmationPopup === true && <ConfirmationPopup />}
            {verificationPopup === true && <VerificationPopup verificationText={"Are you sure you want to logout?"} handleVerificationFunction={logout} />}

            <section className={`dashboard-layout ${handleSidebar === false && 'remove-active-sidebar'} ${tutorialScreen === 0 && "tutorialScreen"}`}>
                <div className='bg-background p-4 d-flex'>
                    <div className={`left  ${handleFullSidebar === true && 'activeSidebar'}`}>
                        <div className='d-none d-sm-flex navigation-controller' onClick={() => setHandleSidebar(!handleSidebar)}>
                            <FaAngleLeft className='icon' />
                        </div>

                        <Container fluid>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-sm-none d-block text-end mt-2' onClick={() => setHandleFullSidebar(false)}>
                                        <FaTimes className="text-white" />
                                    </div>

                                    <div className='py-sm-5 py-4 text-center'>
                                        <NavLink to={"/dashboard"}>
                                            <img src={Assets.Logo} alt="Logo" />
                                        </NavLink>

                                    </div>
                                </Col>

                                <Col className="col-no-padding" xs={12}>
                                    <div className='overflow-controller'>
                                        <small className='px-4 d-block mb-2'>Main Menu</small>
                                        <ul>
                                            <li className="position-relative">
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active position-relative" : "px-4 d-flex align-items-center position-relative"} to="/dashboard">
                                                    <DashboardIcon />
                                                    <span className="ms-3">Dashboard</span>

                                                </NavLink>
                                                {tutorialScreen === 0 && <TutorialSteps
                                                    stepNum={"01"}
                                                    stepName={"step1"}
                                                    placement={"right"}
                                                    icon={<DashboardIcon />}
                                                    heading={"Welcome to your Rodeo Dashboard!"}
                                                    para={"As you (and your team) submit content to your queue, you'll be able to review and approve incoming submissions, manage posts and your team calendar, invite team members, and much more.Let's take a look at a few key items..."}
                                                />}
                                            </li>

                                            <small className='px-4 d-block mt-xxl-5 mt-xl-2 mt-lg-5 mt-md-2 mt-5 mb-2'>Workspace</small>
                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/post-management">
                                                    <PostManagementIcon />
                                                    <span className="ms-3">Post Management</span>
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/leaderboard">
                                                    <LeaderboardIcon />
                                                    <span className="ms-3">Leaderboard</span>
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active" : "px-4 d-flex align-items-center"} to="/user-management">
                                                    <UserManagementIcon />
                                                    <span className="ms-3">Users</span>
                                                </NavLink>
                                            </li>

                                            <li className='position-relative'>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active position-relative" : "px-4 d-flex align-items-center position-relative"} to="/report">
                                                    <ReportIcon />
                                                    <span className="ms-3">Report</span>

                                                </NavLink>
                                                {tutorialScreen === 0 && <TutorialSteps
                                                    stepNum={"05"}
                                                    stepName={"step5"}
                                                    placement={"right"}
                                                    icon={<ReportIcon />}
                                                    heading={"Reporting"}
                                                    para={"You can find Reporting, and more features and settings in the web dashboard. Now it's time to get started! Invite team members, and start submitting content to your queue via the Rodeo Social app!"}
                                                />}
                                            </li>

                                            <small className='px-4 d-block mt-xxl-5 mt-xl-2 mt-lg-5 mt-md-2 mb-2'>General</small>
                                            <li className='position-relative'>
                                                <NavLink onClick={() => setHandleFullSidebar(false)} className={({ isActive }) => isActive ? "px-4 d-flex align-items-center active position-relative" : "px-4 d-flex align-items-center position-relative"} to="/settings/rewards-management">
                                                    <SettingIcon />
                                                    <span className="ms-3">Settings</span>

                                                </NavLink>
                                                {tutorialScreen === 0 && <TutorialSteps
                                                    stepNum={"04"}
                                                    stepName={"step4"}
                                                    placement={"right"}
                                                    icon={<SettingIcon />}
                                                    heading={"AutoPost Setting"}
                                                    para={"This lets Rodeo know whether submissions should automatically be approved and go out for publishing once submitted. Keep in mind, this means that mobile users will have to complete all post parameters before submitting a post, which slows them down! We recommend leaving this setting OFF, so that you (and your team) can quickly capture content from the mobile app, and submissions can appear in the Pending Posts queue for quick review and approval later. Speed is the name of the game!"}
                                                />}
                                            </li>

                                            <li>
                                                <div onClick={() => { setVerificationPopup(true) }} className="nav-link px-4 d-flex align-items-center">
                                                    <LogoutIcon />
                                                    <span className="ms-3" > Log Out</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        {/* onClick={() => logout()} */}
                    </div>


                    <div className='right pb-4'>
                        <div className='ps-md-4'>
                            <Container fluid>
                                <Row className='justify-content-center'>
                                    <Col xs={12}>
                                        <div className='header d-flex justify-content-between align-items-center p-3'>
                                            <div className='icon-container d-flex d-sm-none' onClick={() => setHandleFullSidebar(!handleFullSidebar)}>
                                                <FaBars />
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                {/* Selected Team Goes Here */}
                                                <h5 className='m-0 p-0 me-3 d-none d-md-block'>{heading}<em>{routeParams.pathname == "/dashboard" ? profileDetail?.current_team?.name : " "}</em></h5>
                                                <OverlayTrigger
                                                    key="right"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip className='text-start information'>
                                                            <span style={{ fontSize: "14px" }} className='text-dark'>
                                                                Creating a Post Calendar is critical to maintaining
                                                                a uniform strategy, and it keeps you consistent.
                                                                Every time you create or submit a post, you have the
                                                                option to assign it to a future Post slot.  So, design
                                                                your ideal Post Calendar and make sure your slots are
                                                                filled ahead of time! We suggest starting light, and
                                                                adding slots as desired - that way you're not overwhelmed
                                                                from the start.
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-none d-lg-inline" type="button">{<Icon />}</span>
                                                </OverlayTrigger>
                                            </div>

                                            <div className='d-flex'>
                                                <div className='d-flex d-lg-none me-2 icon-container' onClick={() => setHandleCreatePostPopup(true)}>
                                                    <BsFillPlusCircleFill className='icon-18 text-theme-yellow icon' />
                                                </div>

                                                <Button onClick={() => setHandleCreatePostPopup(true)} className="me-3 btn-create-post d-flex align-items-center d-none d-lg-flex">
                                                    <BsPlus className='icon' />
                                                    <span className='ms-2'>CREATE POST</span>
                                                </Button>

                                                <div className='me-2 icon-container  position-relative'>
                                                    <NavLink to="/post-management-calendar">
                                                        <BiCalendar className='icon-18 icon' />

                                                    </NavLink>
                                                    {tutorialScreen === 0 && <TutorialSteps
                                                        stepNum={"02"}
                                                        stepName={"step2"}
                                                        placement={"left"}
                                                        icon={<BiCalendar />}
                                                        heading={"Post Calendar"}
                                                        para={"Your Post Calendar shows you where your post slots are, which ones are empty and need posts, and which ones have posts already scheduled to them. Your goal as a team should be to submit content regularly and keep upcoming post slots filled! Don't let a post slot pass empty or you'll lose your team streak!"}
                                                    />}
                                                </div>

                                                <div className='me-2 icon-container' onClick={() => setHandleNotificationPopup(true)}>
                                                    <IoMdNotificationsOutline className='icon-18 icon' />
                                                    {notificationLength === 0 ? null : <span className='notification-icon'></span>}
                                                </div>
                                                {/*todo: need to discuss this point from dev */}
                                                <div onClick={() => setHandleProfilePopup(true)} className='me-2 avatar-container d-flex align-items-center'>
                                                    <img onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} src={profileDetail?.details?.user_image_url} className="rounded-circle" />
                                                    <div className='d-lg-flex flex-lg-column ms-2 d-none'>
                                                        <span>{profileDetail?.name}</span>
                                                        <small>{profileDetail?.current_team?.name}</small>
                                                    </div>
                                                </div>

                                                {/* <div className='icon-container no-bg d-flex d-sm-none' onClick={() => setHandleFullSidebar(!handleFullSidebar)}>
                                                    <FaBars />
                                                </div> */}


                                                {/* Change profile popup goes here */}
                                                <div className='icon-container d-none d-sm-flex dots-icon' onClick={() => setSwitchUserPopup(true)}>

                                                    <BsThreeDotsVertical />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {children}
                            </Container>
                        </div>
                    </div>
                </div>
                {/* Skip Tutorial Button */}
                {tutorialScreen === 0 &&
                    <Button onClick={async() => {
                        setTutorialScreen(false)
                        await closeTutorial()
                    }} className="skipTutorialBtn d-flex align-items-center">
                        <span className='text-uppercase'>Skip tutorial</span>
                        <ArrowRight />
                    </Button>
                }
            </section>
        </>
    )
}

export default DashboardLayout
