import React, { useContext, useEffect, useState } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { NavLink, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { DoneIcon, EditProfileIcon, FireIcon, StarIcon, Badge1, Badge2 } from '../../constants/svgs'
import { ProfilePopupContext, ProfileContext } from '../../App';
import { GetMyProfile } from "../../services/profile";
import { success, error } from "../../constants/msg";
import { getSingleTeam } from '../../services/singleTeam';
import BlurLoader from '../blurLoader/BlurLoader'

const ProfilePopup = () => {
    const [profileData, setProfileData] = useState();
    const [singleTeam, setSingleTeam] = useState();
    const [loading, setLoading] = useState(true);
    const [handleProfilePopup, setHandleProfilePopup] = useContext(ProfilePopupContext);
    const navigate = useNavigate();
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);



    var userTeamId = profileDetail?.current_team?.id;

    useEffect(() => {
        const myProfile = async () => {
            try {
                const response = await GetMyProfile();
                setProfileData(response.data.data)
                setLoading(false)
            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        const fetchSingleTeam = async () => {
            try {
                let response = await getSingleTeam(userTeamId);
                console.log(response, "fetch single team")
                setSingleTeam(response.data.data)
                setLoading(false)
            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        fetchSingleTeam();
        myProfile()
    }, [])

    return (
        <div>
            <Modal
                className='profile-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    {loading && <BlurLoader />}
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>My Profile</h6>
                        <div onClick={() => setHandleProfilePopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <div className="profile-container">
                        <div className="d-flex justify-content-end align-items-center">
                            <EditProfileIcon />
                            <NavLink className="ms-2" to="/edit-profile">Edit Profile</NavLink>
                        </div>

                        <div className='avatar-container d-flex align-items-center mb-3'>
                            {profileData?.details?.user_image_url == null ?
                                <img className='rounded-circle radius-50' src={Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} /> :
                                <img className='rounded-circle radius-50' src={profileData?.details?.user_image_url} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} />
                            }

                            <div>
                                <div className="ms-3">
                                    <h6 className='d-block p-0 m-0'>{profileData?.name}</h6>
                                    <small>
                                        {profileData?.email} <br />
                                        {profileData?.details?.phone}

                                    </small>
                                </div>
                                <div className='badge-div mt-2 ms-3'>
                                    {profileData?.team_user?.badges?.map((data, key) => (
                                        <div className="badgeImg me-2" key={key}>
                                            <img src={data?.image_url} onError={(e)=> e.target.src = "https://cdn-icons-png.flaticon.com/512/1435/1435715.png"} />
                                        </div>

                                    ))}

                                </div>
                            </div>
                        </div>

                        <div className="border-bottom pb-4">
                            <p>
                                {profileData?.details?.about}
                            </p>
                            <Button onClick={() => navigate("/view-submissions")} className="w-100 btn-submission">View Submissions</Button>
                        </div>

                        <div className='my-4 d-flex align-items-center'>
                            <div className="radius-50 overflow-hidden">
                                {singleTeam?.profile_picture === null ? <img className='brand-img w-100' src={Assets.GeneralPlaceholderImage} onError={(e) => e.target.src = Assets.GeneralPlaceholderImage} />
                                    : <img className='brand-img w-100' src={singleTeam?.profile_picture} onError={(e) => e.target.src = Assets.GeneralPlaceholderImage} />}
                            </div>
                            <div className="ms-3">
                                <h6>{singleTeam?.name}</h6>
                                <div >
                                    <StarIcon />
                                    {/* points are not in the single team api */}
                                    <small className='ms-2'>{singleTeam?.streaks_points} pt</small>
                                </div>
                            </div>
                        </div>

                        <div className="profile-popup-footer d-flex align-items-center mb-3">
                            <div className='me-5 ms-4'>
                                <DoneIcon />
                                <small className='ms-2'>{singleTeam?.successful_posts} Success Posts</small>
                            </div>

                            <div className='margin-top ms-5'>
                                <FireIcon />
                                <small className='ms-2'>{singleTeam?.streak_score}</small>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProfilePopup
