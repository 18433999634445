import React, { useContext } from 'react'
import Assets from '../../constants/images';
import { putNotification } from '../../services/notification';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import { useNavigate, NavLink } from "react-router-dom";
import { NotificationClickContext, NotificationPopupContext } from '../../App'



const NotificationCard = ({ read_at, name, elapsed_time, message, image, id, clickAction }) => {
    var date = new Date()
    date = moment(date).format('YYYY-MM-DD hh:mm:ss');
    console.log("Time", date)

    const [notificationClick, setNotificationClick] = useContext(NotificationClickContext);
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const handleNotificationCard = async (id, date) => {
        await putNotification(id, date).then((resp) => {
            console.log("this is click action", resp)
            setNotificationClick(notificationClick + 1)
            setHandleNotificationPopup(false)
        })

    }
    return (

        <NavLink to={`/post-management/${id}`}>
            <div className='notification-card mb-2 border-bottom pb-2 cursor-pointer' onClick={() => { handleNotificationCard(id, date) }}>
                <ToastContainer />
                <div className={`notification-box ${read_at == null && "bg"} d-flex align-items-start p-3`}>
                    <div className='notification-img'>
                        {image === null ? <img src={Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} /> : <img src={image} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} />}

                    </div>
                    <div className='ms-3 w-100'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <span className='d-block'>{name}</span>
                            <small className='message'>Sent you a message</small>
                        </div>
                        <p className='p-0 m-0'>{message}</p>
                        <small className='time-small'>{elapsed_time}</small>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

export default NotificationCard