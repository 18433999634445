import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AddCardPopupContext, CardConfirmationPopupContext } from '../../App';
import { FaTimes } from 'react-icons/fa';
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { addStripeRequest } from '../../services/packages';
import { NavLink, useNavigate } from 'react-router-dom';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";


const AddCardPopup = ({ defaultPackage, setDefaultPackage, setCurrentCard }) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [addCardPopup, setAddCardPopup] = useContext(AddCardPopupContext);
    const [CardConfirmationPopup, setCardConfirmationPopup] = useContext(CardConfirmationPopupContext);
    const [isLoading, setIsLoading] = useState(false);

    /*Add new Card Details*/
    const onCardDetailsSubmit = async (event) => {
        event.preventDefault();
        event.target.disabled = true

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (error) {
            event.target.disabled = false
        }

        if (paymentMethod) {
            try {
                const resp = await addStripeRequest('pm_card_visa'); //paymentMethod //todo: paymentMethod need to be added on line 42 condition when we go live
                if(resp.data.success){
                    success(resp.data.message)
                    setAddCardPopup(false)
                    setCardConfirmationPopup(true)
                    setCurrentCard(true);
                }
            } catch (e) {
               console.log("stripe error msg")
            }
        }
    }

    /*const CardModalContent = () => {
        let style = {
            base: {
                iconColor: '#3D2570',
                color: '#3D2570',
                fontWeight: '500',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                ':-webkit-autofill': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
                '::placeholder': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
            },
            invalid: {
                iconColor: '#E14339',
                color: '#E14339',
            },
        };
        return (
            <div className={"cardDetailsModal"}>
                <Form className={"addNewCardDetails"} autoComplete={"off"}>
                    <div className="p-3">


                </Form>
            </div>
        )
    }*/

    let style = {
        base: {
            iconColor: '#3D2570',
            color: '#3D2570',
            fontWeight: '500',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '16px',
            ':-webkit-autofill': {
                fontWeight: '500',
                color: '#3D2570',
            },
            '::placeholder': {
                fontWeight: '500',
                color: '#3D2570',
            },
        },
        invalid: {
            iconColor: '#E14339',
            color: '#E14339',
        },
    };

    return (
        <>
            <ToastContainer />
            <Modal
                className='addCard-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Body>
                    <div className='addCard-popup'>
                        <div
                            className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                            <h5 className='p-0 m-0'>Please Add Your Card Details</h5>
                            <div onClick={() => setAddCardPopup(false)}
                                className="d-flex align-items-center justify-content-center text-center fa-times bg-light rounded-circle p-1 cursor-pointer">
                                <FaTimes /></div>
                        </div>
                        <div className="modal-body">
                            <div className="addCard-box">

                                <CardElement options={{ style: style }}
                                    className={`text-black themeCardStripe ${isLoading ? "d-none" : ''}`} />
                                {
                                    (isLoading)
                                        ?

                                        <Button className={"btn-solid w-100 btn-pay"}
                                            disabled={true}
                                        >
                                            Processing...
                                        </Button>

                                        :

                                        <Button type="submit" className="btn-yellow w-100 bg-yellow text-uppercase mt-4"
                                            onClick={(event) => {
                                                onCardDetailsSubmit(event)
                                            }} disabled={!stripe || !elements}> Add Card</Button>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCardPopup