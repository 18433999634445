import React, { useState, useEffect, useContext } from 'react'
import { Button, Carousel, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { FaTimes, FaCheck } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import DateTimePicker from 'react-datetime-picker';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Select from 'react-select'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { PendingPostData, DeletePopupContext } from '../../App';
import { AccepRejectPost } from "../../services/posts"

//Services Importer
import { DeletePost } from "../../services/posts";
import { PendingPost } from "../../services/dashboard";

import DeletePopup from "../../components/popups/DeletePopup"
import {UpdateUserOnLocalStorage} from "../../services/auth";

const PendingPostPopup = (props) => {
    const [deletePopup, setDeletePopup] = useContext(DeletePopupContext);
    const { id, user_id, setVisible } = props?.popupData
    const [pendingPost, setPendingPost] = useContext(PendingPostData);
    const { register, handleSubmit, reset, watch, control, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });


    const acceptPost = () => {
        AccepRejectPost(id, "approved").then((resp) => {
            props.setVisible(false)
            success("Post has been accepted");
            setDeletePopup(false)
            PendingPost(user_id).then((response) => {
                setPendingPost(response.data.data)
                setPendingPost(pendingPost + 1)
            })
        })
        UpdateUserOnLocalStorage().then(dt => {}).catch(err=>{})
    }

    const rejectPost = () => {
        AccepRejectPost(id, "rejected").then((resp) => {
            props.setVisible(false)
            success("Post has been Rejected");
            setDeletePopup(false)
            PendingPost(user_id).then((response) => {
                setPendingPost(response.data.data)
                setPendingPost(pendingPost + 1)
            })
        })
        UpdateUserOnLocalStorage().then(dt => {}).catch(err=>{})

    }

    return (
        <div>
            <ToastContainer />

            <Modal
                className='pending-post-modal'
                show={props.visible}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Pending Post</h6>
                        <div onClick={() => props.setVisible(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <div className='avatar-container d-flex align-items-center mb-3'>
                        <img src={props?.popupData?.user?.details?.user_image_url} alt="Avatar" className="rounded-circle" />
                        <h6 className='d-inline-block p-0 m-0 ms-2'>{props?.popupData?.user?.name}</h6>
                    </div>

                    <div>
                        <div className="pendingPost-wrap input-icon mb-3">
                            <p>{props?.popupData?.text == null ? "No Caption has been added" : props?.popupData?.text}</p>
                        </div>

                        <div className="pendingPost-wrap input-icon-location input-icon mb-3">
                            <p>{props?.popupData?.location == null ? "No Location has been added" : props?.popupData?.location}</p>
                        </div>

                        <div className="pendingPost-wrap icon-addPeople input-icon mb-3">
                            <p> {props?.popupData?.tags.length > 0 ? props?.popupData?.tags?.map((v, i) => (
                                <span key={i}>
                                    {v.user_name},
                                </span>
                            )) : <p className='m-0'>No user has been tag</p>}</p>
                        </div>
                        <p className='input-icon input-icon-addImage'>Added Media</p>
                        <div className="pendingPost-box  input-icon-addImage mb-3">

                            <Swiper
                                // navigation
                                modules={[Navigation]}
                                spaceBetween={10}
                                breakpoints={{
                                    200: {
                                        slidesPerView: 2,
                                    },
                                    300: {
                                        slidesPerView: 3,
                                    },
                                    640: {
                                        slidesPerView: 5,
                                    },
                                    768: {
                                        slidesPerView: 6,
                                    },
                                    992: {
                                        slidesPerView: 8,
                                    },
                                    1200: {
                                        slidesPerView: 10,
                                    },
                                    1300: {
                                        slidesPerView: 10,
                                    },
                                }}
                            >
                                {
                                    props?.popupData?.media.length > 0 ?
                                        props?.popupData?.media?.map((v, i) => {
                                            return (
                                                <SwiperSlide key={i}>
                                                    <div className="box">
                                                        {v.type.toLowerCase() === "image" ? <img src={v?.url} alt="PostImage1" /> :
                                                            <video width="100%" height="100%" controls={false} >
                                                                <source src={v.url} type="video/mp4" />
                                                            </video>
                                                        }

                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }) :
                                        <p>No Image added</p>
                                }
                            </Swiper>
                        </div>

                        <div className='socialCheckbox d-flex'>
                            {props?.popupData?.platforms?.map((v, i) => (
                                <div key={i}>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked
                                            id={v.name}
                                            className=''
                                            {...register("socialPlatform", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SOCIAL_PLATFORM_REQUIRED
                                                },
                                            })}
                                        />
                                        <label htmlFor={v.name} className={`d-flex align-items-center justify-content-center text-center label-${v.name.toLowerCase()}`}>
                                            {v.id == 1 ? <FacebookIcon /> : null}
                                            {v.id == 2 ? <TwitterIcon /> : null}
                                            {v.id == 3 ? <InstagramIcon /> : null}
                                            {v.id == 4 ? <LinkedinIcon /> : null}
                                            {v.id == 5 ? <YoutubeIcon /> : null}
                                        </label>
                                    </div>

                                </div>
                            ))}
                        </div>
                        {errors.socialPlatform && <small className='text-red'>{errors.socialPlatform.message}</small>}

                        <div>
                            <Row>
                                <Col xs={6}>
                                    <Button
                                        onClick={() => {
                                            setDeletePopup(true)
                                            props.setHandleAcceptReject({
                                                text: "Are you sure you want to accept this post",
                                                name: "accept",
                                                method : acceptPost
                                            })
                                        }}
                                        className="btn-accept-post d-flex align-items-center justify-content-center text-center w-100 mt-5"
                                    >
                                        <FaCheck />
                                        <span className='ms-2'>Accept Post</span>
                                    </Button>
                                </Col>

                                <Col xs={6} className="mb-3">
                                    <Button
                                        onClick={() => {
                                            setDeletePopup(true)
                                            props.setHandleAcceptReject({
                                                text: "Are you sure you want to reject this post",
                                                name: "reject",
                                                method : rejectPost
                                            })
                                        }}
                                        className="btn-reject-post d-flex align-items-center justify-content-center text-center w-100 mt-5"
                                    >
                                        <FaTimes />
                                        <span className='ms-2'>Reject Post</span>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
})(PendingPostPopup);
