import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { InstagramLogo, FacebookLogo, TwitterLogo, LinkedinLogo, YoutubeLogo, BankCard, StripeAccount, ApplePay } from "../../constants/svgs"
import { ProfileContext } from "../../App"
import {GetAuthUserLocalStorage} from "../../services/auth";

const YourPlanCard = ({profileDetail}) => {
    // const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    // const profileDetail = GetAuthUserLocalStorage()
    return (
        <div className="choose-plan-card p-4">
            <h6 className='border-bottom pb-4 mb-5'>Your Plan</h6>
            <div>
                <h3>{profileDetail?.package?.name}: ${profileDetail?.package?.price}/MO </h3>
                <div className="description-container">
                    <p>{profileDetail?.package?.short_desc == null ? "ption not added" : profileDetail?.package?.short_desc}</p>
                </div>
                <h4>{profileDetail?.package?.text}</h4>
            </div>
            {/* Dev need to work on this as from API Social platforms are not as per the login requirements. */}
            <div className="icon-div mt-4">
                <ul>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><InstagramLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><FacebookLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><TwitterLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><LinkedinLogo /></NavLink></li>
                    <li className='me-2 mt-2 me-sm-3'><NavLink to="#"><YoutubeLogo /></NavLink></li>
                </ul>
            </div>
        </div>
    )
}

export default YourPlanCard
