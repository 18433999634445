import React, { useEffect, useState, useContext } from 'react'
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import { useForm } from "react-hook-form";
import { Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import { VALIDATIONS, VALIDATIONS_TEXT, SPECIAL_CHARACTER_ARRAY } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { ProfilePicture } from "../../constants/svgs";
import Assets from "../../constants/images"
import { UploadFileToS3 } from "../../utlis/helper"
import { UserContext } from '../../App';
import { GetMyProfile, UpdateUser } from '../../services/profile';
import { constant } from '../../utlis/constants';
import { CreateTeam } from '../../services/teamsUser';
import { SetAuthUserLocalStorage } from '../../services/auth';
import BlurLoader from '../blurLoader/BlurLoader';

const SetProfileForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [userSocialData, setUserSocialData] = useState();

    useEffect(() => {
        if (location.state) {
            setUserSocialData({
                name: location.state.name,
                image: location.state.image,
            })
        }
    }, [])

    const [step, setStep] = useState(1);
    const [userProfile, setUserProfile] = useState({
        file: null,
        file_url: null,
        aws_url: null
    })

    const [teamProfile, setTeamProfile] = useState({
        file: null,
        file_url: null,
        aws_url: null
    });

    const [userData, setUserData] = useContext(UserContext);

    const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setLoading(true);

        if (userProfile.file) {
            try {
                await UploadFileToS3(userProfile.file, constant.userDirName).then((document) => {
                    data.user_image_url = document.url
                })
            }
            catch (e) {
                console.log(e.response.data.message)
            }
        }

        if (teamProfile.file) {
            try {
                await UploadFileToS3(teamProfile.file, constant.userDirName).then((document) => {
                    data.profile_picture = document.url
                })
            }
            catch (e) {
                console.log(e.response.data.message)
            }
        }

        try {
            data.owner_id = userData.id
            let updateUser = {
                about: data?.about || "",
                name: data?.name || "",
                phone: data?.phone || "00000000000",
                user_image_url: data?.user_image_url || Assets.ProfilePicture
            }
            console.log(data, updateUser)
            let teamData = {
                owner_id: data?.owner_id || userData.id,
                teamTitle: data?.teamTitle || "",
                teamphone: data?.teamphone || "00000000000",
                profile_picture: data?.profile_picture || Assets.ProfilePicture,
            }
            await UpdateUser(userData.id, updateUser).then(async (res) => {
            })
            await CreateTeam(teamData, 1).then(async (response) => {
                console.log("create Team ka response", response)
                const resp = await GetMyProfile(data)
                await setUserData(resp.data.data)
                await SetAuthUserLocalStorage(resp.data.data)
                success("Profile created successfully")
                navigate("/choose-plan")
            })
        }
        catch (e) {
            console.log(e.message)
        }
    }

    return (
        <Row className="justify-content-center">
            <ToastContainer />
            <Col xs={12} xxl={5} xl={6} lg={8} sm={10} className="mb-5 mb-sm-0">
                <div className="d-flex justify-content-end">
                    <div className='d-inline-block setup-profile-form-tabs py-2 ps-3 pe-3 text-end mt-5'>
                        <Button className={`step-button me-1 ${step === 1 && "bg-dark"}`} onClick={() => setStep(1)}>Set Your Profile</Button>
                        <Button className={`step-button ${step === 2 && "bg-dark"}`} type="button" disabled={!isValid} onClick={() => setStep(2)}>Set Team Profile</Button>
                    </div>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)} className='position-relative'>
                    {
                        step === 1 &&
                        <div className='setup-profile-form p-4 position-relative'>

                            <div className='text-center'>
                                <label htmlFor="user-profile">
                                    <input
                                        accept=".png, jpe, jpg, jfif" className='d-none' type="file" name="user-profile" id="user-profile"
                                        onChange={(e) => {
                                            setUserProfile({
                                                file: e.target.files[0],
                                                file_url: URL.createObjectURL(e.target.files[0])
                                            })
                                        }}
                                    />
                                    {
                                        userSocialData?.image ?
                                            <div className="profile-img">
                                                <img src={userSocialData?.image} alt="-" />
                                            </div>
                                            :
                                            userProfile?.file_url ?
                                                <div className="profile-img">
                                                    <img src={userProfile?.file_url} alt="" />
                                                </div>
                                                :
                                                <ProfilePicture />
                                    }
                                </label>
                            </div>

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    name="name"
                                    type="text"
                                    // defaultValue={userSocialData?.name ? userSocialData?.name : ''}
                                    placeholder="Your Name *"
                                    {...register("name",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.NAME,
                                                message: VALIDATIONS_TEXT.NAME_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.NAME_REQUIRED
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.name && <small className='text-red'>{errors.name.message}</small>}

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                    name="phone"
                                    type="number"
                                    placeholder="Contact Number *"
                                    {...register("phone",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.PHONE_MIN,
                                                message: VALIDATIONS_TEXT.PHONE_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.PHONE_MAX,
                                                message: VALIDATIONS_TEXT.PHONE_MAX
                                            },
                                            pattern: {
                                                value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.PHONE_REQUIRED
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    className="py-3"
                                    as="textarea"
                                    rows={4}
                                    name="about"
                                    placeholder="Bio"
                                    {...register("about",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.BIO_MAX,
                                                message: VALIDATIONS_TEXT.BIO_MAX
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.about && <small className='text-red'>{errors.about.message}</small>}

                            <Button type="button" disabled={!isValid} onClick={() => setStep(2)} className='mt-4 w-100 btn-continue'>CONTINUE</Button>


                        </div>

                    }
                    {
                        step === 2 &&
                        <div className='setup-profile-form p-4 position-relative'>
                            {loading && <BlurLoader />}
                            <div className='text-center'>
                                <label htmlFor="team-profile">
                                    <input
                                        accept=".png, jpe, jpg, jfif" className='d-none' type="file" name="team-profile" id="team-profile"
                                        onChange={(e) => {
                                            setTeamProfile({
                                                file: e.target.files[0],
                                                file_url: URL.createObjectURL(e.target.files[0])
                                            })
                                        }}
                                    />
                                    {
                                        teamProfile?.file_url ?
                                            <div className="profile-img">
                                                <img src={teamProfile?.file_url} alt="profileImage" />
                                            </div>
                                            :
                                            <ProfilePicture />
                                    }
                                </label>
                            </div>

                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    name="teamTitle"
                                    type="text"
                                    placeholder="Team Title *"
                                    {...register("teamTitle",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.TEAM_TITLE_MAX,
                                                message: VALIDATIONS_TEXT.TEAM_TITLE_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.TEAM_TITLE_REQUIRED
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.teamTitle && <small className='text-red'>{errors.teamTitle.message}</small>}


                            <InputGroup className='mt-3'>
                                <Form.Control
                                    onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                    name="teamphone"
                                    type="number"
                                    placeholder="Team Contact Number"
                                    {...register("teamphone",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.PHONE_MIN,
                                                message: VALIDATIONS_TEXT.PHONE_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.PHONE_MAX,
                                                message: VALIDATIONS_TEXT.PHONE_MAX
                                            },
                                            pattern: {
                                                value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.PHONE_REQUIRED
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.teamphone && <small className='text-red'>{errors.teamphone.message}</small>}

                            <Button type="submit" className='mt-4 w-100 btn-continue'>SAVE AND CONTINUE</Button>

                        </div>
                    }
                </Form>
            </Col>
        </Row >
    )
}

export default SetProfileForm
