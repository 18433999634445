import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';


//Upadate Team

export const UpdateTeamById = async (id, data) => {
    const { name, phone, profile_picture } = data;
    const resp = await axios.put(`${constant.BASE_URL}${apiEndPoints.teams}/${id}`, { name, phone, profile_picture }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}


//Auto post 


export const AutoPost = async (team_id, auto_status) => {
    const resp = await axios.post(`${constant.BASE_URL}${apiEndPoints.auto_post}?team_id=${team_id}&auto_post=${auto_status}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp;
}


//Current Team

export const CurrentTeam = async (team_id, user_id) => {
    const resp = await axios.post(`${constant.BASE_URL}${apiEndPoints.currentTeam}`, { team_id, user_id }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp;
}