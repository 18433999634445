import React, { useContext, useState } from 'react'
import { Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, SPECIAL_CHARACTER_WITH_NUMBER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { constant } from '../../utlis/constants'
import { base_url } from "../../constants/baseUrl";
import axios from "axios"
import { SetAuthUserLocalStorage, SetTokenLocalStorage, Signup, SocialLogin } from '../../services/auth';
import {CurrentTeamContext, UserContext} from '../../App';
//=========================== Social Login Imports =============================== //
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
} from 'reactjs-social-login';




const SignupForm = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserContext);
    const [currentTeamContext, setCurrentTeam] = useContext(CurrentTeamContext)
    const [showPassword, setShowPassword] = useState(false);
    const [isdisable, setIsDisable] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const { email, password } = data
        try {
            const response = await Signup(email, password);
            setIsDisable(true)
            success(response.data.data.message)
            await SetTokenLocalStorage(response.data.data.access_token)
            await SetAuthUserLocalStorage(response.data.data)
            await setUserData(response.data.data)
            if (response.data.data.teams === undefined || response.data.data.teams.length == 0) {
                navigate("/set-profile", { replace: true })
            }
            else if (response.data.data.all_subscriptions === undefined && response.data.data.all_subscriptions.length == 0) {
                navigate("/choose-plan", { replace: true })
            }
            else {
                if (response.data.data.user.teams.length > 0) {
                    setCurrentTeam(response?.data?.data?.user?.current_team?.id)
                }
                navigate("/dashboard", { replace: true })
            }
        }
        catch (e) {
            setTimeout(() => {
                error(e.response.data.message)
            }, 1000);
        }
    }

    //Google Login Here
    const onGoogleLogin = (provider, data) => {
        if (data) {
            let params = {
                'username': data?.name,
                'email': data?.email,
                'image': data?.picture,
                'platform': provider,
                'client_id': process.env.REACT_APP_GG_APP_ID || data?.id,
                'token': data?.access_token,
                'device_type': constant.DEVICE_TYPE,
                'device_token': constant.DEVICE_TOKEN,
                'role_id': constant.owner_role_id
            }
            onSubmitSocialLogin(params);
        }

    }

    //Facebook Login Here
    const onFacebookLogin = (provider, data) => {
        if (data) {
            let params = {
                'username': data?.name,
                'email': data?.email,
                'image': data?.picture?.data?.url,
                'platform': provider,
                'client_id': data?.userID,
                'token': data?.accessToken,
                'device_type': constant.DEVICE_TYPE,
                'device_token': constant.DEVICE_TOKEN,
                'role_id': constant.owner_role_id
            }
            onSubmitSocialLogin(params);
        }
    }

    const onSubmitSocialLogin = async (data) => {
        try {
            const { username, email, image, platform, client_id, token, device_type, device_token } = data
            const response = await SocialLogin(username, email, image, platform, client_id, token, device_type, device_token);
            success(response.data.message)
            await SetTokenLocalStorage(response.data.data.user.access_token);
            await SetAuthUserLocalStorage(response.data.data.user);
            await setUserData((response.data.data.user))
            console.log(response?.data?.data?.user?.name, response.data.data);
            if (response.data.data.user && response.data.data.user.teams.length < 1) {
                navigate("/set-profile", {
                    replace: true,
                    state: {
                        name: response?.data?.data?.user?.name,
                        image: response?.data?.data?.user?.details?.image,
                    }
                })
            } else if (response.data.data.user && response.data.data.user.all_subscriptions.length < 1) {
                navigate("/choose-plan", {
                    replace: true,
                    state: {
                        name: response?.data?.data?.user?.name,
                        image: response?.data?.data?.user?.details?.image,
                    }
                })
            } else {
                navigate("/dashboard", {
                    replace: true,
                    state: {
                        name: response?.data?.data?.user?.name,
                        image: response?.data?.data?.user?.details?.image,
                    }
                })
            }
        } catch (e) {
            console.log(e.response.data.message)
        }
    }


    return (
        <section className='signup-section mt-3 mt-md-5'>
            <ToastContainer />
            <div className='d-block d-md-none text-center mb-4'>
                <h1 className='welcome-text'>WELCOME TO RODEO</h1>
            </div>
            <span className='d-block text-center mb-5'>Let's create your account <br /> Enter your details to setup your account.</span>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-3' controlId="email">
                    <Form.Control
                        name="email"
                        type="email"
                        placeholder="Email *"
                        {...register("email",
                            {
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            })
                        }
                    />
                </InputGroup>
                {errors.email && <small className='text-red'>{errors.email.message}</small>}

                <InputGroup className='mt-3' controlId="Password">
                    <Form.Control
                        name="password"
                        className="password-input"
                        placeholder="Password *"
                        type={!showPassword ? "password" : "text"}
                        {...register("password",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                },
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.password && <small className='text-red'>{errors.password.message}</small>}

                <div className='text-center'>
                    <Button type="submit" className="w-100 mt-5" disabled={isdisable == true ? true : false}>SIGN UP</Button>
                </div>
            </Form>

            <div className='text-center mt-5'>
                <span>or continue with</span>
                <div className='d-flex align-items-center mt-4 justify-content-center'>
                    <div style={{ width: '140px' }}>
                        <LoginSocialGoogle
                            isOnlyGetCode
                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                            //onLoginStart={onLoginStart}
                            scope="openid profile email"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                                console.log(data,provider, process.env.REACT_APP_GG_APP_ID, 'Google Provider')
                                onGoogleLogin(provider, data)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <Button
                                className='d-flex align-items-center justify-content-center btn-google w-100 me-3'><FcGoogle
                                    className='me-2' />Sign in</Button>
                        </LoginSocialGoogle>
                    </div>

                    <div className='ms-4' style={{ width: '140px' }}>
                        <LoginSocialFacebook
                            isOnlyGetCode
                            appId={process.env.REACT_APP_FB_APP_ID || ''}
                            //onLoginStart={onLoginStart}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            }
                            onResolve={({ provider, data }) => {
                                onFacebookLogin(provider, data)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <Button
                                className='d-flex align-items-center justify-content-center btn-facebook w-100'><FaFacebookF
                                    className='me-2' />Sign in</Button>
                        </LoginSocialFacebook>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignupForm
