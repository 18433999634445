import React, { useState, useEffect, useRef } from 'react'
import { Button, Carousel, Form, InputGroup, Modal, Row, Col } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import Assets from '../../constants/images'
import {
    AddUserIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    LocationIcon,
    TwitterIcon,
    YoutubeIcon,
    YoutubeIconWithoutBg
} from '../../constants/svgs'
import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import { Controller, useForm } from "react-hook-form";
import {
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    VALIDATIONS,
    VALIDATIONS_TEXT
} from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import DateTimePicker from 'react-datetime-picker';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Select from 'react-select'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import moment from "moment";
import DatePicker from 'react-datepicker';
import { TeamDaysSlots } from "../../services/templates";
import { GetMyProfile } from "../../services/profile";
import { CreatePost, DeletePostMedia, EditPost } from "../../services/posts";
import { GetAuthUserLocalStorage, UpdateUserOnLocalStorage } from "../../services/auth";
import { UploadFileToS3 } from "../../utlis/helper";
import { constant, s3Credential } from "../../utlis/constants";
import EditImageModal from "./EditImageModal";

const EditPostPopup = (props) => {
    const [locationValue, setLocationValue] = useState(props?.editPostPopUp?.location);
    const [data, setData] = useState(props?.editPostPopUp?.location);
    const [address, setAddress] = useState();
    const [options, setOptions] = useState([]);
    const [timeOptions, setTimeOptions] = useState()
    const [value, onChange] = useState(new Date());
    const [showLocationPickerModal, setShowLocationPickerModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState([]);
    const { register, handleSubmit, reset, watch, control, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [profile, setProfile] = useState([]);
    const [currTeam, setCurrTeam] = useState("")
    const [tempDays, setTempDays] = useState([]);
    const [teamPlatform, setTeamPlatform] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [timeSlots, setTimeSlots] = useState(true);
    const [newDate, setNewDate] = useState(moment(props?.editPostPopUp?.schedule_time.split(' ')[0]).format('ddd MMM DD, yyyy'));
    const [selectedTemplate, setSelectedTemplate] = useState("")
    const [selectedTimeDisable, setSelectedTimeDisable] = useState(true);
    const [disableDate, setDisableDate] = useState(true);
    const [startTime, setStartTime] = useState(props?.editPostPopUp?.schedule_time.split(' ')[1]);
    const selectTime = useRef()
    const selectDate = useRef()
    const locationRef = useRef()

    const mapStyles = {
        position: "relative",
        width: "100%",
        height: "100%"
    };

    useEffect(() => {
        console.log(props, 'pros')
        myProfile()
    }, [])

    useEffect(() => {
        locationRef.current?.setAddress('Some Text');
    }, []);

    // useEffect(() => {
    //     data === "" ? setData("") : setData("test");
    // }, [data]);



    useEffect(() => {
        getTeamDaysSlots()
        setDisableDate(false);
    }, [currTeam, selectedTemplate])

    const myProfile = async () => {
        let response = await GetMyProfile()
        let resp = response?.data?.data
        setTeamPlatform(resp?.current_team?.platforms);
        setSelectedTemplate(resp?.current_team?.template?.id)
        setCurrTeam(resp?.current_team?.id);
        let teams = resp?.team;
        let option = []
        if (teams && teams.team_users.length > 0) {
            teams.team_users.map((v, i) => {
                option.push({
                    value: v.id,
                    label: v?.name
                })
            })
            setOptions(option)
        }
        setProfile(resp.data)
    }

    const getTeamDaysSlots = async () => {
        let response
        if (selectedTemplate == 3) {
            response = await TeamDaysSlots(currTeam, selectedTemplate);
        } else {
            response = await TeamDaysSlots('', selectedTemplate);
        }
        if (response?.data?.data) {
            let loop = [];
            response?.data?.data.map((dt) => {
                loop = [...loop, dt.day]
            })
            setTempDays(loop)
            setTimeSlots(response?.data?.data)
        }
    }

    const isWeekday = (date) => {
        const day = date.getDay();
        return tempDays.includes(day);
    };

    const handleImageUpload = (e) => {
        setUploadedImage([...uploadedImage, ...e.target.files])
    }

    useEffect(() => {
        reset({
            caption: props?.editPostPopUp.text,
            location: props?.editPostPopUp.location,
        });

        setUploadedImage([...props?.editPostPopUp.media])
    }, [])

    const deleteImage = async (data, index) => {
        if (data.id) {
            await DeletePostMedia(data.id);
        }
        let temp = [...uploadedImage]
        temp.splice(index, 1)
        setUploadedImage(temp)
    }

    console.log(uploadedImage)

    const returnFileType = (file) => {
        let fileType = file.name.split(".")
        fileType = fileType[fileType.length - 1]

        if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "jfif") {
            fileType = "image"
        } else if (fileType === "pdf" || fileType === "docx" || fileType === "doc" || fileType === "txt" || fileType === "xlsx" || fileType === "tiff") {
            fileType = "document"
        } else if (fileType === "mp3") {
            fileType = "mp3"
        } else if (fileType === "mp4" || fileType === "mov") {
            fileType = "video"
        } else {
            fileType = "invalid"
        }
        return fileType
    }

    const onSubmit = async (data) => {
        // console.log(startDate === "")

        //time handling
        let time = moment(startTime, "hh:mm A").format("HH:mm:ss")
        let schedule_time = moment(startDate).format('YYYY-MM-DD') + " " + time;

        //if startDate is empty because no change in date and time cause no startDate
        if (startDate === "") schedule_time = moment(newDate).format('YYYY-MM-DD') + " " + time


        let addPeople = []
        if (data.addPeople.length > 0) {
            data.addPeople.map(d => addPeople.push(d.value))
        }
        else {
            props.editPostPopUp.tags.map(d => addPeople.push(d.user_id))
        }

        console.log(addPeople, props.editPostPopUp.tags, 'This is Add People')
        let userData = GetAuthUserLocalStorage()
        // reset();
        // success("Post Updated")
        setTimeout(() => {
            props.handleClickEditPostPopup()
            setShowLocationPickerModal(false)
        }, 1500)

        let coordinate = [];
        if (data.location) {
            await geocodeByAddress(locationValue?.label || data.location)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    //console.log('Successfully got latitude and longitude', {lat, lng})
                    coordinate = { lat: lat, lng: lng }
                });
        }

        let up_img = []
        if (data.image.length > 0) data.image.map(d => up_img.push(d))
        else props.editPostPopUp.media.map(d => up_img.push({ url: d.url, type: d.type }))

        let medias = []

        if (uploadedImage.length > 0) {
            for (let i = 0; i < uploadedImage.length; i++) {
                if (uploadedImage[i].url) {
                    medias.push({
                        url: uploadedImage[i].url,
                        type: uploadedImage[i].type
                    })
                }
                else {
                    try {
                        await UploadFileToS3(uploadedImage[i], constant.userDirName).then((document) => {
                            medias.push({
                                type: returnFileType(uploadedImage[i]),
                                url: s3Credential.s3EndPoint + document.url
                            })
                        })
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        } else {
            medias = up_img
        }

        let params = {
            template_id: selectedTemplate,
            user_id: userData.id,
            team_id: userData.teams[0].team_id,
            text: data.caption,
            status: 'pending',
            location: locationValue?.label || data.location,
            latitude: coordinate?.lat || "",
            longitude: coordinate?.lng || "",
            schedule_time: schedule_time ? schedule_time : '',
            user_ids: addPeople,
            platform_ids: data.socialPlatform.length >= 1 ? data.socialPlatform : [data.socialPlatform],
            medias: medias
        }

        console.log(params, 'Params')

        try {
            const resp = await EditPost(props?.editPostPopUp?.id, params)
            await UpdateUserOnLocalStorage()
            success(resp.data.message)
            props.setCounter(props.counter + 1)
            reset()
            setTimeout(() => {
                // setHandleCreatePostPopup(false)
                setShowLocationPickerModal(false)
            }, 1500)
        } catch (e) {
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        setValue("recuringTime", value)
        setValue("addPeople", [])
        setValue("image", uploadedImage)
        setSelectedTimeDisable(true);
    }, [value, uploadedImage])

    //running only one time for default date pick
    // useEffect(()=>{
    //     // datePickerOnChange(newDate)
    // },[])
    const datePickerOnChange = (date) => {
        setStartDate(date)
        let ts = timeSlots.filter((v) => v.day == date.getDay())
        //setDefaultTimeSlot(ts);
        let data = []
        ts.map((v, i) => {
            if (v.time && v.time.length > 0) {
                v.time.map((time) => {
                    data.push({
                        value: time,
                        label: time
                    })
                });
            }
        })
        setTimeOptions(data)
        setNewDate(moment(date).format('ddd MMM DD, yyyy'));
        setSelectedTimeDisable(false);
    }

    const returnBlob = (file) => {
        const blob = URL.createObjectURL(file)
        return blob
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                className='edit-post-modal'
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div
                        className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Edit Post </h6>
                        <div onClick={() => props.handleClickEditPostPopup()}
                            className="d-flex align-items-center justify-content-center text-center fa-times">
                            <FaTimes /></div>
                    </div>

                    <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                        <div className='avatar-container d-flex align-items-center mb-3'>

                            {props.editPostPopUp?.user?.current_team?.profile_picture == null ? <img src={Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} className="rounded-circle" />
                                : <img src={props.editPostPopUp?.user?.current_team?.profile_picture} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} className="rounded-circle" />}

                            <h6 className='d-inline-block p-0 m-0 ms-2'>{props.editPostPopUp?.user?.current_team?.name || 'N/A'}</h6>
                        </div>

                        <div>
                            <InputGroup className='mt-2'>
                                <Form.Control
                                    name="caption"
                                    placeholder="Add a caption"
                                    className="pt-3"
                                    as="textarea"
                                    rows={3}
                                    // defaultValue={props?.editPostPopUp?.text}
                                    //onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    {...register("caption",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.CAPTION_MAX,
                                                message: VALIDATIONS_TEXT.CAPTION_MAX
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.caption && <small className='text-red'>{errors.caption.message}</small>}

                            <InputGroup className='mt-2'>
                                <Select defaultValue={props.editPostPopUp.tags.map((v, i) => {
                                    return {
                                        value: v.user_id,
                                        label: v.user_name,
                                    }
                                })} onChange={(e) => setValue("addPeople", e)} className='w-100 custom-select-input'
                                    isMulti options={options} />
                            </InputGroup>

                            <div className='select-location mt-2'
                                style={{ width: '100%', border: 'none', background: '#f8f9fd' }}>
                                <InputGroup>
                                    <GooglePlacesAutocomplete
                                        className={"pt-3 form-control input-icon input-icon-location w-100 "}
                                        onLoadFailed={(error) => (
                                            console.error("Could not connect Google script", error)
                                        )}
                                        selectProps={{
                                            locationValue,
                                            onChange: setLocationValue,
                                            className: 'input-icon input-icon-location',
                                            placeholder: "Write Location...",
                                            defaultInputValue: data,
                                        }}
                                        debounce={"300"}
                                        minLengthAutocomplete={2}
                                        apiKey="AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
                                        {...register("location")}
                                    />
                                </InputGroup>
                            </div>

                            <Row>
                                <Col xs={6} xl={6} lg={6} md={12}>
                                    <div className="mt-2 d-flex flex-row ">
                                        <DatePicker
                                            ref={selectDate}
                                            disabled={disableDate}
                                            placeholderText={'Please select a date'}
                                            className={"pt-3 form-control input-icon input-icon-clockIcon"}
                                            onChange={(date) => datePickerOnChange(date)}
                                            filterDate={isWeekday}
                                            value={newDate}
                                            selected={startDate}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} xl={6} lg={6} md={12} >
                                    {/* Time Dropdown Select */}
                                    <div
                                        className={`mt-2 d-flex flex-row`}>
                                        <Select
                                            defaultValue={[props.editPostPopUp.schedule_time].map((v, i) => {
                                                return {
                                                    label: v.split(" ")[1],
                                                }
                                            })}
                                            onChange={(selectedOptions) => {
                                                setStartTime(selectedOptions.value)
                                            }}
                                            className='w-100 custom-select-input timedropdown'
                                            // isMulti
                                            options={timeOptions}
                                            placeholder={"Selected Time"}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            {errors.location && <small className='text-red'>{errors.location.message}</small>}


                            <InputGroup className='mt-2'>
                                <Form.Control
                                    readOnly
                                    placeholder="Add Image"
                                    className="bg-none input-icon input-icon-addImage"
                                />
                            </InputGroup>

                            <div className="d-flex mb-3 align-items-center">
                                <input
                                    onChange={(e) => handleImageUpload(e)}
                                    name="image"
                                    id="img-upload"
                                    type="file"
                                    multiple
                                    accept=".png, .jpeg, .jpg"
                                    className={`custom-file-input ${uploadedImage.length > 0 && "mt-15"} me-3`}
                                />
                                <Swiper
                                    // navigation
                                    modules={[Navigation]}
                                    spaceBetween={10}
                                    breakpoints={{
                                        200: {
                                            slidesPerView: 2,
                                        },
                                        300: {
                                            slidesPerView: 3,
                                        },
                                        640: {
                                            slidesPerView: 5,
                                        },
                                        768: {
                                            slidesPerView: 6,
                                        },
                                        992: {
                                            slidesPerView: 6,
                                        },
                                        1200: {
                                            slidesPerView: 10,
                                        },
                                        1300: {
                                            slidesPerView: 10,
                                        },
                                    }}
                                >
                                    {
                                        uploadedImage.length > 0 &&
                                        uploadedImage.map((data, i) => {
                                            // let url = URL.createObjectURL(v)
                                            return (
                                                <SwiperSlide>
                                                    <div className="box">
                                                        <div className='icon'>
                                                            <FaTimes onClick={() => deleteImage(data, i)} />
                                                        </div>
                                                        <img src={data?.url ? data.url : returnBlob(data)} alt="PostImage1" />
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                    {/* <EditImageModal editpostimage={ props?.editPostPopUp.media}/> */}
                                    {/*{*/}
                                    {/*    props?.editPostPopUp.media.length > 0 &&*/}
                                    {/*    props?.editPostPopUp.media.map((v, i) => {*/}
                                    {/*        return (*/}
                                    {/*            <SwiperSlide>*/}
                                    {/*                <div className="box">*/}
                                    {/*                    <div className='icon'>*/}
                                    {/*                        /!*Show here some Theme Modal*!/*/}
                                    {/*                        <FaTimes onClick={() => deletePostMedia(v.id)} />*/}
                                    {/*                    </div>*/}
                                    {/*                    {v?.type === "image" ? <img*/}
                                    {/*                        onError={(e) => e.target.src = Assets.GeneralPlaceholderImage}*/}
                                    {/*                        className="w-100" src={v.url} alt="First slide" /> :*/}
                                    {/*                        <video width="100%"*/}
                                    {/*                            height="100%" controls={true}>*/}
                                    {/*                            <source src={v.url} type="video/mp4" />*/}
                                    {/*                        </video>}*/}
                                    {/*                </div>*/}
                                    {/*            </SwiperSlide>*/}
                                    {/*        )*/}
                                    {/*    })*/}
                                    {/*}*/}
                                </Swiper>
                            </div>

                            {/*<Controller
                                name="recuringTime"
                                control={control}
                                defaultValue={null}
                                render={
                                    () => (
                                        <DateTimePicker onChange={onChange} value={value} />
                                    )
                                }
                            />
                            {value === null &&
                                <small className='text-red'>{VALIDATIONS_TEXT.DATE_TIME_REQUIRED}</small>}*/}

                            <InputGroup className='mt-2'>
                                <Form.Control readOnly placeholder="Select Platform(s)"
                                    className="bg-none input-icon input-icon-shareIcon" />
                            </InputGroup>

                            <div className='socialCheckbox d-flex w-100'>
                                {(teamPlatform && teamPlatform.length > 0) ?
                                    teamPlatform.map((data, index) => {
                                        return (
                                            <div key={index} className='platform-box'>
                                                < input
                                                    value={data?.platform_id}
                                                    type="checkbox"
                                                    defaultChecked={props.editPostPopUp.platforms[index]}
                                                    id={data?.platform?.name || "No Name"}
                                                    //onClick={(e)=>setValue("socialPlatform", e.target.value)}
                                                    className=''
                                                    {
                                                    ...register("socialPlatform", {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.SOCIAL_PLATFORM_REQUIRED
                                                        },
                                                    })
                                                    }
                                                />
                                                <label htmlFor={data?.platform?.name || "No name"}
                                                    className={`d-flex align-items-center justify-content-center text-center label-${data?.platform.name.toLowerCase()}`}>

                                                    {data?.platform_id == 1 && <FacebookIcon />}
                                                    {data?.platform_id == 2 && <TwitterIcon />}
                                                    {data?.platform_id == 3 && <InstagramIcon />}
                                                    {data?.platform_id == 4 && <LinkedinIcon />}
                                                    {data?.platform_id == 5 && <YoutubeIconWithoutBg />}

                                                </label>
                                            </div>
                                        )
                                    })


                                    : (<p className='mt-4'>No Platform Has Been Login Yet</p>)}
                            </div>
                            {/* {errors.socialPlatform && <small className='text-red'>{errors.socialPlatform.message}</small>} */}

                            <Button type="submit" className="w-100 mt-5 btn-create-post">UPDATE
                                POST</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
})(EditPostPopup);
