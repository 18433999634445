import React, {useEffect} from 'react'
import Assets from '../../constants/images'
import { AddUserIcon, DoneIconSquare, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, RejectIconSquare, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import moment from "moment";
import {PostSlotApprovedOrReject} from "../../services/templates";
import {error, success} from "../../constants/msg";

const PendingSubmissionCard = ({data, team_id, reset, setReset}) => {
    const acceptPost = (post_id, status) => {
        //todo: add confirmation popup
        postStatusUpdate(post_id, status)
    }

    const rejectPost = (post_id, status) => {
        //todo: add confirmation popup
        postStatusUpdate(post_id, status)
    }

    const postStatusUpdate = async (post_id, status) => {
        try {
            const resp = await PostSlotApprovedOrReject(post_id, status, team_id);
            console.log(resp.data.message);
            setReset(!reset);
            success(resp.data.message)
        } catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div className='pending-submission-card'>
            <div className='d-flex justify-content-between align-items-center border-bottom pb-3 mt-3'>
                <div className='avatar-container d-flex'>
                    <img src={data?.user?.details?.image_url || Assets.Avatar} onError={(e)=>e.target.src = Assets.ProfilePlaceholderImage} alt="Avatar" />
                    <div className='d-flex flex-column ms-2'>
                        <span>{data?.user?.name || ""}</span>
                        <small>{moment(data?.created_at).fromNow() || ""}</small>
                        <p className='p-0 m-0'>{data?.text || ""}</p>
                    </div>
                </div>

                <div className='ps-3 d-flex align-items-center'>
                    <div className='me-2' onClick={() => acceptPost(data.id, 'approved')} ><DoneIconSquare/></div>
                    <div onClick={() => rejectPost(data.id, 'rejected')}><RejectIconSquare/></div>
                </div>
            </div>
        </div>
    )
}

export default PendingSubmissionCard