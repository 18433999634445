import React, { useEffect, useState, useContext, useRef } from 'react'
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Assets from '../../constants/images';
import { CameraIcon } from '../../constants/svgs';
import { UserContext, ProfileContext } from "../../App";
//Get the profile data
import { GetMyProfile, UpdateUser } from "../../services/profile";
import { UploadFileToS3 } from "../../utlis/helper";
import { constant } from "../../utlis/constants";
import { SetAuthUserLocalStorage, UpdateUserOnLocalStorage } from "../../services/auth";

const EditProfileForm = () => {
    const navigate = useNavigate();
    const [preview, setPreview] = useState(null);
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [profileData, setProfileData] = useState();
    const [userData, setUserData] = useContext(UserContext);
    const logIn = profileDetail?.id;
    const [image, setImage] = useState(null);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        try {
            if (image) {
                try {
                    await UploadFileToS3(image, constant.userDirName).then((document) => {
                        data.user_image_url = document.url
                    })
                } catch (e) {
                    console.log(e.response.data.message)
                }
            }

            const response = await UpdateUser(logIn, data);
            SetAuthUserLocalStorage(response.data.data)
            setProfileDetail(response.data.data)
            setImage(null)
            success(response.data.message)
            setProfileDetail(profileDetail)
            setTimeout(()=>{
                navigate('/dashboard')
            },2000)

        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const handleImageUpload = (e) => {
        let img = e.target.files[0]
        let objectUrl = URL.createObjectURL(img)
        setImage(img)
        setPreview(objectUrl)
        console.log(preview,)
    }

    //API for getting the user data
    useEffect(() => {
        const getMyProfile = async () => {
            const res = await GetMyProfile()
            const data = res.data.data
            reset({
                name: data?.name,
                phone: data?.details?.phone,
                about: data?.details?.about
            });
            console.log(data?.details)
            setPreview(data?.details?.user_image_url)
            setProfileData(data)
        }

        getMyProfile()
    }, [profileDetail])

    return (
        <div className='edit-profile-form pb-4 mt-5'>
            <ToastContainer />
            <Row className='justify-content-center'>
                <Col xs={12} xxl={5} xl={6} lg={8} sm={10}>

                    <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                        <div className="text-center">
                            <label htmlFor="img-upload">
                                <div className="overlay"></div>
                                <img src={preview === null ? (profileData) : preview}
                                     onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} />
                                <input
                                    name="user_image_url"
                                    id="img-upload"
                                    type="file"
                                    accept=".png, .jpeg, .jpg"
                                    onChange={(e) => handleImageUpload(e)}
                                />

                                <CameraIcon />
                            </label>
                        </div>

                        <InputGroup className='mt-3'>
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                name="name"
                                type="text"
                                placeholder="Your Name"
                                {...register("name",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.name && <small className='text-red'>{errors.name.message}</small>}

                        <InputGroup className='mt-3'>
                            <Form.Control
                                onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                name="phone"
                                type="text"
                                placeholder="Contact Number"
                                {...register("phone",
                                    {
                                        minLength: {
                                            value: VALIDATIONS.PHONE_MIN,
                                            message: VALIDATIONS_TEXT.PHONE_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PHONE_MAX,
                                            message: VALIDATIONS_TEXT.PHONE_MAX
                                        },
                                        pattern: {
                                            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                            message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                        <InputGroup className='mt-3'>
                            <Form.Control
                                className="py-3"
                                as="textarea"
                                rows={4}
                                name="about"
                                placeholder="Bio"
                                {...register("about",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.BIO_MAX,
                                            message: VALIDATIONS_TEXT.BIO_MAX
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.about && <small className='text-red'>{errors.about.message}</small>}

                        <div className='text-center mt-5'>
                            <Button type="submit" className="btn-update-profile w-100">UPDATE PROFILE</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default EditProfileForm
