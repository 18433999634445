import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import {GetTokenLocalStorage, UpdateUserOnLocalStorage} from './auth';

export const TutorialViewed = async () => {
    const tutorial_viewed = 1
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.tutorial_viewed}?tutorial_viewed=${tutorial_viewed}`,{}, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    await UpdateUserOnLocalStorage()
    return resp
}
