import React, {useState, useEffect, useContext} from 'react'
import {Route, Navigate, Outlet, useNavigate} from "react-router";
import {constant} from './utlis/constants'


const PublicRoute = () => {
    //const navigate = useNavigate();
    const token = localStorage.getItem(constant.LOCAL_STORAGE_TOKEN)

    return (
        token ? <Navigate to="/dashboard"/> : <Outlet/>
    )
}

export default PublicRoute;