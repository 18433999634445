import React, { useState, useEffect, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import Assets from '../constants/images';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Badge1Large, Badge1XLarge, Badge2Large, Badge3Large, Badge4Large, Badge5Large, DoneIcon, FireIcon } from '../constants/svgs'
import { RiArrowDropDownFill } from "react-icons/ri";
import { error } from "../constants/msg";
import { BadgesList } from "../services/teamBadges";
import Loader from '../components/loader/Loader';
import BlurLoader from '../components/blurLoader/BlurLoader';
import { getSingleTeam } from "../services/singleTeam";
import { UserContext, ProfileContext } from '../App';
import badge from './../assets/img/badge.png'

const RewardsManagementPage = () => {
    const [tabs, setTabs] = useState("1");
    const [showMore, setShowMore] = useState(false);
    const [badgesList, setBadgesList] = useState();
    const [loading, setLoading] = useState(false);
    const [teamDetails, setTeamDetails] = useState();
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    var userTeamId = profileDetail?.current_team?.id;
    console.log(userTeamId, "userTeamId")
    useEffect(() => {
        const FetchBadgesList = async () => {
            try {
                const response = await BadgesList(userTeamId);
                console.log("badges list", response.data.data)
                setBadgesList(response.data.data);
                setLoading(true)
            } catch (e) {
                console.log(e.response.data.message)
            }

        }
        FetchBadgesList();
    }, [userTeamId])


    useEffect(() => {
        const fetchSingleTeam = async () => {
            try {
                let response = await getSingleTeam(userTeamId);
                setTeamDetails(response.data.data);
                setLoading(true)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        fetchSingleTeam();
    }, [userTeamId])

    return (
        <div className='rewards-management-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />


                <Col xs={12} lg={12} xl={9} className="mt-4">
                    <Row>
                        <Col xs={12} lg={8} className="mb-4">
                            <div className="badge-list-container">
                                <h6 className='pb-3 border-bottom mb-4'>Recent Added Badges</h6>
                                <div className="position-relative pt-4">
                                    {loading ?
                                        (badgesList?.length > 0 ? <Tabs defaultActiveKey="" id="uncontrolled-tab-example" className="mb-3">
                                            {badgesList?.map((data, i) => {
                                                return <Tab eventKey="" key={i}
                                                    title={
                                                        <div className="cursor-div">
                                                            <div className="badge-img">
                                                                <img src={data?.image_url} onError={(e)=>e.target.src = "https://cdn-icons-png.flaticon.com/512/1435/1435715.png"}/>

                                                            </div>

                                                            <small className="badge-title">{data?.name}</small>
                                                        </div>
                                                    }>

                                                    <div className='mt-4 p-4 tab-deatail-box'>
                                                        <Row className="justify-content-center">
                                                            <Col xs={5} md={3} className="mb-3">
                                                                <div className="large-badge">
                                                                    <img src={data?.image_url} />
                                                                </div>

                                                            </Col>

                                                            <Col xs={12} md={9}>
                                                                <h6 className='title'>{data?.name}</h6>
                                                                <p>{data?.description}</p>

                                                                <Row className="mt-4">
                                                                    <Col xs={12} sm={12} className="mb-4">
                                                                        {data?.team_user_badge && data?.team_user_badge?.map((v, i) => {
                                                                            console.log(v.user.details.user_image_url)
                                                                            return (
                                                                                <div className='d-flex align-items-center '>
                                                                                    <div className="rounded-circle overflow-hidden badge-user-icon">
                                                                                        <img className='w-100' src={v?.user?.details?.user_image_url} onError={(e)=> e.target.src=Assets.ProfilePicture} alt="Avatar" />
                                                                                    </div>

                                                                                    <h6 className='p-0 m-0 ms-3'>{v?.user?.name}</h6>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                    </Col>
                                                                    {/*
                                                                <Col xs={12} sm={6} className="mb-4">
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={Assets.Avatar} alt="Avatar" />
                                                                        <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={12} sm={6} className="mb-4">
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={Assets.Avatar} alt="Avatar" />
                                                                        <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={12} sm={6} className="mb-4">
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={Assets.Avatar} alt="Avatar" />
                                                                        <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                                    </div>
                                                                </Col>
                                                                <div className={showMore ? "show-more-wrapper open" : "show-more-wrapper "}>
                                                                    <Row>
                                                                        <Col xs={12} sm={6} className="mb-4">
                                                                            <div className='d-flex align-items-center'>
                                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                                            </div>
                                                                        </Col>

                                                                        <Col xs={12} sm={6} className="mb-4">
                                                                            <div className='d-flex align-items-center'>
                                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                                            </div>
                                                                        </Col>

                                                                        <Col xs={12} sm={6} className="mb-4">
                                                                            <div className='d-flex align-items-center'>
                                                                                <img src={Assets.Avatar} alt="Avatar" />
                                                                                <h6 className='p-0 m-0 ms-3'>Smith Khel</h6>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div> */}
                                                                    {/* <Row className="justify-content-center">
                                                                    <Col xs={12}>
                                                                        <div>
                                                                            <div className="custom-dropdown text-yellow" to="#" onClick={() => setShowMore(!showMore)}>
                                                                                {showMore ? <p> Show Less</p> : <p>3 more </p>}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row> */}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </Tab>
                                            })}
                                        </Tabs> : <p>No badges to Show</p>)
                                        : <BlurLoader />}
                                </div>

                            </div>
                        </Col>

                        <Col xs={12} lg={4} className="mb-4">
                            <div className="left-container p-4 position-relative">
                                {loading ?
                                    <div className="brand-team-box">
                                        <div className="brand-img-wrapper">
                                            {teamDetails?.profile_picture === null ? <img className='brand-img w-100' src={Assets.GeneralPlaceholderImage} />
                                                : <img className='brand-img w-100' src={teamDetails?.profile_picture} onError={(e) => e.target.src = Assets.GeneralPlaceholderImage} />}
                                        </div>
                                        <div className='mt-4'>
                                            <p>{teamDetails?.name}</p>
                                            <div>
                                                <FireIcon />
                                                <h6 className='d-inline-block ms-2'>{teamDetails?.streak_score}</h6>
                                            </div>
                                        </div>
                                    </div> : <BlurLoader />}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default RewardsManagementPage
