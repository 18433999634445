import React, {useEffect} from 'react'
import { Button } from 'react-bootstrap'
import { success, error } from "../../constants/msg";
import { AcceptUser, RejectUser } from '../../services/teamsUser'

const RequestTeamCard = ({ index, name, email, phone, setJoinTeamList, joinTeamList, user_id, setCounter, counter, team_id, setuserAddModal, setSelectedUser, userAddModal, check,set_check}) => {

    const handleAccept = async () => {
        try {
            let response = await AcceptUser(user_id, team_id);
            setCounter(counter + 1);
            success(response.data.message)
            set_check(0)

        } catch (e) {
            console.log(e.response.data.message)
        }
    }
    const handleReject = async () => {
        try {
            let response = await RejectUser(user_id, team_id);
            setCounter(counter + 1);
            success(response.data.message)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }
    useEffect(()=>{
        if(check === 1) handleAccept()

    },[check])
    return (
        <div className='request-team-card'>
            <div className='d-flex align-items-center justify-content-between flex-wrap pb-3 mb-3 border-bottom'>
                <div className='mt-2 mb-2 pe-2'>
                    <h6 className='p-0 m-0'>{name}</h6>
                    <small>
                        {email} <br />
                        {phone}
                    </small>
                </div>

                <div className='pe-2'>
                    <Button className="btns btn-accept me-3" onClick={() => {
                        setuserAddModal(true)
                        setSelectedUser(user_id)
                    }}>ACCEPT</Button>
                    <Button className="btns btn-reject" onClick={() => handleReject()}>REJECT</Button>
                </div>
            </div>
        </div>
    )
}

export default RequestTeamCard
