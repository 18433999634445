import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';


//For Facebook Page

export const getFacebookPage = async (accesToken) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.facebookPages}?access_token=${accesToken}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}