import React, { useContext, useEffect, useState } from 'react'
import { Button, Carousel, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import NotificationCard from './NotificationCard'
import { NotificationPopupContext, NotificationLenghtContext, NotificationClickContext } from "../../App";
import { success, error } from "../../constants/msg";
import { getAllNotification } from '../../services/notification';
import Loader from '../../components/loader/Loader';


const NotificationPopup = () => {

    const [allNotifications, setAllNotification] = useState([]);
    const [handleNotificationPopup, setHandleNotificationPopup] = useContext(NotificationPopupContext);
    const [notificationLength, setNotificationLength] = useContext(NotificationLenghtContext)
    const [loading, setLoading] = useState(true);
    const [notificationClick, setNotificationClick] = useContext(NotificationClickContext);
    useEffect(() => {
        const getNotifications = async () => {
            try {
                let response = await getAllNotification();
                setLoading(false)
                setAllNotification(response.data.data);
                setNotificationLength(response.data.data.length)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        getNotifications();
    }, [notificationClick])


    return (
        <div>
            <Modal
                className='notification-modal'
                show={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Body>
                    <>
                        {loading ? <div className='d-flex mt-5 mb-5'><Loader /></div> :
                            <div className='notification-popup'>
                                <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                                    <h6 className='p-0 m-0'>Notifications <span>({allNotifications?.length})</span></h6>
                                    <div onClick={() => setHandleNotificationPopup(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                                </div>

                                <div className='notification-container'>
                                    {allNotifications.length > 0 ? allNotifications?.map((data, index) => {
                                        return (
                                            <NotificationCard id={data?.id} read_at={data?.read_at} name={data?.user} elapsed_time={data?.elapsed_time} clickAction={data?.data?.click_action} message={data?.data?.message} image={data?.image} key={index} />
                                        )
                                    }) : <h6 className='text-center'>No Notifications To Show</h6>

                                    }
                                </div>
                            </div>
                        }
                    </>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NotificationPopup