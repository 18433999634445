import React, { useState, useEffect, useContext } from 'react'
import { DoneIcon, PendingIcon, RejectIcon, LinkedinLogoSmall, TwitterLogo, YoutubeLogo, InstagramLogo, FacebookLogo, LinkedinLogo } from '../../constants/svgs'
import Assets from '../../constants/images'
import { Row, Col, Carousel, NavDropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { success, error } from "../../constants/msg";
import { DeletePost, AccepRejectPost } from "../../services/posts";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GetSinglePost } from "../../services/dashboard";
import BlurLoader from '../blurLoader/BlurLoader';
import { DeletePopupContext, NotificationClickContext } from "../../App"
import DeletePopup from '../popups/DeletePopup';

const SinglePostManagementCard = ({ handleClickEditPostPopup, counter, setCounter }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [hashValues, setHashValues] = useState([]);
    const [strWithoutHash, setStrWithouthash] = useState();
    const [singlePost, setSinglePost] = useState();
    const [resloadPost, setReloadPost] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const [notificationClick, setNotificationClick] = useContext(NotificationClickContext);
    const [deletePopup, setDeletePopup] = useContext(DeletePopupContext);

    const getSinglePostById = async () => {
        try {
            const response = await GetSinglePost(id);
            setSinglePost(response.data.data);
            setIsLoading(false)

        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    useEffect(() => {
        getSinglePostById();
    }, [resloadPost, notificationClick, counter]);

    const HASHTAG_FORMATTER = string => {
        return string.split(/((?:^|\s)(?:#[a-z\d-]+))/gi).filter(Boolean).map((v, i) => {
            if (v.includes('#')) {
                return <span key={i} style={{ color: '#1DA1F2', padding: '0 2px' }}>{v}</span>
            } else {
                return <p key={i}>{v}</p>
            }
        })
    };

    var post_id = singlePost?.id;
    var postStatus = singlePost?.status;

    const deletePost = async () => {
        try {
            const response = await DeletePost(singlePost.id);
            success(response.data.message)
            setDeletePopup(false)
            setTimeout(() => {
                navigate("/post-management")
            }, 1500)

        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const AprrovedPost = async () => {
        try {
            const resp = await AccepRejectPost(post_id, "approved");
            setIsLoading(true)
            await getSinglePostById()
            setIsLoading(false)
            success(resp.data.message)

        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const RejectPost = async () => {
        try {
            const resp = await AccepRejectPost(post_id, "reject");
            success(resp.data.message)

        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    return (
        <Col xs={12} sm={12} md={8} lg={6} className="mb-4">
            <ToastContainer />
            <DeletePopup text="Are you sure you want to delete post" handleDelete={deletePost} />
            {
                <div className='post-management-card single-post-management-card p-3 pt-2'>
                    {isLoading ? <BlurLoader /> : ""}
                    <div className='d-flex justify-content-end'>
                        <NavDropdown title={<BsThreeDots className='icon-dots' />} id="basic-nav-dropdown">

                            <NavDropdown.Item>
                                <div className='operation-box'>
                                    <div className='w-100'>
                                        <div className="hover-div border-bottom">
                                            <button className='w-100 text-start' onClick={() => handleClickEditPostPopup()}>Edit Post</button>
                                        </div>

                                        <div className="hover-div border-bottom">
                                            <button className='w-100 text-start' onClick={() => AprrovedPost()}>Approve Post</button>
                                        </div>

                                        <div className="hover-div border-bottom">
                                            <button className='w-100 text-start' onClick={() => RejectPost()}>Reject Post</button>
                                        </div>

                                        <div className="hover-div">
                                            <button className="w-100 text-start" onClick={() => setDeletePopup(true)}>Delete Post</button>
                                        </div>
                                    </div>
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>

                    <div className='mb-3'>
                        <div className='d-flex align-items-center'>
                            {
                                postStatus === "approved" ?
                                    <DoneIcon /> :
                                    postStatus === "reject" ?
                                        <RejectIcon /> :
                                        <PendingIcon />
                            }

                            <small className='ms-3'>{singlePost?.schedule_time}</small>
                        </div>
                    </div>



                    <div className='avatar-container d-flex align-items-center '>
                        <img src={singlePost?.user?.details?.user_image_url} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} className='rounded-circle' />
                        <div className='d-flex flex-column ms-2'>
                            <span>{singlePost?.user?.name}</span>
                            <small>{singlePost?.elapsed_time}</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <p className='d-flex flex-wrap'>{singlePost?.text && HASHTAG_FORMATTER(singlePost?.text)}</p>
                        <div className='footer d-flex flex-xl-row flex-column align-items-xl-center justify-content-between'>

                            <div className='mt-3 mt-xl-0 icon-div d-flex align-items-center'>
                                {singlePost?.platforms.length > 0 && singlePost?.platforms.map((d, i) => {
                                    if (d.id === 1) {
                                        return <div className='ms-xl-2'><FacebookLogo /></div>
                                    }
                                    else if (d.id === 2) {
                                        return <div className='ms-xl-2 mx-2 mx-xl-0'><TwitterLogo /></div>
                                    }
                                    else if (d.id === 3) {
                                        return <div className='ms-xl-2'><InstagramLogo /></div>
                                    }
                                    else if (d.id === 4) {
                                        return <div className='ms-xl-2'><LinkedinLogo /></div>
                                    }
                                    else {
                                        return <div className='ms-xl-2'><YoutubeLogo /></div>
                                    }

                                })}
                            </div>
                        </div>

                        <div className='mt-4'>
                            {singlePost?.media?.length > 0 ? <Carousel>
                                {singlePost?.media?.length > 0 && singlePost.media.map((d, i) => {
                                    return (<Carousel.Item key={i}>
                                        {d?.type === "image" ? <img onError={(e) => e.target.src = Assets.GeneralPlaceholderImage} className="w-100" src={d.url} alt="First slide" /> :
                                            <video width="100%"
                                                height="100%" controls={true} >
                                                <source src={d.url} type="video/mp4" />
                                            </video>}

                                    </Carousel.Item>
                                    )
                                })}
                            </Carousel> : <p>This post contains no media.</p>}

                        </div>
                    </div>
                </div>
            }

        </Col >
    )
}

export default SinglePostManagementCard
