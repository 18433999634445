import React,{useContext} from 'react'
import { Col, Row, Form, Button, InputGroup } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form";
import { CHARACTER_ARRAY_FOR_NUMBER_INPUT, SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { GetMyProfile } from '../../services/profile';
import { UserContext } from '../../App';
import { SetAuthUserLocalStorage } from '../../services/auth';

const BankCardForm = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserContext)
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async(data) => {
        const resp = await GetMyProfile()
        setUserData(resp.data.data)
        SetAuthUserLocalStorage(resp.data.data)
        success("Payment Successfull")
        setTimeout(() => {
            navigate("/invite-team")
        }, 1500)
    }

    return (
        <div className="bank-card-form">
            <ToastContainer />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} sm={6} className="mt-4">
                        <InputGroup>
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" && e.preventDefault() || Number(e.key)) && e.preventDefault()}
                                name="cardHolderName"
                                type="text"
                                placeholder="Card Holder Name"
                                {...register("cardHolderName",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.NAME_REQUIRED
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.cardHolderName && <small className='text-red'>{errors.cardHolderName.message}</small>}

                    </Col>

                    <Col xs={12} sm={6} className="mt-4">
                        <InputGroup>
                            <Form.Control
                                onKeyDown={(e) => CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                name="cardNumber"
                                type="number"
                                placeholder="Card Number"
                                {...register("cardNumber",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CARD_NUMBER_REQUIRED
                                        },
                                        minLength: {
                                            value: VALIDATIONS.CARD_NUMBER,
                                            message: VALIDATIONS_TEXT.CARD_NUMBER_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.CARD_NUMBER,
                                            message: VALIDATIONS_TEXT.CARD_NUMBER_MAX
                                        },
                                        pattern: {
                                            value: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
                                            message: VALIDATIONS_TEXT.INVALID_FORMAT
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.cardNumber && <small className='text-red'>{errors.cardNumber.message}</small>}

                    </Col>

                    <Col xs={12} sm={6} className="mt-4">
                        <InputGroup>
                            <Form.Control
                                onKeyDown={(e) => CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                name="cvv"
                                type="number"
                                placeholder="CVV"
                                {...register("cvv",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.CVV_REQUIRED
                                        },
                                        minLength: {
                                            value: VALIDATIONS.CVV,
                                            message: VALIDATIONS_TEXT.CVV_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.CVV,
                                            message: VALIDATIONS_TEXT.CVV_MAX
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.cvv && <small className='text-red'>{errors.cvv.message}</small>}

                    </Col>

                    <Col xs={12} sm={6} className="mt-4">
                        <Controller
                            rules={{ required: true }}
                            name="expiryDate"
                            control={control}
                            defaultValue={null}
                            render={
                                ({ field }) => (
                                    <DatePicker
                                        dateFormat="MM / yy"
                                        showMonthYearPicker
                                        minDate={new Date()}
                                        className="w-100 date-picker"
                                        placeholderText="Expiry date"
                                        onChange={(e) => field.onChange(e)}
                                        selected={field.value}
                                    />
                                )
                            }
                        />
                        {errors.expiryDate && <small className='text-red'>Expiry Date is required</small>}
                    </Col>

                    <Col xs={12} sm={6} className="mt-5">
                        <Button className="w-100 btn-pay-now" type="submit">PAY NOW</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default BankCardForm