import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';


//Create Post
export const CreatePost = async (params) => {
    console.log(params, 'params')
    let { template_id, user_id, team_id, text, status, location, latitude, longitude, schedule_time, user_ids, platform_ids, medias } = params;
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.dashboard_post, { template_id, user_id, team_id, text, status, location, latitude, longitude, schedule_time, user_ids, platform_ids, medias }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp;
}


// Delete post
export const DeletePost = async (post_ID) => {
    const resp = await axios.delete(`${constant.BASE_URL + apiEndPoints.dashboard_post}/${post_ID}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}


// Delete post media
export const DeletePostMedia = async (mediaID) => {
    const resp = await axios.delete(`${constant.BASE_URL + apiEndPoints.post_media}/${mediaID}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}


//All Post
export const AllPost = async (user_ID, curr_team, page) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?sortedBy=desc&orderBy=id&paginate=10&page=${page}&user_id=${user_ID}&team_id=${curr_team}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//get single post
export const GetSinglePost = async (post_id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}/${post_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


//Rejected Post
export const RejectPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&paginate=25&user_id=${user_ID}&sortedBy=asc&status=rejected`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Approved Post
export const ApprovedPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&user_id=${user_ID}&sortedBy=asc&status=approved`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Rejected Post
export const RejectedPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&paginate=25&user_id=${user_ID}&sortedBy=asc&status=rejected`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Deleted Post
export const DeletedPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&paginate=25&user_id=${user_ID}&sortedBy=asc&status=deleted`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


// Edit post
export const EditPost = async (post_id, body = {}) => {
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.dashboard_post}/${post_id}`, body, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp
}


// Filter Post
export const FilterPost = async (params, CurrTeamid) => {
    // let to = ""
    // let from = ""

    // if (params.timeDuration) {
    //     to = params.timeDuration.to
    //     from = params.timeDuration.from
    //     delete params.timeDuration
    //     params.to = to
    //     params.from = from
    // }

    console.log('params--------------', params)

    // if (!params.user_id) {
    //     params.user_id = id
    // }


    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?media_type=${params.media_type}&platform_ids=${params.platform_ids}&to=${params.timeDuration?.to}&from=${params.timeDuration?.from}&published_post=${params.published_post}&status=${params.status}&user_id=${params.user_id}&team_id=${CurrTeamid}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp




}

//Accept/Rejected the post

export const AccepRejectPost = async (post_id, status) => {
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.approved_post, { post_id, status }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp;
}



//My submisstion



export const GetPostUserID = async (user_Id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?paginate=6&page=1&user_id=${user_Id}&sortedBy=desc`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}
