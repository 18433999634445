import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './Navigation';
import Assets from './constants/images';
import { GetAuthUserLocalStorage } from './services/auth';
import Loader from './components/loader/Loader';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_2kwes8cuUU2RbXg6xmnsrnqs00j4rEjAIa");
const ChoosePlanData = createContext();
const PostManagementData = createContext();
const PendingPostData = createContext();
const SelectedPlanData = createContext();

// popup context
const CreatePostPopupContext = createContext();
const ProfilePopupContext = createContext();
const FilterPopupContext = createContext();
const PendingSubmissionPopupContext = createContext();
const FilterDataContext = createContext();
const CounterContext = createContext();
const SwitchTeamContext = createContext();
const AddTeamContext = createContext();
const ConfirmationPopupContext = createContext();
const AddCardPopupContext = createContext();
const CardConfirmationPopupContext = createContext();
const DeleteSchedulePostPopup = createContext();
const VerificationPopupContext = createContext();
const DeletePopupContext = createContext();

//Notifications
const NotificationPopupContext = createContext();
const NotificationLenghtContext = createContext();
const NotificationClickContext = createContext();
//User
const UserContext = createContext();

//Current Team Content
const CurrentTeamContext = createContext();

//Profile Detail
const ProfileContext = createContext();

//Tutorial Screen
const TutorialContext = createContext();

const App = () => {

    const [choosePlan, setChoosePlan] = useState([
        {
            price: "Individual:  $70/mo",
            description: "For the solopreneur who’s fed up with the time and effort required to keep up on social media.",
            title: "1 Manager (Web & App) Collaborators not included",
            list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️View Reporting & Post Engagement"],
            id: "1"
        },
        {
            price: "Team:  $250/mo",
            description: "Collaborators can submit content via the iOS app. Turn off auto-post to review submissions before being published.",
            title: "1 Manager  (Web & App) 10 Collaborators (App)",
            list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️Team Leaderboard", "View Reporting & Post Engagement"],
            id: "2"
        },
        {
            price: "Marketing Managers:  $  /mo",
            description: "Collaborators can submit content via the iOS app. Turn off auto-post to review submissions before being published.",
            title: "5 Manager  (Web & App) 10 Collaborators (App)",
            list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️Team Leaderboard", "️View Reporting & Post Engagement"],
            id: "3"
        },
    ])

    const [postManagement, setPostManagement] = useState([]);
    const [pendingPost, setPendingPost] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(
        {
            price: "Individual:  $70/mo",
            description: "For the solopreneur who’s fed up with the time and effort required to keep up on social media.",
            title: "1 Manager (Web & App) Collaborators not included",
            list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️View Reporting & Post Engagement"]
        }
    )
    const [userData, setUserData] = useState(GetAuthUserLocalStorage());
    const [handleCreatePostPopup, setHandleCreatePostPopup] = useState(false);
    const [handleNotificationPopup, setHandleNotificationPopup] = useState(false);
    const [notificationLength, setNotificationLength] = useState();
    const [handleProfilePopup, setHandleProfilePopup] = useState(false);
    const [handleFilterPopup, setHandleFilterPopup] = useState(false);
    const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useState(false);
    const [switchUserPopup, setSwitchUserPopup] = useState(false);
    const [addTeamPopup, setAddTeamPopup] = useState(false);
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [cardConfirmationPopup, setCardConfirmationPopup] = useState(false);
    const [addCardPopup, setAddCardPopup] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [counter, setCounter] = useState(0);
    const [delSchedulePostPopup, setDelSchedulePostPopup] = useState(false)
    const [currentTeam, setCurrentTeam] = useState(userData?.current_team?.id);
    const [profileDetail, setProfileDetail] = useState(userData);
    const [notificationClick, setNotificationClick] = useState(0);
    const [verificationPopup, setVerificationPopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false)
    const [tutorialScreen, setTutorialScreen] = useState(1);
    return (
        <TutorialContext.Provider value={[tutorialScreen, setTutorialScreen]}>
            <ChoosePlanData.Provider value={[choosePlan, setChoosePlan]}>
                <PostManagementData.Provider value={[postManagement, setPostManagement]}>
                    <PendingPostData.Provider value={[pendingPost, setPendingPost]}>
                        <CreatePostPopupContext.Provider value={[handleCreatePostPopup, setHandleCreatePostPopup]}>
                            <NotificationLenghtContext.Provider value={[notificationLength, setNotificationLength]}>
                                <NotificationPopupContext.Provider
                                    value={[handleNotificationPopup, setHandleNotificationPopup]}>
                                    <ProfilePopupContext.Provider value={[handleProfilePopup, setHandleProfilePopup]}>
                                        <FilterPopupContext.Provider value={[handleFilterPopup, setHandleFilterPopup]}>
                                            <SelectedPlanData.Provider value={[selectedPlan, setSelectedPlan]}>
                                                <PendingSubmissionPopupContext.Provider
                                                    value={[handlePendingSubmissionPopup, setHandlePendingSubmissionPopup]}>
                                                    <UserContext.Provider value={[userData, setUserData]}>
                                                        <FilterDataContext.Provider value={[filterData, setFilterData]}>
                                                            <CounterContext.Provider value={[counter, setCounter]}>
                                                                <SwitchTeamContext.Provider
                                                                    value={[switchUserPopup, setSwitchUserPopup]}>
                                                                    <CurrentTeamContext.Provider
                                                                        value={[currentTeam, setCurrentTeam]}>
                                                                        <AddTeamContext.Provider
                                                                            value={[addTeamPopup, setAddTeamPopup]}>
                                                                            <ConfirmationPopupContext.Provider
                                                                                value={[confirmationPopup, setConfirmationPopup]}>
                                                                                <AddCardPopupContext.Provider
                                                                                    value={[addCardPopup, setAddCardPopup]}>
                                                                                    <CardConfirmationPopupContext.Provider
                                                                                        value={[cardConfirmationPopup, setCardConfirmationPopup]}>
                                                                                        <DeleteSchedulePostPopup.Provider value={[delSchedulePostPopup, setDelSchedulePostPopup]}>
                                                                                            <ProfileContext.Provider value={[profileDetail, setProfileDetail]}>
                                                                                                <NotificationClickContext.Provider value={[notificationClick, setNotificationClick]}>
                                                                                                    <VerificationPopupContext.Provider value={[verificationPopup, setVerificationPopup]}>
                                                                                                        <DeletePopupContext.Provider value={[deletePopup, setDeletePopup]}>
                                                                                                            <BrowserRouter>
                                                                                                                <Elements stripe={stripePromise}>
                                                                                                                    <Navigation />
                                                                                                                </Elements>
                                                                                                            </BrowserRouter>
                                                                                                        </DeletePopupContext.Provider>
                                                                                                    </VerificationPopupContext.Provider>
                                                                                                </NotificationClickContext.Provider>
                                                                                            </ProfileContext.Provider>
                                                                                        </DeleteSchedulePostPopup.Provider>
                                                                                    </CardConfirmationPopupContext.Provider>
                                                                                </AddCardPopupContext.Provider>
                                                                            </ConfirmationPopupContext.Provider>
                                                                        </AddTeamContext.Provider>
                                                                    </CurrentTeamContext.Provider>
                                                                </SwitchTeamContext.Provider>
                                                            </CounterContext.Provider>
                                                        </FilterDataContext.Provider>
                                                    </UserContext.Provider>
                                                </PendingSubmissionPopupContext.Provider>
                                            </SelectedPlanData.Provider>
                                        </FilterPopupContext.Provider>
                                    </ProfilePopupContext.Provider>
                                </NotificationPopupContext.Provider>
                            </NotificationLenghtContext.Provider>
                        </CreatePostPopupContext.Provider>
                    </PendingPostData.Provider>
                </PostManagementData.Provider>
            </ChoosePlanData.Provider >
        </TutorialContext.Provider>
    )
}

export default App
export {
    DeletePopupContext,
    VerificationPopupContext,
    NotificationClickContext,
    ProfileContext,
    DeleteSchedulePostPopup,
    CardConfirmationPopupContext,
    AddCardPopupContext,
    ConfirmationPopupContext,
    AddTeamContext,
    CurrentTeamContext,
    CounterContext,
    FilterDataContext,
    UserContext,
    ChoosePlanData,
    PostManagementData,
    PendingPostData,
    SelectedPlanData,
    CreatePostPopupContext,
    NotificationLenghtContext,
    NotificationPopupContext,
    ProfilePopupContext,
    FilterPopupContext,
    PendingSubmissionPopupContext,
    SwitchTeamContext,
    TutorialContext
}
