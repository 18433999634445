import axios from 'axios';
import {constant, apiEndPoints} from '../utlis/constants';
import {GetTokenLocalStorage} from './auth';

export const GetAllTemplates = async () => {
    const resp = await axios.get(`${constant.BASE_URL}${apiEndPoints.templates}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    );
    return resp
}

export const GetAllTemplatesById = async (id, team_id = null) => {
    const resp = await axios.get(`${constant.BASE_URL}${apiEndPoints.templates}/${id}?team_id=${team_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    );
    return resp
}


export const StoreTemplateDays = async (name, template_id, day, team_id = null) => {
    const resp = await axios.post(`${constant.BASE_URL}${apiEndPoints.template_days}`, {
        name,
        template_id,
        day,
        team_id
    }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp;
}


//Template Day Slots

export const UpdateTemplateDaySlots = async (time, day_id) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.template_day_slots}`, {time, day_id}, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}


export const DeleteTemplateDays = async (id) => {
    const resp = await axios.delete(`${constant.BASE_URL + apiEndPoints.template_days}/${id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}

//Update team template
export const UpdateTeamTemplate = async (team_id, template_id) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.team_template}`, {team_id, template_id}, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}

//Team Days Slots
export const TeamDaysSlots = async (team_id, template_id) => {
    const resp = await axios.get(`${constant.BASE_URL}${apiEndPoints.team_day_slot}?team_id=${team_id}&template_id=${template_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    );
    return resp
}

//Book Slots
export const BookSlots = async (team_id, template_id, from, to) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.book_slots}?team_id=${team_id}&template_id=${template_id}&from=${from}&to=${to}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}

//Book Slots for single day
export const BookSlotsSingle = async (team_id, date) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.book_slots}?team_id=${team_id}&date=${date}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}

//Post Slot Status
export const PostSlotStatus = async (team_id, date, time) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.post_slots_status}?team_id=${team_id}&date=${date}&time=${time}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}

//Post Status Approve Or Reject
export const PostSlotApprovedOrReject = async (post_id, status, team_id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.post_slots_status}?post_id=${post_id}&status=${status}&team_id=${team_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }
    )
    return resp
}