import React, { useEffect, useRef, useContext } from 'react'
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap'
import { useState } from 'react';
import { FacebookIcon, FileDownloadIcon, InstagramIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from '../constants/svgs'
import { DateRangePicker } from 'react-date-range';
import { UserContext, ProfileContext } from '../App';
import { error } from "../constants/msg";
import moment from 'moment';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css';
import GenericPdfDownloader from "../components/genericPdfDownloader/GenericPdfDownloader"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';
import { Bar, Chart, Line } from 'react-chartjs-2';
import faker from 'faker';
import Assets from '../constants/images'
import SocialStatsCard from '../components/dashboard/SocialStatsCard';
import { format } from 'date-fns';
import { AllPost, ApprovedPost, RejectedPost, DeletedPost } from "../services/posts";
import { GetAllStatistics, Getstatistics } from "../services/dashboard";
import { GetSocialPlatformList } from "../services/socialPlatformList";
import BlurLoader from "../components/blurLoader/BlurLoader"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                display: false
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
};

const labelsForPlatform = ['Facebook', 'Twitter', 'Instagram', 'Linkedin', 'Youtube'];
const labelsForMembers = ['Anthony', 'Emily', 'patricia', 'David', 'Chalres'];
const labelsForWeek = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
const labelsForMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const platformData = {
    labels: labelsForPlatform,
    datasets: [
        {
            label: '',
            data: labelsForPlatform.map(() => faker.datatype.number({ min: 0, max: 1500 })),
        },
    ],
};

export const memberData = {
    labels: labelsForMembers,
    datasets: [
        {
            label: '',
            data: labelsForMembers.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#2b2829',
        },
        {
            label: '',
            data: labelsForMembers.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#e09944',
        },
    ],
};

export const weekData = {
    labels: labelsForWeek,
    datasets: [
        {
            label: '',
            data: labelsForWeek.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: '2b2829',
            backgroundColor: '#2b2829',
        },
    ],
};

export const monthData = {
    labels: labelsForMonth,
    datasets: [
        {
            label: '',
            data: labelsForMonth.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: '2b2829',
            backgroundColor: '#2b2829',
        },
    ],
};

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const colorStart = "#FEC053"
    const colorMid = "#F2203E"
    const colorEnd = "#B729A8"

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.5, colorMid);
    gradient.addColorStop(1, colorEnd);

    return gradient;
}






const ReportPage = () => {
    const chartRef = useRef(null);
    const [filter, setFilter] = useState("0");
    const [handleOpen, setHandleOpen] = useState(false);
    const [allPostsCount, setAllPostsCount] = useState([]);
    const [approvedPostCount, setApprovedPostCount] = useState([]);
    const [rejectedPostCount, setRejectedPostCount] = useState([]);
    const [deletedPostCount, setDeletedPostCount] = useState([]);
    //Loader
    const [loading, setLoading] = useState(true)

    // Login User Id's
    const [userData, setUserData] = useContext(UserContext);
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    var user_ID = profileDetail?.id
    var userTeamId = profileDetail?.current_team?.id

    // ---------------------------------------- Social Media Graph Data -------------------------------------
    const [statsData, setStatsData] = useState([]);
    //platform Data
    const [platformName, setPlatformName] = useState([]);
    const [platformValue, setPlatformValue] = useState([]);
    //Members Data
    const [membersName, setMembersName] = useState([]);
    const [membersValue, setMembersValue] = useState([]);
    //Weeks Data
    const [weeksName, setWeeksName] = useState([]);
    const [weeksValue, setWeeksValue] = useState([]);
    //Month Data
    const [monthName, setmonthName] = useState([]);
    const [monthValue, setmonthValue] = useState([]);
    //Social Media List
    const [socialList, setSocialList] = useState([]);


    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date((new Date()).setDate((new Date()).getDate() - 30)),
        endDate: new Date(),
        key: 'selection',
    })

    const handleSelect = (ranges) => {
        setSelectionRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
        })
    }

    const showDate = () => {
        // let startDate = selectionRange.startDate.toString().slice(3, 7) + "," + selectionRange.startDate.toString().slice(10, 15)
        // let endDate = selectionRange.endDate.toString().slice(3, 7) + "," + selectionRange.endDate.toString().slice(10, 15)
        let startDate = selectionRange.startDate.toString().slice(4, 10)
        let endDate = selectionRange.endDate.toString().slice(4, 10)
        let date = startDate + "-" + endDate
        return date
    }


    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }
        else {
            const chartData = {
                ...platformData,
                backgroundColor: [
                    '#4776b9',
                    '#1da1f2',
                    createGradient(chart.ctx, chart.chartArea),
                    '#3d83d9',
                    '#d40c18',
                ]
                // datasets: platformData.datasets.map(dataset => ({
                //     ...dataset,
                //     backgroundColor: [
                //         '#4776b9',
                //         '#1da1f2',
                //         createGradient(chart.ctx, chart.chartArea),
                //         '#3d83d9',
                //         '#d40c18',
                //     ],
                // })),
            };

            setChartData(chartData);
        }

    }, [userTeamId]);

    //All post count
    useEffect(() => {
        const getAllPost = async () => {
            try {
                const response = await AllPost(user_ID);
                setAllPostsCount(response?.data?.data)
            } catch (e) {
                console.log(e.response?.data?.message)
            }
        }
        const getApprovedPosts = async () => {
            try {
                const response = await ApprovedPost(user_ID);
                setApprovedPostCount(response?.data?.data)
            }
            catch (e) {
                console.log(e.response?.data?.message);
            }
        }
        const getRejectedPosts = async () => {
            try {
                const response = await RejectedPost(user_ID);
                setRejectedPostCount(response?.data?.data)
            }
            catch (e) {
                console.log(e.response?.data?.message);
            }
        }
        const getDeletedPosts = async () => {
            try {
                const response = await DeletedPost(user_ID);
                setDeletedPostCount(response?.data?.data)
            }
            catch (e) {
                console.log(e.response?.data?.message);
            }
        }
        getAllPost();
        getApprovedPosts();
        getRejectedPosts();
        getDeletedPosts();
    }, [userTeamId]);


    //Use Effect for Statistics
    useEffect(() => {
        // Get Statistics of user posts
        const getStatistics = async () => {
            let start_date = moment(selectionRange.startDate).format('YYYY-MM-DD');
            let end_date = moment(selectionRange.endDate).format('YYYY-MM-DD');
            try {
                const res = await Getstatistics(start_date, end_date, profileDetail.current_team.id);
                const respStatsdata = res.data.data
                setStatsData(respStatsdata);
                setLoading(false)
                //Social Platforms Data
                setPlatformName(Object.keys(respStatsdata?.platform));
                setPlatformValue(Object.values(respStatsdata?.platform));
                //Members Data
                setMembersName(Object.keys(respStatsdata?.members));
                setMembersValue(Object.values(respStatsdata?.members));
                //Weeks Data
                setWeeksName(Object.keys(respStatsdata?.weeks));
                setWeeksValue(Object.values(respStatsdata?.weeks));
                //Year Data
                setmonthName(Object.keys(respStatsdata?.months));
                setmonthValue(Object.values(respStatsdata?.months));
            }
            catch (e) {
                console.log(e.response.data.message)
            }
        }
        getStatistics();
    }, [selectionRange,userTeamId])

    //Use Effect for Social Platform Lsit
    useEffect(() => {
        const fetchSocialPlatformList = async () => {
            try {
                const resp = await GetSocialPlatformList(userTeamId);
                const socialResp = resp?.data?.data
                setSocialList(socialResp);
                setLoading(false);
            }
            catch (e) {
                console.log(e.response.data.message)
            }
        }
        fetchSocialPlatformList()
    }, [userTeamId])

    //Social Platform data
    const platformData = {
        labels: platformName,
        datasets: [
            {
                label: '',
                data: platformValue,
                backgroundColor: [
                    '#4776b9',
                    '#1da1f2',
                    '#fbad50',
                    '#3d83d9',
                    '#d40c18',
                ],

            },
        ],


    };
    //Filter By Member data
    const memberData = {
        labels: membersName,
        datasets: [
            {
                label: '',
                data: membersValue,
                backgroundColor: '#e09944',
            },
        ],
    };
    //Filter By Week Data
    const weekData = {
        labels: weeksName,
        datasets: [
            {
                label: '',
                data: weeksValue,
                borderColor: '2b2829',
                backgroundColor: '#2b2829',
            },
        ],
    };

    //Filter By Months Data
    const monthData = {
        labels: monthName,
        datasets: [
            {
                label: '',
                data: monthValue,
                borderColor: '2b2829',
                backgroundColor: '#2b2829',
            },
        ],
    };


    return (
        <div className='report-page pb-3' >
            <div className='whole-report' id="report-page">
                <Row>
                    <Col xs={12} className="d-block d-md-none mt-4">
                        <h5 className='text-yellow'>REPORT</h5>
                    </Col>

                    <Col xs={12} xl={8} className="mt-4">
                        <div className='date-range-container d-flex flex-column flex-md-row align-items-md-center justify-content-md-end' >
                            {/* <Form.Control onClick={() => setHandleOpen(!handleOpen)} className="mb-2 mb-md-0 me-0 me-md-2" value={showDate()} />
                        {
                            handleOpen &&
                            <DateRangePicker
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                            />
                        } */}

                            <Dropdown className="calendar-dropdown mb-2 mb-md-0 me-0 me-md-2">
                                <Dropdown.Toggle id="dropdown-basic">
                                    {showDate()}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <DateRangePicker
                                        ranges={[selectionRange]}
                                        onChange={handleSelect}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    {
                                        filter === "0" ?
                                            "Filter By Platform" :
                                            filter === "1" ?
                                                "Filter By Members" :
                                                filter === "2" ?
                                                    "Filter By Week" :
                                                    " Filter By Month"
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item className="border-bottom py-2" href="#" onClick={() => setFilter("0")}>Filter By Platform</Dropdown.Item>
                                    <Dropdown.Item className="border-bottom py-2" href="#" onClick={() => setFilter("1")}>Filter By Members</Dropdown.Item>
                                    <Dropdown.Item className="border-bottom py-2" href="#" onClick={() => setFilter("2")}>Filter By Week</Dropdown.Item>
                                    <Dropdown.Item className="py-2" href="#" onClick={() => setFilter("3")}>Filter By Month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col xs={12} xl={8} className="mt-4">
                        <div className="chart-container p-4">
                            {
                                handleOpen &&
                                <div onClick={() => setHandleOpen(false)} className="overlay" style={{ backgroundColor: "transparent", position: "absolute", width: "100%", height: "100%", zIndex: "1" }}></div>
                            }
                            <Row>
                                <Col xs={12} xl={9} className="mb-4">
                                    <div>
                                        {
                                            filter === '0' ?
                                                platformData?.datasets[0]?.data?.length == 0 ? <div className='graph-error-msg text-center'>There Is No Data To Display <br />For The Selected Month Range. </div> : <Chart className='mt-4' options={options} ref={chartRef} type='bar' data={platformData} /> :
                                                filter === '1' ?
                                                    memberData?.datasets[0]?.data?.length == 0 ? <div className='graph-error-msg text-center'>There Is No Data To Display <br />For The Selected Month Range. </div> : <Bar className='mt-4' options={options} data={memberData} /> :
                                                    filter === '2' ?
                                                        weekData?.datasets[0]?.data?.length == 0 ? <div className='graph-error-msg text-center'>There Is No Data To Display <br />For The Selected Month Range. </div> : <Line className='mt-4' options={options} data={weekData} /> :
                                                        monthData?.datasets[0]?.data?.length == 0 ? <div className='graph-error-msg text-center'>There Is No Data To Display <br />For The Selected Month Range. </div> : <Line className='mt-4' options={options} data={monthData} />
                                        }
                                    </div>
                                </Col>

                                <Col xs={12} xl={3}>
                                    <div className='info-container d-sm-flex d-xl-block justify-content-sm-between text-center p-4'>
                                        <div className="box border-bottom mb-3 pb-3 px-sm-2 px-xl-0 ">
                                            <p className="p-0 m-0">{allPostsCount?.length}</p>
                                            <h6 className="p-0 m-0">Total Posts</h6>
                                        </div>

                                        <div className="box border-bottom mb-3 pb-3 px-sm-2 px-xl-0">
                                            <p className="p-0 m-0">{approvedPostCount?.length}</p>
                                            <h6 className="p-0 m-0">Approved Posts</h6>
                                        </div>

                                        <div className="box border-bottom mb-3 pb-3 px-sm-2 px-xl-0">
                                            <p className="p-0 m-0">{rejectedPostCount?.length}</p>
                                            <h6 className="p-0 m-0">Rejected Posts</h6>
                                        </div>

                                        <div className="border-bottom mb-3 pb-3 px-sm-2 px-xl-0">
                                            <p className="p-0 m-0">{deletedPostCount?.length}</p>
                                            <h6 className="p-0 m-0">Deleted Posts</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12} xl={4} className="mt-4">
                        <div className='social-stats-container p-4 position-relative'>

                            <h6>Social Stats</h6>
                            <div className='d-flex align-items-center justify-content-between pb-2 mb-4 mt-3 border-bottom'>
                                <small>Source</small>
                                <small>Posts</small>
                            </div>
                            <div className="social-stats-list ">
                                {loading ? <BlurLoader /> :
                                    socialList?.map((data, i) => {
                                        return <SocialStatsCard key={i} iconSrc={data?.image} id="facebook" title={data?.name} count={data?.post_platform_count} />
                                    })
                                }
                            </div>
                        </div>
                    </Col>


                </Row>
            </div>
            <GenericPdfDownloader
                downloadFileName="report"
                rootElementId="report-page"
            />
        </div>
    )
}

export default ReportPage
