import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';


//Badges List
export const BadgesList = async (team_id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.badges_list}?team_id=${team_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}

// Team User Badges
export const TeamUserBadges = async (team_id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user_badges}/?orderBy=id&sortedBy=asc&team_id=${team_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}
