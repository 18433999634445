import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';



//Get All Pending Post
export const GetAllPendingPost = async (user_ID, currTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?status=pending&user_id=${user_ID}&team_id=${currTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}


//pending post 
export const PendingPost = async (user_ID, currTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?sortedBy=desc&paginate=6&page=1&user_id=${user_ID}&status=pending&team_id=${currTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}

//get upcoming scheduled Post 

export const UpcomingPost = async (user_ID, currTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&sortedBy=desc&paginate=6&page=1&user_id=${user_ID}&status=approved&team_id=${currTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//get single post by ID

export const GetSinglePost = async (id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Dashboard Statistics 

export const Getstatistics = async (start_date, end_date, currTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.statistics}?start_date=${start_date}&end_date=${end_date}&team_id=${currTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


export const fetchStasByTeamId = async (currTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.statistics}?team_id=${currTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


