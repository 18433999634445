import React from 'react';
import Loader from '../loader/Loader'

const BlurLoader = () => {
    return (
        <div className='blur-loader '>
            <span className='position-relative swap d-flex justify-content-center align-items-center'></span>
        </div>
    )
}

export default BlurLoader