import React, {useContext, useEffect, useState} from 'react'
import {Button, Carousel, Form, InputGroup, Modal} from 'react-bootstrap'
import {FaTimes} from "react-icons/fa"
import Assets from '../../constants/images'
import {
    AddUserIcon,
    DoneIconSquare,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    LocationIcon,
    RejectIconSquare,
    TwitterIcon,
    YoutubeIcon,
    YoutubeIconWithoutBg
} from '../../constants/svgs'
import PendingSubmissionCard from './PendingSubmissionCard'
import {PendingSubmissionPopupContext, CreatePostPopupContext} from '../../App';
import {PostSlotStatus} from "../../services/templates";
import {error} from "../../constants/msg";

const PendingSubmissionPopup = ({team_id, selectedDate, selectedTime, reset, setReset}) => {
    const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useContext(PendingSubmissionPopupContext)
    const [handleCreatePostPopup, setHandleCreatePostPopup] = useContext(CreatePostPopupContext);

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    //const [reset, setReset] = useState(false)

    useEffect(() => {
        postSlotStatus()
    }, [reset])

    const postSlotStatus = async () => {
        try {
            let response = await PostSlotStatus(team_id, selectedDate, selectedTime)
            setData(response.data.data);
            setIsLoading(false)
        } catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <div>
            <Modal
                className='pending-submission-modal'
                show={handlePendingSubmissionPopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div
                        className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Pending Submissions</h6>
                        <div onClick={() => setHandlePendingSubmissionPopup(false)}
                             className="d-flex align-items-center justify-content-center text-center fa-times">
                            <FaTimes/></div>
                    </div>

                    {
                        isLoading && <div>Loading...</div>
                    }

                    {
                        !isLoading && <div>
                            {
                                data && data.length > 0 ?
                                    data.map((dt) => {
                                        return <PendingSubmissionCard data={dt} team_id={team_id} reset={reset} setReset={setReset}/>
                                    }) : 'No Post Found'
                            }
                        </div>
                    }


                    {/*<div className='text-center mt-4 mb-2'>
                        <Button className="w-100 btn-create-post" onClick={()=>setHandleCreatePostPopup(true)}>CREATE NEW POST </Button>
                    </div>*/}

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PendingSubmissionPopup