import { React, Fragment } from 'react'
import { DoneIcon, PendingIcon, RejectIcon, FacebookIcon, TwitterIcon, InstagramIcon, LinkedinLogoSmall, YoutubeIcon, FacebookLogo, TwitterLogo, InstagramLogo, LinkedinLogo, YoutubeLogo, } from '../../constants/svgs'
import Assets from '../../constants/images'
import { Row, Col } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

const PostManagementCard = ({ data }) => {
    return (
        <>
            <NavLink to={`/post-management/${data?.id}`}>
                <div className='post-management-card p-3'>
                    <div className='header d-flex align-items-center mb-2'>
                        {
                            data?.status === "approved" ?
                                <DoneIcon /> :
                                data?.status === "reject" ?
                                    <RejectIcon /> :
                                    <PendingIcon />
                        }
                        <small className={`ms-3 ${data?.status === "approved" ? "text-done" : data?.status === "reject" ? "text-reject" : "text-pending"}`}>
                            {
                                data?.status === "approved" ?
                                    data?.schedule_time :
                                    data?.status === "reject" ?
                                        "Rejected" :
                                        "Pending for approval"
                            }
                        </small>
                    </div>

                    <div className='avatar-container d-flex align-items-center '>
                        <img src={data?.user?.details?.user_image_url} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} className='rounded-circle' />
                        <div className='d-flex flex-column ms-2'>
                            <span>{data?.user?.name}</span>
                            <small>{data?.elapsed_time}</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        {data?.text && <p>{data?.text} <span>{data?.hashtag}</span></p>}

                        <div className='footer d-flex flex-xl-row flex-column align-items-xl-center justify-content-between'>
                            <div className='d-flex'>
                                {
                                    data?.media?.slice(0, 2).map((v, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {v.type === "image" ? <img key={i} onError={(e) => e.target.src = Assets.GeneralPlaceholderImage} className="me-2" src={v.url} alt="PostImage1" /> :
                                                    <video width="35" height="35" controls={false} >
                                                        <source src={v.url} type="video/mp4" />
                                                    </video>}
                                            </Fragment>

                                        )
                                    })
                                }

                                {data?.media?.length > 2 && <div className='cutom-number-div d-flex align-items-center justify-content-center text-center'>
                                    <span>+{data?.media?.length}</span>
                                </div>}
                            </div>

                            <div className='mt-3 mt-xl-0 icon-div d-flex align-items-center'>
                                {data?.platforms?.length > 0 && data?.platforms?.map((d, i) => {
                                    if (d.id === 1) {
                                        return <div className='ms-xl-2'><FacebookLogo /></div>
                                    }
                                    else if (d.id === 2) {
                                        return <div className='ms-xl-2 mx-2 mx-xl-0'><TwitterLogo /></div>
                                    }
                                    else if (d.id === 3) {
                                        return <div className='ms-xl-2'><InstagramLogo /></div>
                                    }
                                    else if (d.id === 4) {
                                        return <div className='ms-xl-2'><LinkedinLogo /></div>
                                    }
                                    else {
                                        return <div className='ms-xl-2'><YoutubeLogo /></div>
                                    }


                                })}


                            </div>
                        </div>
                    </div>
                </div>
            </NavLink>

        </>
    )
}

export default PostManagementCard
