import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext, ProfileContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import { AllPost } from "../services/posts";
import { GetMyProfile } from '../services/profile';
import { error } from "../constants/msg";
import BlurLoader from "../components/blurLoader/BlurLoader";
import { ToastContainer } from "react-toastify";
import {GetAuthUserLocalStorage} from "../services/auth";

const PostManagementPage = () => {
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    // const [profileDetail, setProfileDetail] = useContext(ProfileContext);

    //------------------------------ Fetch the profile me API Starts ------------------//
    const [user_ID, setUser_ID] = useState(0);
    const [currTeamId, setCurrTeamId] = useState(0)
    const [beers, setbeers] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [HasMore, setHasMore] = useState(true);
    const profileDetail = GetAuthUserLocalStorage()

    // useEffect(() => {
    //     setProfileDetail(GetAuthUserLocalStorage())
    //     let a = GetAuthUserLocalStorage()
    //     console.log(a, "__++_+_++")
    //     // setUser_ID(profileDetail?.id)
    //     // setCurrTeamId(profileDetail?.current_team?.id)
    //     if (a?.id && a?.current_team?.id) {
    //         setbeers([])
    //         setUser_ID(a?.id)
    //         setCurrTeamId(a?.current_team?.id)
    //         console.log("helloe", a.id, a.current_team.id)
    //         loadMoreBeers(a?.id, a?.current_team?.id);
    //     }
    //     //
    //     console.log(GetAuthUserLocalStorage(), "This is Details")
    // }, []);

    useEffect(()=>{
        if(profileDetail?.id && profileDetail?.current_team?.id){
            setbeers([])
            // setUser_ID(a?.id)
            // setCurrTeamId(profileDetail?.current_team?.id)
            loadMoreBeers(profileDetail?.id, profileDetail?.current_team?.id);
        }
    },[page])

    const loadMoreBeers = async (user_id, team_id) => {
        setbeers([])
        setIsFetching(true);
        try {
            const res = await AllPost(user_id, team_id, page);
            setbeers((prevBeers) => {
                return [...new Set([...prevBeers, ...res?.data?.data])];
            });
            setPage(page);
            setHasMore(res?.data?.data?.length > 0);

            setIsFetching(false);
        }
        catch (e) {
            console.log(e,"---- Load Post Error ----")
        }

    }


    return (
        <>
            {handleFilterPopup && <FilterSearchPopup />}

            <div className="post-management-page">
                <Row className="mt-4">
                    <Col xs={12} className="d-block d-md-none mb-3">
                        <h5 className='text-yellow'>POST MANAGEMENT</h5>
                    </Col>

                    <Col xs={12}>
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"} to='/post-management'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)} className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4 mb-5 mb-sm-0 position-relative">
                    {isFetching && <BlurLoader />}
                    {beers?.length == 0 ?
                        <p className='text-center'>Their are no posts to show</p> : null
                    }
                    {beers?.map((beer, index) => {
                        if (beers?.length === index + 1) {
                            return <Col xs={12} sm={12} md={6} lg={4} className="mb-4" >
                                <PostManagementCard data={beer} key={index} />
                            </Col>;
                        }

                        else {
                            return <Col xs={12} sm={12} md={6} lg={4} className="mb-4" >
                                <PostManagementCard data={beer} key={index} />
                            </Col>
                        }
                    })}

                    {!isFetching && HasMore && (
                        <div className='LoadMore-btn d-flex justify-content-center mt-4'>
                            <Button onClick={()=>setPage(page+1)} className={`loadMore-btn btn-yellow`}>
                                Load More Posts
                            </Button>
                        </div>
                    )}
                </Row>
            </div>
        </>
    );
}


export default PostManagementPage
