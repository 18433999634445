import React from 'react';
import { Container, Row, Col,  } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom'
import Assets from "../../constants/images";
import { Logo } from "../../constants/svgs";

const HalfNavigationLayout = ({ heading, children, subHeading, link }) => {
    console.log("Link", link)
    return (
        <section className="account-setup-layout">
            <div className='half-navigation-layout p-4'>
                <header className='header d-flex align-items-center py-2 px-4'>
                    <Logo />
                    <div className='w-100 text-end text-sm-center flex-1'>
                        <h5 className='text-golden mt-2'>{heading}</h5>
                    </div>
                    <div className="text-end">
                        {/* <Link to={link} className="text-dark me-3">{subHeading}</Link> */}
                        <NavLink className="text-dark me-3" to={`${link}`}>{subHeading}</NavLink>
                    </div>
                </header>
                {children}
            </div>
        </section>
    )
}

export default HalfNavigationLayout