import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext, ProfileContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import { error } from "../constants/msg";
import { GetAllPendingPost } from "../services/dashboard";
import Loader from '../components/loader/Loader';

const PendingPostPage = () => {
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    const [pendingPost, setPendingPost] = useState();
    const [loading, setLoading] = useState(false);


    var user_ID = profileDetail?.id
    var currTeamId = profileDetail?.current_team?.id



    useEffect(() => {
        const OnlyPendingPosts = async (user_ID, currTeamId) => {
            try {
                const response = await GetAllPendingPost(user_ID, currTeamId);
                console.log(response, "pending response")

                setPendingPost(response.data.data)

                setLoading(true)
            }
            catch (e) {
                console.log(e.response.data.message)
            }
        }
        OnlyPendingPosts(user_ID, currTeamId)
    }, [user_ID, currTeamId])

    return (
        <>
            {handleFilterPopup && <FilterSearchPopup />}
            <div className="post-management-page">
                <Row className="mt-4">
                    <Col xs={12}>
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"} to='/pending-post'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)} className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    {loading ? (pendingPost.length == 0 ? <h4 className='text-center'>No Pending To Show</h4> : pendingPost?.map((data, index) => {
                        return (
                            <Col key={index} xs={12} sm={12} md={6} lg={4} className="mb-4">

                                <PostManagementCard data={data} />
                            </Col>
                        )
                    })) : (<Loader />)

                    }
                </Row>
            </div>
        </>
    )
}

export default PendingPostPage
