import React, {useCallback, useState, useEffect, useContext} from 'react'
import {InstagramIcon, FacebookIcon, TwitterIcon, LinkedinIcon, YoutubeIcon,} from "../../constants/svgs";
import {Row, Col, Button} from "react-bootstrap";
import Assets from '../../constants/images';
import {FaCheckCircle} from "react-icons/fa";
import {NavLink, useNavigate} from 'react-router-dom';
import {success, error} from "../../constants/msg";
import {UserContext, CurrentTeamContext} from '../../App';
import {GetTeamPlatform, GetTeamPlatformByTeamId} from "../../services/teamPlatforms";
import {constant} from "../../utlis/constants";

//=========================== Social Login Imports =============================== //
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialTwitter,
    LoginSocialApple,
} from 'reactjs-social-login'
import {getFacebookPage} from "../../services/socialMediaIntegration";

const SocialAccountForm = () => {


    const navigate = useNavigate();
    const [currentTeamContext, setCurrentTeam] = useContext(CurrentTeamContext);
    const [data, setData] = useState([]);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        getTeamPlatformByTeamId()
    }, [reset])

    //Get Team Platform
    const getTeamPlatformByTeamId = async () => {
        try {
            const response = await GetTeamPlatformByTeamId(currentTeamContext);
            console.log(response.data.data);
            let loop = []
            response.data.data.map((data) => {
                loop.push(data.platform_id)
            })
            setData(loop)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const onFacebookClick = async (provider, data) => {
        try {
            let response = await getFacebookPage(data?.accessToken)
            if (response && response.data.data && response.data.data.length > 0) {
                let params = {
                    team_id: currentTeamContext,
                    platform_id: constant.PLATFORM.FACEBOOK,
                    accessId: response.data.data[0].id,
                    accessToken: response.data.data[0].access_token,
                    url: 'https://www.facebook.com/'
                }
                submitTeamPlatfom(params);
            } else {
                error("We didn't found any page connected with your facebook account")
            }
        } catch (e) {
            error(e.response.data.message)
        }
        /*let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.FACEBOOK,
            accessId: data?.userID,
            accessToken: data?.accessToken,
            url: 'https://www.facebook.com/'
        }
        submitTeamPlatfom(params);*/
    }

    const onTwitterClick = (provider, data) => {
        /*todo: need to connect twitter and add access id and access token*/
        console.log(data);
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.TWITTER,
            accessId: '',
            accessToken: '',
            url: 'https://www.twitter.com/'
        }
        submitTeamPlatfom(params);
    }

    const onInstagramClick = (provider, data) => {
        /*todo: need to connect instagram and add access id and access token*/
        console.log(data);
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.INSTAGRAM,
            accessId: '',
            accessToken: '',
            url: 'https://www.instagram.com/'
        }
        submitTeamPlatfom(params);
    }

    const onLinkedinClick = (provider, data) => {
        /*todo: need to connect linkedin and add access id and access token*/
        console.log(data);
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.LINKEDIN,
            accessId: '',
            accessToken: '',
            url: 'https://www.linkedin.com/'
        }
        submitTeamPlatfom(params);
    }

    const onYoutubeClick = (provider, data) => {
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.YOUTUBE,
            accessId: data?.id,
            accessToken: data?.access_token,
            url: 'https://www.youtube.com/'
        }
        submitTeamPlatfom(params);
    }

    //Get Team Platform
    const submitTeamPlatfom = async (data) => {
        const {team_id, platform_id, accessId, accessToken, url} = data
        try {
            const response = await GetTeamPlatform(team_id, platform_id, accessId, accessToken, url);
            if (response.success) {
                success(response.data.message)
                setReset(!reset);
                //getTeamPlatformByTeamId()
            }
        } catch (e) {
            console.log(e.response.data.message)
        }
    }


    return (
        <div className='social-account-form mt-4'>
            <h6>Add Your Social Media Accounts</h6>

            <Row>
                <Col xs={12} md={12} lg={12} xl={7} className="mb-4">
                    <Row className='mt-3'>
                        <Col xs={6} xl={6} lg={6} md={12} className={`mb-4 col-full-width`} style={{cursor: 'pointer'}}>
                            {
                                !data.includes(constant.PLATFORM.FACEBOOK) ?
                                    <LoginSocialFacebook
                                        className={'social-account-card px-4 py-2 bg-facebook'}
                                        isOnlyGetCode
                                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                                        fieldsProfile={
                                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                        }
                                        onResolve={({provider, data}) => {
                                            onFacebookClick(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.log(err)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div d-flex align-items-center justify-content-center text-center'>

                                                <FacebookIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Facebook</h6>

                                        </div>
                                    </LoginSocialFacebook> :
                                    <div
                                        className={`social-account-card px-4 py-2 bg-facebook active`}>
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                <FacebookIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Facebook <FaCheckCircle/></h6>
                                        </div>
                                    </div>
                            }
                        </Col>

                        <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width" style={{cursor: 'pointer'}}>
                            {
                                !data.includes(constant.PLATFORM.TWITTER) ? <LoginSocialTwitter
                                        className={`social-account-card px-4 py-2 bg-twitter `}
                                        client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}
                                        // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                                        onResolve={(provider, data) => {
                                            onTwitterClick(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.log(err)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                <TwitterIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Twitter </h6>

                                        </div>
                                    </LoginSocialTwitter>
                                    : <div
                                        className={`social-account-card px-4 py-3 bg-twitter active`}>
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                <TwitterIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Twitter <FaCheckCircle/></h6>
                                        </div>
                                    </div>
                            }
                        </Col>

                        <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width" style={{cursor: 'pointer'}}>
                            {
                                !data.includes(constant.PLATFORM.INSTAGRAM) ? <LoginSocialInstagram
                                        className='social-account-card px-4 py-2 bg-instagram '
                                        client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ''}
                                        client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ''}
                                        onResolve={(provider, data) => {
                                            console.log(provider, data); return false;
                                            onInstagramClick(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.log(err);
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                <InstagramIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Instagram </h6>
                                        </div>
                                    </LoginSocialInstagram>
                                    : <div
                                        className='social-account-card px-4 py-3 bg-instagram active'>
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                <InstagramIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Instagram <FaCheckCircle/></h6>
                                        </div>
                                    </div>
                            }
                        </Col>

                        <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width" style={{cursor: 'pointer'}}>
                            {
                                !data.includes(constant.PLATFORM.LINKEDIN) ? <LoginSocialLinkedin
                                        className='social-account-card px-4 py-2 bg-linkedin'
                                        client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                                        client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                                        onResolve={(provider, data) => {
                                            onLinkedinClick(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.log(err);
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div linkedin-circle d-flex align-items-center justify-content-center text-center'>
                                                <LinkedinIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Linkedin </h6>
                                        </div>
                                    </LoginSocialLinkedin>
                                    : <div className='social-account-card px-4 py-2 bg-linkedin active'>
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div linkedin-circle d-flex align-items-center justify-content-center text-center'>
                                                <LinkedinIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Linkedin <FaCheckCircle/></h6>
                                        </div>
                                    </div>
                            }
                        </Col>

                        <Col xs={6} xl={6} lg={6} md={12} className="mb-4 col-full-width" style={{cursor: 'pointer'}}>
                            {
                                !data.includes(constant.PLATFORM.YOUTUBE) ? <LoginSocialGoogle
                                        className='social-account-card px-4 py-2 bg-youtube '
                                        isOnlyGetCode
                                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                                        //onLoginStart={onLoginStart}
                                        scope="openid profile email"
                                        access_type="offline"
                                        onResolve={({provider, data}) => {
                                            onYoutubeClick(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.log(err)
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div youtube-circle d-flex align-items-center justify-content-center text-center'>
                                                <YoutubeIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Youtube </h6>
                                        </div>
                                    </LoginSocialGoogle>
                                    : <div className='social-account-card px-4 py-3 bg-youtube active'>
                                        <div className='d-flex align-items-center'>
                                            <div
                                                className='circle-div youtube-circle d-flex align-items-center justify-content-center text-center'>
                                                <YoutubeIcon/>
                                            </div>
                                            <h6 className='p-0 m-0 ms-2'>Youtube <FaCheckCircle/></h6>
                                        </div>
                                    </div>
                            }
                        </Col>

                        <Col xs={12}></Col>

                        <Col xs={12} sm={6}>
                            <Button className="w-100 btn-continue mt-4"
                                    onClick={() => navigate('/dashboard')}>CONTINUE</Button>
                        </Col>

                        <Col xs={12} className="mt-5">
                            <NavLink to="/dashboard">Set Up Later</NavLink>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={12} lg={12} xl={5}>
                    <div className='img-div mt-3 d-none d-xl-block'>
                        <img className='w-100' src={Assets.SocialAccountImage} alt="SocialAccountImage"/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SocialAccountForm
