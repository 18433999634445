import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Carousel, Form, Dropdown } from 'react-bootstrap'
import StatsboxCard from '../components/dashboard/StatsboxCard'
import { TotalPostStatsIcon, SchedulePostStatsIcon, CalendarStreakStatsIcon, PendingIcon } from '../constants/svgs'
import { AiOutlineClockCircle } from "react-icons/ai"
import { NavLink, useNavigate } from 'react-router-dom'
import PendingPostCard from '../components/dashboard/PendingPostCard'
import { PendingPostData, PostManagementData, UserContext, ProfileContext, TutorialContext } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { Getstatistics, PendingPost, UpcomingPost } from "../services/dashboard";
import { GetMyProfile } from '../services/profile';
import { success, error } from "../constants/msg";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import TextLoader from '../components/TextLodaer/TextLoader';
import Loader from '../components/loader/Loader';
import 'swiper/css';
import 'swiper/css/pagination';
import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';

import { Bar, Chart, Line } from 'react-chartjs-2';
import faker from 'faker';
import Assets from '../constants/images'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css';
import { constant } from "../utlis/constants";
import AutoPostPage from "./AutoPostPage";
import TutorialSteps from '../components/TutorialSteps/TutorialSteps'
import DeletePopup from '../components/popups/DeletePopup'
//Loader


SwiperCore.use([Autoplay]);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                display: false
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
};


function createGradient(ctx, area) {
    const colorStart = "#FEC053"
    const colorMid = "#F2203E"
    const colorEnd = "#B729A8"

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.5, colorMid);
    gradient.addColorStop(1, colorEnd);

    return gradient;
}

const DashboardPage = () => {
    const chartRef = useRef(null);
    const navigate = useNavigate()
    const [getPendingPost, setGetPendingPost] = useState('');
    const [pendingPost, setPendingPost] = useContext(PendingPostData);
    const [postManagement, setPostManagement] = useState([]);
    // User id and current team id variable
    const [filter, setFilter] = useState("0");
    const [handleOpen, setHandleOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date((new Date()).setDate((new Date()).getDate() - 30)),
        endDate: new Date(),
        key: 'selection',
    });
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    // ---------------------- ------------------ Post Created Graph Data -------------------------------------
    const [statsData, setStatsData] = useState([]);
    //platform Data
    const [platformName, setPlatformName] = useState([]);
    const [platformValue, setPlatformValue] = useState([]);
    //Members Data
    const [membersName, setMembersName] = useState([]);
    const [membersValue, setMembersValue] = useState([]);
    //Weeks Data
    const [weeksName, setWeeksName] = useState([]);
    const [weeksValue, setWeeksValue] = useState([]);
    //Month Data
    const [monthName, setmonthName] = useState([]);
    const [monthValue, setmonthValue] = useState([]);
    //Loding States
    const [isLoading, setIsLoading] = useState(true)

    //------------------------------ Fetch the profile me API Starts ------------------//
    const [profileDetail, setProfileDetail] = useState([]);
    const [user_ID, setUser_ID] = useState('');
    const [currTeamId, setCurrTeamId] = useState('')

    //Tutorial Context
    const [tutorialScreen, setTutorialScreen] = useContext(TutorialContext);
    const [handleAcceptReject, setHandleAcceptReject] = useState({
        text: "",
        name: "",
        method:null
    })

    const myProfile = async () => {
        try {
            const response = await GetMyProfile();
            let profileResp = response?.data?.data
            setProfileDetail(profileResp)
            setUser_ID(profileResp?.id)
            setCurrTeamId(profileResp?.current_team?.id)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    useEffect(() => {
        myProfile();
    }, [])
    //------------------------------ Fetch the profile me API End ------------------//

    //Upcoming Scheduled post API calling
    const upcomingPost = async () => {
        try {
            const response = await UpcomingPost(user_ID, currTeamId);
            setGetPendingPost(response?.data?.data);
            setIsLoading(false)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    //Pending post API calling
    const getPending = async () => {
        try {
            const response = await PendingPost(user_ID, currTeamId);
            console.log("Pending Post Response", response?.data?.data)
            setGetPendingPost(response?.data?.data)
            setIsLoading(false)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    useEffect(() => {

        if (user_ID && currTeamId) {
            getPending();

            upcomingPost();
        }



    }, [currTeamId, pendingPost]);


    // Use Effect for Statistics
    useEffect(() => {
        // Get Statistics of user posts
        const getStatistics = async () => {
            let start_date = moment(selectionRange.startDate).format('YYYY-MM-DD');
            let end_date = moment(selectionRange.endDate).format('YYYY-MM-DD');
            try {
                const res = await Getstatistics(start_date, end_date, currTeamId);
                const respStatsdata = res.data.data
                setStatsData(respStatsdata);
                setLoading(true)
                //Social Platforms Data
                setPlatformName(Object.keys(respStatsdata?.platform));
                setPlatformValue(Object.values(respStatsdata?.platform));
                //Members Data
                setMembersName(Object.keys(respStatsdata?.members));
                setMembersValue(Object.values(respStatsdata?.members));
                //Weeks Data
                setWeeksName(Object.keys(respStatsdata?.weeks));
                setWeeksValue(Object.values(respStatsdata?.weeks));
                //Year Data
                setmonthName(Object.keys(respStatsdata?.months));
                setmonthValue(Object.values(respStatsdata?.months));
            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        getStatistics();
    }, [selectionRange, currTeamId])

    useEffect(() => {
        const chart = chartRef.current;
        if (!chart) {
            return;
        } else {
            const chartData = {
                ...platformData,
                datasets: platformData.datasets.map(dataset => ({
                    ...dataset,
                    backgroundColor: [
                        '#4776b9',
                        '#1da1f2',
                        createGradient(chart.ctx, chart.chartArea),
                        '#3d83d9',
                        '#d40c18',
                    ],
                })),
            };
            setChartData(chartData);
        }
    }, [])

    const handleSelect = (ranges) => {
        setSelectionRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
        })
    }

    const showDate = () => {
        let startDate = selectionRange.startDate.toString().slice(4, 10)
        let endDate = selectionRange.endDate.toString().slice(4, 10)
        let date = startDate + "-" + endDate
        return date
    }

    //Social Platform data
    const platformData = {
        labels: platformName,
        datasets: [
            {
                label: '',
                data: platformValue,
                backgroundColor: [
                    '#4776b9',
                    '#1da1f2',
                    '#fbad50',
                    '#3d83d9',
                    '#d40c18',
                ],

            },
        ],


    };
    //Filter By Member data
    const memberData = {
        labels: membersName,
        datasets: [
            {
                label: '',
                data: membersValue,
                backgroundColor: '#e09944',
            },
        ],
    };
    //Filter By Week Data
    const weekData = {
        labels: weeksName,
        datasets: [
            {
                label: '',
                data: weeksValue,
                borderColor: '2b2829',
                backgroundColor: '#2b2829',
            },
        ],
    };

    //Filter By Months Data
    const monthData = {
        labels: monthName,
        datasets: [
            {
                label: '',
                data: monthValue,
                borderColor: '2b2829',
                backgroundColor: '#2b2829',
            },
        ],
    };

    console.log(handleAcceptReject)

    return (
        <div className={`dashboard-page`}>
            <DeletePopup text={handleAcceptReject.text} handleDelete={handleAcceptReject.method} />

            <Row>
                <Col xs={12} className="my-4">
                    <div>
                        <h5 className='text-yellow d-block d-md-none'>DASHBOARD</h5>
                        <h6 className='p-0 m-0'>Welcome
                            Back, {profileDetail?.name}</h6>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12} xl={8}>
                    <Swiper
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 3000 }}
                        className="mb-2"
                        spaceBetween={20}
                        breakpoints={{
                            200: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                            1300: {
                                slidesPerView: 3,
                            },
                        }}
                    >

                        <SwiperSlide>
                            <StatsboxCard theme="light" icon={<TotalPostStatsIcon />}
                                count={statsData?.total_posts}
                                title="Total Posts" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatsboxCard theme="dark" icon={<SchedulePostStatsIcon />}
                                count={statsData?.scheduled_posts} title="Scheduled Posts" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatsboxCard theme="light" icon={<CalendarStreakStatsIcon />}
                                count={statsData?.calendar_streak} title="Calendar Streaks" />
                        </SwiperSlide>
                    </Swiper>

                    <Row>
                        <Col xs={12} className="mb-4">
                            <div className="chart-container p-4">
                                {/* {
                                    handleOpen &&
                                    <div onClick={() => setHandleOpen(false)} className="overlay" style={{ backgroundColor: "transparent", position: "absolute", width: "100%", height: "100%", zIndex: "1" }}></div>
                                } */}
                                <div
                                    className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Assets.BarIcon} alt="" />
                                        <p className="p-0 m-0 ms-3">Post Created</p>
                                    </div>

                                    <div
                                        className='date-range-container d-flex flex-column flex-md-row align-items-md-center mt-3 mt-lg-0'>
                                        {/* <Form.Control readOnly onClick={() => setHandleOpen(!handleOpen)} className="mb-2 mb-md-0 me-0 me-md-2" value={showDate()} />
                                        {
                                            handleOpen &&
                                            <DateRangePicker
                                                ranges={[selectionRange]}
                                                onChange={handleSelect}
                                            />
                                        } */}

                                        <Dropdown className="calendar-dropdown mb-2 mb-md-0 me-0 me-md-2">
                                            <Dropdown.Toggle id="dropdown-basic">
                                                {showDate()}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <DateRangePicker
                                                    ranges={[selectionRange]}
                                                    onChange={handleSelect}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                {
                                                    filter === "0" ?
                                                        "Filter By Platform" :
                                                        filter === "1" ?
                                                            "Filter By Members" :
                                                            filter === "2" ?
                                                                "Filter By Week" :
                                                                " Filter By Month"
                                                }
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item className="border-bottom py-2" href="#"
                                                    onClick={() => setFilter("0")}>Filter By
                                                    Platform</Dropdown.Item>
                                                <Dropdown.Item className="border-bottom py-2" href="#"
                                                    onClick={() => setFilter("1")}>Filter By
                                                    Members</Dropdown.Item>
                                                <Dropdown.Item className="border-bottom py-2" href="#"
                                                    onClick={() => setFilter("2")}>Filter By
                                                    Week</Dropdown.Item>
                                                <Dropdown.Item className="py-2" href="#"
                                                    onClick={() => setFilter("3")}>Filter By
                                                    Month</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                {
                                    filter === '0' ?
                                        platformData?.datasets[0]?.data?.length == 0 ?
                                            <div className='graph-error-msg text-capitalize text-center'>There is no data to display <br /> for the selected month range. </div> :
                                            <Chart className='mt-4' options={options} ref={chartRef} type='bar'
                                                data={platformData} /> :
                                        filter === '1' ?
                                            memberData?.datasets[0]?.data?.length == 0 ?
                                                <div className='graph-error-msg text-capitalize text-center'>There is no data to display <br /> for the selected month range. </div> :
                                                <Bar className='mt-4' options={options} data={memberData} /> :
                                            filter === '2' ?
                                                weekData?.datasets[0]?.data?.length == 0 ?
                                                    <div className='graph-error-msg text-capitalize text-center'>There is no data to display <br /> for the selected month range. </div> :
                                                    <Line className='mt-4' options={options} data={weekData} /> :
                                                monthData?.datasets[0]?.data?.length == 0 ?
                                                    <div className='graph-error-msg text-capitalize text-center'>There is no data to display <br /> for the selected month range. </div> :
                                                    <Line className='mt-4' options={options} data={monthData} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} xl={4}>
                    <div className='pending-post-container p-3'>
                        <div className='d-flex justify-content-between align-items-center border-bottom pb-3'>
                            <div className='d-flex align-items-center'>
                                <div className='clock-icon-container position-relative'>
                                    <AiOutlineClockCircle className='clock-icon ' />
                                    {tutorialScreen === true && <TutorialSteps
                                        stepNum={"03"}
                                        stepName={"step3"}
                                        placement={"left"}
                                        icon={<AiOutlineClockCircle />}
                                        heading={"Pending Posts"}
                                        para={"As you and your team submit content via the mobile app, you'll see them appear here. Be generous with content submissions! You can reject the bad ones, and assign the good ones to upcoming post slots!"}
                                    />}
                                </div>
                                <h4 className='p-0 m-0 ms-3'>Pending Posts <span>({getPendingPost.length})</span></h4>
                            </div>
                            <div>
                                {/*<NavLink to="/pending-post">View All</NavLink>*/}
                                <AutoPostPage padding={false} />
                            </div>
                        </div>

                        {isLoading ? <TextLoader text="Pending Post Loading " /> :
                            <div className='pending-post-holder'>
                                {getPendingPost?.length == 0 ? <p className='mt-4'>No posts to
                                    show</p> : getPendingPost?.length > 0 && getPendingPost?.map((data, index) => {
                                        return (
                                            <PendingPostCard key={index} data={data} handleAcceptReject={handleAcceptReject} setHandleAcceptReject={setHandleAcceptReject} />
                                        )
                                    })

                                }
                            </div>}

                        <div className='text-center mt-2'>
                            <NavLink to="/pending-post">View All</NavLink>
                        </div>
                    </div>
                </Col>

                <Col xs={12} className="mb-5 mb-sm-0">
                    <div className='slider-container upComing-schedule-section'>
                        <h6 className='my-4'>Upcoming Scheduled Posts</h6>

                        {isLoading ? <TextLoader text="Loading Upcoming Schedule Posts" /> :

                            <Swiper
                                className="mb-4"
                                spaceBetween={20}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    200: {
                                        slidesPerView: 1,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    992: {
                                        slidesPerView: 2,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >

                                {getPendingPost?.length > 0 ? getPendingPost?.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <PostManagementCard key={index} data={data} />
                                        </SwiperSlide>
                                    )
                                })
                                    : <p className='text-center'>There are no scheduled posts to show.</p>
                                }

                            </Swiper>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )

}

export default DashboardPage
