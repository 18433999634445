import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Assets from '../../constants/images'
import { RiDeleteBin6Line } from "react-icons/ri"
import { success, error } from "../../constants/msg";
import { DeleteTeamUser } from "../../services/teamsUser";
import { DeletePopupContext } from '../../App';
import DeletePopup from '../popups/DeletePopup';
import {UpdateUserOnLocalStorage} from "../../services/auth";

const TeamAddedCard = ({ name, image, user_id, setCounter, counter }) => {
    const [loading, setLoading] = useState(false)
    const [deletePopup, setDeletePopup] = useContext(DeletePopupContext);

    const deleteUser = async () => {
        try {
            const response = await DeleteTeamUser(user_id);
            setCounter(counter + 1);
            setDeletePopup(false)
            await UpdateUserOnLocalStorage()

            success(response.data.message)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    return (
        <div className='team-added-card mt-3'>
            <DeletePopup text="Are you sure you want to delete user" handleDelete={deleteUser} />

            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ps-2">
                    {image == null ? <img src={Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} alt="Avatar" />
                        : <img src={image} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} alt="Avatar" />}

                    <h6 className='m-0 p-0 ms-3'>{name}</h6>
                </div>

                <div className='btn-delete-container me-2'>
                    <RiDeleteBin6Line className='icon' onClick={() => setDeletePopup(true)} />
                </div>
            </div>
        </div>
    )
}

export default TeamAddedCard
