import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import SettingNavigation from '../components/dashboard/SettingNavigation'
import Assets from '../constants/images'
import {FaCheckCircle} from "react-icons/fa";
import {FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon, YoutubeIcon} from '../constants/svgs'
import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
} from 'reactjs-social-login'

import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
    AppleLoginButton,
} from 'react-social-login-buttons';
import {GetTeamPlatform, GetTeamPlatformByTeamId} from "../services/teamPlatforms";
import {error, success} from "../constants/msg";
import {constant} from "../utlis/constants";
import {CurrentTeamContext, UserContext} from "../App";
import {getFacebookPage} from "../services/socialMediaIntegration";

const SocialAccountsManagementPage = () => {
    const [currentTeamContext, setCurrentTeam] = useContext(CurrentTeamContext);
    const [data, setData] = useState([]);
    const REDIRECT_URI = "https://rodeo-web.theservtech.com/";
    // const REDIRECT_URI = "https://localhost:3000/";
    useEffect(() => {
        getTeamPlatformByTeamId()
    }, [])

    //Get Team Platform
    const getTeamPlatformByTeamId = async () => {
        try {
            const response = await GetTeamPlatformByTeamId(currentTeamContext);
            let loop = []
            response.data.data.map((data) => {
                console.log(data)
                loop.push(data.platform_id)
            })
            setData(loop)
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const onFacebookClick = async (provider, data) => {
        try {
            let response = await getFacebookPage(data?.accessToken)
            if (response && response.data.data && response.data.data.length > 0) {
                let params = {
                    team_id: currentTeamContext,
                    platform_id: constant.PLATFORM.FACEBOOK,
                    accessId: response.data.data[0].id,
                    accessToken: response.data.data[0].access_token,
                    url: 'https://www.facebook.com/'
                }
                submitTeamPlatfom(params);
            } else {
                error("We didn't found any page connected with your facebook account")
            }
        } catch (e) {
            error(e.response.data.message)
        }
    }

    const onTwitterClick = (provider, data) => {
        /*todo: need to connect twitter and add access id and access token*/
        console.log(data);
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.TWITTER,
            accessId: '',
            accessToken: '',
            url: 'https://www.twitter.com/'
        }
        submitTeamPlatfom(params);
    }

    const onInstagramClick = (provider, data) => {
        /*todo: need to connect instagram and add access id and access token*/
        console.log(data);
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.INSTAGRAM,
            accessId: '',
            accessToken: '',
            url: 'https://www.instagram.com/'
        }
        submitTeamPlatfom(params);
    }

    const onLinkedinClick = (provider, data) => {
        /*todo: need to connect linkedin and add access id and access token*/
        console.log(data);
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.LINKEDIN,
            accessId: '',
            accessToken: '',
            url: 'https://www.linkedin.com/'
        }
        submitTeamPlatfom(params);
    }

    const onYoutubeClick = (provider, data) => {
        let params = {
            team_id: currentTeamContext,
            platform_id: constant.PLATFORM.YOUTUBE,
            accessId: data?.id,
            accessToken: data?.access_token,
            url: 'https://www.youtube.com/'
        }
        submitTeamPlatfom(params);
    }

    //Get Team Platform
    const submitTeamPlatfom = async (data) => {
        const {team_id, platform_id, accessId, accessToken, url} = data
        try {
            const response = await GetTeamPlatform(team_id, platform_id, accessId, accessToken, url);
            if (response.success) {
                success(response.data.message)
                getTeamPlatformByTeamId()
            }
        } catch (e) {
            console.log(e.response.data.message)
        }
    }


    return (
        <div className='social-accounts-management-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation/>
                {/* todo: This functionality is not working properly. Need to ask to adeel bhai */}

                <Col xs={12} lg={12} xl={9} className="mt-4">
                    <div className='social-account-form'>
                        <h6>Add Your Social Media Accounts</h6>
                        <Row className='mt-3'>
                            <Col xs={6} xl={4} lg={6} md={12} style={{cursor: 'pointer'}}
                                 className={`mb-4 col-full-width`}>
                                {
                                    !data.includes(constant.PLATFORM.FACEBOOK) ?
                                        <LoginSocialFacebook
                                            className={'social-account-card px-4 py-3 bg-facebook '}
                                            isOnlyGetCode
                                            appId={process.env.REACT_APP_FB_APP_ID || ''}
                                            fieldsProfile={
                                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                            }
                                            onResolve={({provider, data}) => {
                                                onFacebookClick(provider, data)
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div d-flex align-items-center justify-content-center text-center'>

                                                    <FacebookIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Facebook</h6>
                                            </div>
                                        </LoginSocialFacebook> :
                                        <div
                                            className={`social-account-card px-4 py-3 bg-facebook active`}>
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                    <FacebookIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Facebook <FaCheckCircle/></h6>
                                            </div>
                                        </div>
                                }
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} style={{cursor: 'pointer'}}
                                 className="mb-4 col-full-width">
                                {
                                    !data.includes(constant.PLATFORM.TWITTER) ? <LoginSocialTwitter
                                            className={`social-account-card px-4 py-3 bg-twitter `}
                                            client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}
                                            redirect_uri={REDIRECT_URI}
                                            // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                                            onResolve={(provider, data) => {
                                                onTwitterClick(provider, data)
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                    <TwitterIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Twitter</h6>
                                            </div>
                                        </LoginSocialTwitter>
                                        : <div
                                            className={`social-account-card px-4 py-3 bg-twitter active`}>
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                    <TwitterIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Twitter <FaCheckCircle/></h6>
                                            </div>
                                        </div>
                                }
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} style={{cursor: 'pointer'}}
                                 className="mb-4 col-full-width">
                                {
                                    !data.includes(constant.PLATFORM.INSTAGRAM) ? <LoginSocialInstagram
                                            className='social-account-card px-4 py-3 bg-instagram '
                                            client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ''}
                                            client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ''}
                                            redirect_uri={REDIRECT_URI}
                                            onResolve={(provider, data) => {
                                                console.log(provider, data);
                                                onInstagramClick(provider, data)
                                            }}
                                            onReject={(err) => {
                                                console.log(err);
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                    <InstagramIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Instagram</h6>
                                            </div>
                                        </LoginSocialInstagram>
                                        : <div
                                            className='social-account-card px-4 py-3 bg-instagram active'>
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div d-flex align-items-center justify-content-center text-center'>
                                                    <InstagramIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Instagram <FaCheckCircle/></h6>
                                            </div>
                                        </div>
                                }
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} style={{cursor: 'pointer'}}
                                 className="mb-4 col-full-width">
                                {
                                    !data.includes(constant.PLATFORM.LINKEDIN) ? <LoginSocialLinkedin
                                            className='social-account-card px-4 py-3 bg-linkedin '
                                            client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                                            client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                                            redirect_uri={REDIRECT_URI}
                                            onResolve={(provider, data) => {
                                                onLinkedinClick(provider, data)
                                            }}
                                            onReject={(err) => {
                                                console.log(err);
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div linkedin-circle d-flex align-items-center justify-content-center text-center'>
                                                    <LinkedinIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Linkedin</h6>
                                            </div>
                                        </LoginSocialLinkedin>
                                        : <div className='social-account-card px-4 py-3 bg-linkedin active'>
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div linkedin-circle d-flex align-items-center justify-content-center text-center'>
                                                    <LinkedinIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Linkedin <FaCheckCircle/></h6>
                                            </div>
                                        </div>
                                }
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12} style={{cursor: 'pointer'}}
                                 className="mb-4 col-full-width">
                                {
                                    !data.includes(constant.PLATFORM.YOUTUBE) ? <LoginSocialGoogle
                                            className='social-account-card px-4 py-3 bg-youtube '
                                            isOnlyGetCode
                                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                                            //onLoginStart={onLoginStart}
                                            scope="openid profile email"
                                            access_type="offline"
                                            onResolve={({provider, data}) => {
                                                onYoutubeClick(provider, data)
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div youtube-circle d-flex align-items-center justify-content-center text-center'>
                                                    <YoutubeIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Youtube</h6>
                                            </div>
                                        </LoginSocialGoogle>
                                        : <div className='social-account-card px-4 py-3 bg-youtube active'>
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='circle-div youtube-circle d-flex align-items-center justify-content-center text-center'>
                                                    <YoutubeIcon/>
                                                </div>
                                                <h6 className='p-0 m-0 ms-4'>Youtube <FaCheckCircle/></h6>
                                            </div>
                                        </div>
                                }
                            </Col>

                            <Col xs={6} xl={4} lg={6} md={12}>
                                <div className='img-div d-none d-xl-block'>
                                    <img className='w-100' src={Assets.SocialAccountImage} alt="SocialAccountImage"/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SocialAccountsManagementPage
