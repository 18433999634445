import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';


//get the list of the packages

export const getPakagesList = async () => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.all_packages}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}

// Store the package by id 

export const storePackageById = async (package_id) => {
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.all_packages}/${package_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    )
    return resp
}


//Create Subcription

export const createSubscription = async (user_id, package_id) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.user_subscription_create}`, { user_id, package_id }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return resp
}

//Add Strip Card

export const addStripeRequest = async (stripe_token) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.add_stripe_card}`, {stripe_token} , {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp
}