import React from 'react';
import { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTimes } from "react-icons/fa";
import { ConfirmationPopupContext } from '../../App';

function ConfirmationPopup() {
    const [confirmationPopup, setConfirmationPopup] = useContext(ConfirmationPopupContext)
    return (
        <Modal
            className='confirmation-modal'
            show={true}
            size="xs"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content position-relative border-0 d-flex justify-content-center align-items-center p-4">
                    <div onClick={() => (setConfirmationPopup(false))} className="cross-icon d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    <div className="confirmation-box d-flex justify-content-center align-items-center flex-flow-column w-80">
                        <h4>Are You Sure?</h4>
                        <div className="confirmation-button d-flex align-items-center justify-content-center w-100">
                            <Button type="submit" className="w-100 mt-2 confirmation-btn">Yes</Button>
                            <Button type="submit" className="w-100 mt-2 ms-2 reject-btn">No</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationPopup