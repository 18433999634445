import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { PostManagementData } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext, ProfileContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import { GetPostUserID } from '../services/posts'


const MySubmissionsPage = () => {
    const [postManagement, setPostManagement] = useContext(PostManagementData);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    const [userPost, setUserPost] = useState([]);
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    console.log(userPost)

    var user_ID = profileDetail?.id

    const getUserPost = async () => {
        try {
            let response = await GetPostUserID(user_ID);
            setUserPost(response.data.data)

        } catch {

        }
    }
    useEffect(() => {
        if (getUserPost) {
            getUserPost()
        }

    }, [])


    return (
        <>
            {handleFilterPopup && <FilterSearchPopup />}
            <div className="post-management-page">
                <Row className="mt-4">
                    <Col xs={12}>
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"} to='/view-submissions'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)} className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    {userPost?.length > 0 ?
                        userPost.map((data, index) => {
                            return (
                                <Col key={index} xs={12} sm={12} md={6} lg={4} className="mb-4">
                                    <PostManagementCard data={data} />
                                </Col>
                            )
                        }) : <h6 className='text-center'>No Submission Yet</h6>
                    }
                </Row>
            </div>
        </>
    )
}

export default MySubmissionsPage