import React, { useState, useEffect, useContext } from 'react'
import { Col, Row } from 'react-bootstrap';
import { success, error } from "../constants/msg";
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup'
import InviteTeamCardDashboard from '../components/dashboard/InviteTeamCardDashboard';
import InviteCollaboratorCard from '../components/dashboard/InviteCollaboratorCard';
import InviteManagerCard from '../components/dashboard/InviteManagerCard';
import PendingSubmissionPopup from '../components/dashboard/PendingSubmissionPopup'
import RequestTeamCard from '../components/dashboard/RequestTeamCard'
import TeamAddedCard from '../components/dashboard/TeamAddedCard'
import { ToastContainer } from "react-toastify";
import { getJoinTeamRequest, GetTeamCollaborators, GetTeamManagers } from '../services/teamsUser';
import { SelectedPlanData, UserContext, CurrentTeamContext, ProfileContext } from '../App';
import BlurLoader from '../components/blurLoader/BlurLoader';
import UserAdditionPopup from '../components/popups/UserAdditionPopup';

const UserManagementPage = () => {
    //Loading State
    const [loading, setLoading] = useState(false);
    const [profileDetail, setProfileDetail] = useContext(ProfileContext);
    const [userAddModal, setuserAddModal] = useState(false);
    const [joinTeamList, setJoinTeamList] = useState([]);
    const [selectedPlan, setSelectedPlan] = useContext(SelectedPlanData);
    const [userData, setUserData] = useContext(UserContext);
    const [counter, setCounter] = useState(0)
    const [selectUser, setSelectedUser] = useState()
    const [check, set_check] = useState()
    const [teamCollab, setTeamCollab] = useState();
    const [teamManagers, setTeamManagers] = useState();

    var currTeam = profileDetail?.current_team?.id;

    useEffect(() => {
        //api for joining team request
        const fetchJoinTeamRequest = async () => {
            try {
                let response = await getJoinTeamRequest(currTeam);
                let joinTeamVar = response?.data?.data
                setJoinTeamList(joinTeamVar)
                setLoading(true)
            } catch (e) {
                error("Team not fetched")
            }
        }
        // Api for fetch Collaborators
        const fetchTeamCollaborators = async () => {
            try {
                let response = await GetTeamCollaborators(currTeam);
                setTeamCollab(response.data.data)
                setLoading(true)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }
        //API for fetch Manager
        const fetchTeamManagers = async () => {
            try {
                let response = await GetTeamManagers(currTeam);
                setTeamManagers(response.data.data)
                setLoading(true)

            } catch (e) {
                console.log(e.response.data.message)
            }
        }

        fetchTeamManagers();
        fetchTeamCollaborators();
        fetchJoinTeamRequest();
    }, [counter]);

    return (
        <div className='user-management-page'>

            <ToastContainer />
            {userAddModal === true && <UserAdditionPopup userAddModal={userAddModal} setuserAddModal={setuserAddModal} selectUser={selectUser} setuserAddModal={setuserAddModal} set_check={set_check} check={check}/>}
            <Row className="mt-4">
                <Col xs={12} xl={6} className="mb-4">
                    <h5 className='text-yellow d-block d-md-none'>USERS</h5>
                    <h6>Joining Team Request</h6>
                    <div className='request-team-card-container mt-4 p-4'>
                        <div className="holder position-relative">
                            {true ? (joinTeamList?.data?.length > 0 ? joinTeamList?.data?.map((v, i) => {
                                return <RequestTeamCard index={i} name={v?.name} email={v?.name} phone={v?.details?.phone} key={i} user_id={v?.team_user_pending?.user_id} team_id={v?.team_user_pending?.team_id} joinTeamList={joinTeamList} setJoinTeamList={setJoinTeamList} setCounter={setCounter} counter={counter} setuserAddModal={setuserAddModal} setSelectedUser={setSelectedUser} userAddModal={userAddModal} check={check} set_check={set_check}/>
                            }) : <h4 className='w-100 h-100 d-flex align-items-center justify-content-center text-center'>No Request to Join The Team</h4>)
                                : <BlurLoader />}

                        </div>
                    </div>
                </Col>

                <Col xs={12} xl={6} className="">
                    <Row>
                        <Col xs={12} lg={6} className="mb-4">
                            <h6 className='mb-4'>Invite Collaborator</h6>
                            <InviteCollaboratorCard heading="Invite Collaborator" buttonText="Invite Collaborator" />
                        </Col>

                        <Col xs={12} lg={6} className="mb-4">
                            <h6 className='mb-4'>Invite Brand Manager</h6>
                            <InviteManagerCard heading="Invite Brand Manager" buttonText="Invite Brand Manager" />
                        </Col>

                        {/* Collaborator List */}

                        <Col xs={12} lg={6} className="mb-4">
                            <div className='team-added-card-container p-4'>
                                <h6 className='border-bottom pb-3'>Collaborator Added</h6>
                                <div className="holder position-relative">
                                    {true ? (teamCollab?.length > 0 ? teamCollab?.map((v, i) => {
                                        return <TeamAddedCard key={i} name={v?.name} image={v?.user_image_url} user_id={v?.id} setCounter={setCounter} counter={counter} />
                                    }) : <h6 className='w-100 h-100 d-flex align-items-center justify-content-center text-center'>No Collaborator has been added yet</h6>)
                                        : (<BlurLoader />)}
                                </div>
                            </div>
                        </Col>

                        {/* Brand Manager List */}

                        <Col xs={12} lg={6} className="mb-4">
                            <div className='team-added-card-container p-4'>
                                <h6 className='border-bottom pb-3'>Brand Mananger Added</h6>
                                <div className="holder position-relative">

                                    {true ? (teamManagers?.length > 0 ? teamManagers?.map((v, i) => {
                                        return <TeamAddedCard key={i} name={v?.name} image={v?.user_image_url} user_id={v?.id} setCounter={setCounter} counter={counter} />
                                    }) : <h6 className='w-100 h-100 d-flex align-items-center justify-content-center text-center'>No Mangers has been added yet</h6>)
                                        : (<BlurLoader />)}

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default UserManagementPage
