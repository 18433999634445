import React, { useContext, useEffect, useState } from 'react';
import { Router, useNavigate } from 'react-router-dom';
import { Modal, FormCheck, Form, Button } from 'react-bootstrap'
import { StarIcon } from '../../constants/svgs'
import { FaTimes } from "react-icons/fa";
import Assets from '../../constants/images';
import { ProfileContext, UserContext, CurrentTeamContext, AddTeamContext, SwitchTeamContext } from "../../App";
import { GetMyProfile } from "../../services/profile";
import { success, error } from "../../constants/msg";
import BlurLoader from '../blurLoader/BlurLoader';
import { RoundedPlus } from '../../constants/svgs';
import { CurrentTeam } from '../../services/teams';
import { UpdateUserOnLocalStorage } from "../../services/auth";
import {UpdateRole} from "../../services/profile";

const UserAdditionPopup = (props) => {
    const [defaultCheck, setDefaultCheck] = useState(false); // Indicates the default checked value
    const [id, set_id]= useState()
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = async () => {
        // console.log(props.selectUser,id, "addition data")
        UpdateRole(props.selectUser,id).then((dt)=> console.log(dt)).catch(e=>console.log(e))
        // e.preventDefault()
        props.setuserAddModal(false)
        props.set_check(1)
    }

    return (
        <div>
            <Modal
                className='userAddtionModal'
                show={props?.userAddModal}
                onHide={() => props?.setuserAddModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                        <div className="modal-header w-100">
                            <h6 className='p-0 m-0'>Team Request</h6>
                            <div onClick={() => props?.setuserAddModal(false)} className="cursor-pointer cross-icon d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                        </div>
                        <div className="modal-body w-100 pt-0 pb-0">
                            <div className="team-card mt-4">
                                <Form onSubmit={handleOnSubmit}>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`}>
                                            <div className="approveWrap mb-2">
                                                <Form.Check
                                                    inline
                                                    label={
                                                        <h6 className='ms-2 m-0'>Approve as a Collaborator</h6>
                                                    }
                                                    onChange={(e)=>set_id(4)}
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                    defaultChecked={defaultCheck}
                                                />

                                            </div>
                                            <div className="approveWrap">
                                                <Form.Check
                                                    inline
                                                    label={<h6 className='ms-2 m-0'>Approve as a Manager</h6>}
                                                    name="group1"
                                                    onChange={(e)=>set_id(3)}
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                />

                                            </div>
                                        </div>
                                    ))}
                                    <Button className="w-100 mt-4 btn-yellow mb-4" onClick={handleOnSubmit}>Done</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >
        </div >
    )
}

export default UserAdditionPopup
