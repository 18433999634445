import React, { useContext, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import PaymentMethodTabs from '../components/payment-method/PaymentMethodTabs';
import { InstagramLogo, FacebookLogo, TwitterLogo, LinkedinLogo, YoutubeLogo, BankCard, StripeAccount, ApplePay } from "../constants/svgs"
import BankCardForm from '../components/payment-method/BankCardForm';
import YourPlanCard from '../components/plan/YourPlanCard';
import { SelectedPlanData } from "../App"

const PaymentMethodPage = () => {
  const [selectedPlan, setSelectedPlan] = useContext(SelectedPlanData);
  const location = useLocation();
  const [bankCard, setBankCard] = useState(true);
  const [stripeAccount, setStripeAccount] = useState(false);
  const [applePay, setApplePay] = useState(false);

  const handleClick = (id) => {
    if (id === "bank-card") {
      setBankCard(true)
      setStripeAccount(false)
      setApplePay(false)
    }
    else if (id === "stripe-account") {
      setBankCard(false)
      setStripeAccount(true)
      setApplePay(false)
    }
    else {
      setBankCard(false)
      setStripeAccount(false)
      setApplePay(true)
    }
  }


  return (
    <div className='payment-method-page mt-4'>
      <Row>
        <Col xs={12} lg={5} md={12} xl={4}>
          <YourPlanCard data={selectedPlan} />
          <NavLink className="d-block mt-5" to="/choose-plan">Change Plan</NavLink>
        </Col>

        <Col xs={12} lg={7} md={12} xl={8}>
          <div className='payment-section'>
            <h6>Select your payment method</h6>
            <div className='payment-tabs-section'>
              <Row>
                <PaymentMethodTabs onClick={() => handleClick("bank-card")} isCheck={bankCard} title="Bank Card" icon={<BankCard />} />
                <PaymentMethodTabs onClick={() => handleClick("stripe-account")} isCheck={stripeAccount} title="Stripe Account" icon={<StripeAccount />} />
                {/* <PaymentMethodTabs onClick={() => handleClick("apple-pay")} isCheck={applePay} title="Apple Pay" icon={<ApplePay />} /> */}
              </Row>
            </div>

            <div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={8} className="mb-5 mb-sm-0">
                  <div className="mt-5">
                    <h6>Bank Card Details</h6>
                    {bankCard && <BankCardForm />}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PaymentMethodPage