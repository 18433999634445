import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

// Get All Team Users

export const GetAllTeamUsers = async (currenTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?team_id=${currenTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


// Get All Team Users Weekly

export const getTemUserWeekly = async (currenTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?team_id=${currenTeamId}&weekly_points=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


// Get All Team Users Monthly

export const getTemUserMonthly = async (currenTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?team_id=${currenTeamId}&monthly_points=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


//Get All managers
export const GetTeamManagers = async (userTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?team_id=${userTeamId}&role_id=3&accepted_status=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


//Get All collabrators

export const GetTeamCollaborators = async (userTeamId) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?team_id=${userTeamId}&role_id=4&accepted_status=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


//Delete Team User
export const DeleteTeamUser = async (userTeamId) => {
    const resp = await axios.delete(`${constant.BASE_URL + apiEndPoints.team_user}/${userTeamId}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}







export const CreateTeam = async (data, is_current = 0) => {
    const { owner_id, teamTitle, teamphone, profile_picture } = data
    const name = teamTitle
    const phone = teamphone
    const package_id = 1
    const auto_post = 1
    const status = 1
    const current_team = is_current

    console.log("profile_picture:", profile_picture)

    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.teams}`, { is_current:current_team, owner_id, name, profile_picture, package_id, auto_post, status, phone }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });

    return resp
}

//Accept and Reject User


export const AcceptUser = async (user_id, team_id) => {
    const accepted_status = 1;
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.team_user}/${team_id}`, { user_id, accepted_status }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

export const RejectUser = async (user_id, team_id) => {
    const accepted_status = 2;
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.team_user}/${team_id}`, { user_id, accepted_status }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//Request to  Teams

export const getTeamRequest = async (userTeam) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.ing_team_request}?team_id=${userTeam}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


//Request to join Teams

export const getJoinTeamRequest = async (userTeam) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.joining_team_request}?team_id=${userTeam}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        }

    );
    return resp
}


export const GetTeamUserByRole = async (team_id, role_id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?team_id=${team_id}&role_id=${role_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return resp
}


export const InviteCollaborator = async (email, team_id) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.teams_invite_collaborator}`, { email, team_id }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return resp
}

export const InviteManager = async (email, team_id) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.teams_invite_manager}`, { email, team_id }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return resp
}
